import { type MouseEvent, useCallback } from 'react'

export const ColorButton = ({ id, onClick, color }) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()

      onClick(id)
    },
    [id, onClick],
  )

  return (
    <div
      onClick={handleClick}
      style={{
        width: 40,
        height: 20,
        float: 'right',
        backgroundColor: color,
        borderRadius: 5,
        border: '1px solid #aaa',
      }}
    />
  )
}

export default ColorButton
