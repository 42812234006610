import { EditPanelCard } from 'components'
import { useApi, useBoolean } from 'hooks'
import type { ApiCall } from 'hooks/useApi'
import { useCallback } from 'react'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { Col, Row } from 'reactstrap'
import { askQuestion } from 'utils'
import UploadImageDropZone from './UploadImageDropZone'
import 'photoswipe/dist/photoswipe.css'
import CardEditButton from 'containers/common/CardEditButton'

type ImagesCardProps = {
  returnId: number
  getReturn: ApiCall
  readOnly: boolean
}

type ImageItem = {
  id: number
  filename: string
  url_path: string
  thumb_url_path: string
  width: number
  height: number
}

const ImagesCard = ({ returnId, getReturn, readOnly }: ImagesCardProps) => {
  const { result } = getReturn
  const editMode = useBoolean()

  const apiDeleteImage = useApi(
    { action: 'returns_deleteImage', id: returnId },
    null,
    () => ({ errorModal: true }),
  )

  const handleUploadDone = useCallback(() => {
    getReturn.performRequest()
  }, [getReturn])

  const handleClickRemove = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>, id: number) => {
      e.preventDefault()

      const confirm = await askQuestion(
        'Are you sure you want to remove this image?',
      )

      if (confirm) {
        await apiDeleteImage.performRequest({ imageID: id })
        await getReturn.performRequest()
      }
    },
    [apiDeleteImage, getReturn],
  )

  const headerActions = (
    <CardEditButton isEditing={editMode.value} onClick={editMode.toggle} />
  )

  return (
    <EditPanelCard
      caption={`Images ${editMode.value ? '(editing)' : ''}`}
      stateId="return_images"
      bodyProps={{ className: 'p-0' }}
      {...{
        headerActions: !readOnly && result.images.length ? headerActions : null,
      }}
    >
      {!readOnly ? (
        <Row>
          <Col className="p-3">
            <UploadImageDropZone
              onUploadDone={handleUploadDone}
              {...{ returnId }}
            />
          </Col>
        </Row>
      ) : null}
      {result.images.length ? (
        <Row>
          <Col className="pl-3 pr-3 pb-2 pt-0">
            <Gallery
              options={{ hideAnimationDuration: 0, showAnimationDuration: 0 }}
            >
              {result.images.map((item: ImageItem) => (
                <Item
                  key={item.filename}
                  original={`${global.appConfig.wpBaseUrl}${item.url_path}`}
                  thumbnail={`${global.appConfig.wpBaseUrl}${item.thumb_url_path}`}
                  width={item.width}
                  height={item.height}
                >
                  {({ ref, open }) => (
                    <div style={{ display: 'inline-block' }}>
                      <img
                        className="pr-2 pb-2"
                        alt=""
                        ref={ref as React.LegacyRef<HTMLImageElement>}
                        onClick={open}
                        src={`${global.appConfig.wpBaseUrl}${item.thumb_url_path}`}
                        width={120}
                      />
                      {editMode.value ? (
                        <a
                          href="#"
                          onClick={(e) => handleClickRemove(e, item.id)}
                        >
                          Remove Image
                        </a>
                      ) : null}
                    </div>
                  )}
                </Item>
              ))}
            </Gallery>
          </Col>
        </Row>
      ) : (
        <Row className="p-3">
          <Col>
            <i>No images added.</i>
          </Col>
        </Row>
      )}
    </EditPanelCard>
  )
}

export default ImagesCard
