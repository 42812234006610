import { LoadingImage } from 'components'
import { type StatefulState, useApi, usePrevious, useStateful } from 'hooks'
import React, { useCallback, useEffect, useRef } from 'react'
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap'

type DropDownState = {
  id?: string | null
  isEditing?: boolean
}

type NotesIconProps = {
  id: string
  productId: number
  header: string
  editHeader: string
  children: React.JSX.Element
  dropDownState: StatefulState<DropDownState>
  value?: string
  onNoteUpdated: (orderId: number, note: string) => void
  apiAction: string
  readOnly: boolean
  reloadNotes?: () => void
}

const NotesIcon = (props: NotesIconProps) => {
  const {
    productId,
    header,
    editHeader,
    children,
    id,
    dropDownState,
    value,
    onNoteUpdated,
    apiAction,
    readOnly,
  } = props

  const hasNote = value !== ''
  const notes = useStateful('')
  const isEditing = dropDownState.value.isEditing
  const isOpen = dropDownState.value.id === id
  const prevIsEditing = usePrevious(isEditing)
  const prevIsOpen = usePrevious(isOpen)
  const apiSave = useApi({}, null, { errorModal: true })

  /* Immediatly switch to edit mode if no notes exist yet. */
  useEffect(() => {
    if (!hasNote && !isEditing && isOpen && isOpen !== prevIsOpen) {
      dropDownState.update({ isEditing: true })
    }
  }, [dropDownState, hasNote, isOpen, prevIsOpen, isEditing])

  /* Initialize textarea content when switching to edit mode. */
  useEffect(() => {
    if (isOpen && isEditing && isEditing !== prevIsEditing) {
      notes.set(value)
    }
  }, [notes, value, isOpen, isEditing, prevIsEditing])

  /* Dismiss editing mode when popover closes. */
  useEffect(() => {
    if (isEditing && dropDownState.value.id == null) {
      dropDownState.update({ isEditing: false })
    }
  }, [isEditing, dropDownState])

  const handleToggle = useCallback(() => {
    if (isEditing) {
      return
    }

    dropDownState.update({ id: isOpen ? null : id })
  }, [isOpen, isEditing, dropDownState, id])

  const handleClickIcon = useCallback(() => {
    if (readOnly) {
      return
    }

    if (!isOpen) {
      dropDownState.update({ id })
      return
    }

    dropDownState.update({ isEditing: !isEditing })
  }, [readOnly, isOpen, isEditing, dropDownState, id])

  const handleClickSave = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault()

      if (readOnly || apiSave.isLoading) {
        return
      }

      await apiSave.performRequest({
        action: apiAction,
        json: {
          id: productId,
          note: notes.value,
        },
      })

      dropDownState.set({})

      onNoteUpdated(productId, notes.value)
    },
    [
      readOnly,
      apiAction,
      onNoteUpdated,
      productId,
      notes,
      apiSave,
      dropDownState,
    ],
  )

  const handleChangeTextarea = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      notes.set(e.currentTarget.value)
    },
    [notes],
  )

  const ref = useRef(null)

  return (
    <>
      <i
        ref={ref}
        className={`cursor-pointer notes-icon fa fa-comment${
          hasNote ? '' : '-o'
        } ml-1`}
        onClick={handleClickIcon}
      />
      {hasNote || isEditing ? (
        <UncontrolledPopover
          className={!isEditing ? 'hovering' : ''}
          placement="left"
          isOpen={isOpen}
          target={ref}
          trigger={hasNote ? 'hover' : 'click'}
          toggle={handleToggle}
          key={isEditing ? `${id}-editing` : `${id}`}
        >
          <PopoverHeader>
            {!readOnly && isEditing ? (
              <>
                {editHeader}
                <a
                  className={`float-right ${
                    apiSave.isLoading ? 'disabled' : ''
                  }`}
                  href="#"
                  onClick={handleClickSave}
                >
                  Save
                </a>
              </>
            ) : (
              header
            )}
            {apiSave.isLoading ? <LoadingImage small className="ml-2" /> : null}
          </PopoverHeader>
          {isEditing ? (
            <PopoverBody>
              <textarea
                className="form-control"
                style={{ minHeight: 150 }}
                value={notes.value}
                onChange={handleChangeTextarea}
              />
            </PopoverBody>
          ) : (
            <PopoverBody
              style={{
                maxHeight: 300,
                overflow: 'hidden',
                whiteSpace: 'pre-wrap',
              }}
            >
              {children}
            </PopoverBody>
          )}
        </UncontrolledPopover>
      ) : null}
    </>
  )
}

type NotesCellProps = {
  id: number
  dropDownState: StatefulState<DropDownState>
  note?: string
  onNoteUpdated: (id: number, note: string) => void
  readOnly: boolean
  apiAction: string
}

const NotesCell = React.memo((props: NotesCellProps) => {
  const { id, dropDownState, note, onNoteUpdated, readOnly, apiAction } = props

  return (
    <div className="order-notes-cell">
      <NotesIcon
        id={`notes-${id}-popover1`}
        header="Notes"
        editHeader="Notes"
        value={note ? note : ''}
        {...{
          apiAction,
          readOnly,
          productId: id,
          onNoteUpdated,
          dropDownState,
        }}
      >
        {note == '' ? <i>(empty)</i> : <>{note}</>}
      </NotesIcon>
    </div>
  )
})

export default NotesCell
