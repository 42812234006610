import type { Mail } from '@ttc/api/orders'
import useApi from 'hooks/useApi'
import { useCallback } from 'react'
import { Button, Col, FormGroup, Input, Row } from 'reactstrap'
import Swal from 'sweetalert2'

type ResendMailForm = {
  mail: Mail
  defaultRecipient: string
  onResent: () => void
  onClose: () => void
}

const ResendMailForm = ({
  mail,
  defaultRecipient,
  onResent,
  onClose,
}: ResendMailForm) => {
  const resendApi = useApi({ action: 'orders_resendEmail' }, null, {
    errorModal: true,
    throws: true,
  })

  const handleClickSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      const form = e.currentTarget.form as HTMLFormElement
      const to = (form.elements.namedItem('email') as HTMLInputElement).value

      try {
        await resendApi.performRequest({
          json: { id: mail.id, key: mail.key, to },
        })

        Swal.fire({ icon: 'info', text: 'E-Mail has been sent.' })

        onResent()
        onClose()
      } catch (_e) {
        /* Ignore */
      }
    },
    [mail, resendApi, onClose, onResent],
  )

  const handleClickCancel = useCallback(async () => {
    onClose()
  }, [onClose])

  return (
    <>
      <Row>
        <Col>
          <b>Resend email to:</b>
          <FormGroup className="mb-1">
            <Input
              required
              id="input-to"
              defaultValue={defaultRecipient}
              type="email"
              name="email"
              placeholder="recipient@example.com"
              autoComplete="nope"
              autoCorrect="off"
              spellCheck={false}
              disabled={resendApi.isLoading}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            disabled={resendApi.isLoading}
            type="submit"
            onClick={handleClickSubmit}
          >
            Send
          </Button>
          <Button
            disabled={resendApi.isLoading}
            className="ml-2"
            type="button"
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ResendMailForm
