import { EditPanelCard } from 'components'
import { usePrevious, useSet } from 'hooks'
import type { ApiCall, StatefulState } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { Nav } from 'reactstrap'
import BatchActionButton from './BatchActionButton'
import OrderLine from './OrderLine'

export type OrderType = {
  id: string
  status: string
  order_id: number
  order_number: string
  customer_name: string
  quantity: number
  item_count: number
  has_only_plants: boolean
  num_plants: number
}

type OrdersCardType = {
  readOnly: boolean
  caption: string | React.JSX.Element
  getOrders: ApiCall
  triggerSearch: () => void
  isLoading: boolean
  selectedOrder: StatefulState<any>
}

const OrdersCard = (props: OrdersCardType) => {
  const {
    readOnly,
    caption,
    getOrders,
    triggerSearch,
    isLoading,
    selectedOrder,
  } = props
  const { result } = getOrders
  const selectedOrders = useSet<number>()

  const handleClickOrder = useCallback(
    (id) => {
      selectedOrders.toggle(Number(id))
    },
    [selectedOrders],
  )

  const orders: OrderType[] = useMemo(
    () => (result?.orders.length ? result.orders : []),
    [result],
  )

  const cleanupSelectedOrders = useCallback(() => {
    const ids = orders.map((o) => Number(o.id))
    if (ids.length) {
      selectedOrders.remove(ids)
    }
  }, [orders, selectedOrders])

  const prevOrders = usePrevious(orders)

  /* Make sure "selectedOrders" doesn't include IDs that have been removed. */
  useEffect(() => {
    if (orders != prevOrders) {
      cleanupSelectedOrders()
    }
  }, [prevOrders, orders, cleanupSelectedOrders])

  return (
    <EditPanelCard
      stateId="orders_in_batch"
      bodyProps={{ className: 'p-0' }}
      {...{ caption, isLoading }}
    >
      {!readOnly && orders.length ? (
        <Nav className="p-1" pills>
          <BatchActionButton {...{ selectedOrders, triggerSearch }} />
        </Nav>
      ) : null}

      {orders.length === 0 ? (
        <div className="p-3">No orders in batch.</div>
      ) : null}

      {orders.map((order) => {
        return (
          <OrderLine
            key={order.order_id}
            {...{
              order,
              onClick: handleClickOrder,
              checked: selectedOrders.has(Number(order.order_id)),
              selectedOrder,
            }}
          />
        )
      })}
    </EditPanelCard>
  )
}

export default OrdersCard
