import type { GetAllTableColumnPresetsApiResult } from '@ttc/api/tableColumnPresets'
import type { ColumnDef, ColumnPreset, Columns } from 'components/Table/types'
import useApi from 'hooks/useApi'
import keyBy from 'lodash/keyBy'
import xor from 'lodash/xor'
import { useCallback, useMemo } from 'react'
import {
  resetColumnColors,
  resetColumns,
  setColumnColor,
  setPreset as setPresetAction,
  setSortedColumns,
  setVisibleColumns,
} from './reducer/actions'
import type { Action, BaseRow, Row, State } from './reducer/types'

/**
 * Returns an array of column ids in the order they appear in the sortedColumns array,
 * followed by any additional ids that exist in the columnDef array but not in
 * the sortedColumns array.
 *
 * This is used to return the table columns in the order preferred by the user.
 *
 * @param columnDef - An array of column definitions.
 * @param sortedColumns - An array of sorted column ids.
 * @returns - An array of sorted column ids.
 */
export const getSortedColumnIds = (
  columnDef: ColumnDef[],
  sortedColumns: string[],
) => {
  const allColumnIds = columnDef.map((col) => col.id)

  if (sortedColumns == null) {
    return allColumnIds
  }

  const ret = sortedColumns.filter((id: string) => allColumnIds.includes(id))
  for (const col of columnDef) {
    const id = col.id
    if (!ret.includes(id)) {
      ret.push(id)
    }
  }

  return ret
}

/**
 * Custom React hook that provides various utilities for managing table columns.
 *
 * Features:
 * - Moving Columns: Allows the reordering of columns in the table.
 * - Toggling Visibility: Provides the ability to show or hide specific columns.
 * - Sorting: Supports sorting of columns based on specified criteria.
 * - Column Coloring: Supports setting and resetting of colors for specific columns.
 * - Presets: Allows loading and storing of predefined sets of column configurations (presets).
 *
 * @param tableName - The name of the table.
 * @param state - The current state of the table, from useTable.
 * @param dispatch - The dispatch function from useReducer, from useTable.
 * @param columnDef - An array of column definitions.
 * @returns - An object containing various utilities for managing table columns.
 */
const useTableColumns = <RowType extends BaseRow = Row>(
  tableName: string | null,
  // state from useTable
  state: State<RowType>,
  // dispatch from useTable
  dispatch: React.Dispatch<Action<RowType>>,
  columnDef: ColumnDef[],
): Columns => {
  const sortedColumnIds = useMemo(
    () => getSortedColumnIds(columnDef, state.sortedColumns),
    [columnDef, state.sortedColumns],
  )

  const keyed = useMemo(() => keyBy(columnDef, 'id'), [columnDef])

  const defaultVisibleColumnIds = useMemo(() => {
    return sortedColumnIds.filter((id) => keyed[id].defaultVisible !== false)
  }, [keyed, sortedColumnIds])

  const visibleColumnsIds = useMemo(
    () =>
      state.visibleColumns.length > 0
        ? state.visibleColumns
        : defaultVisibleColumnIds,
    [state.visibleColumns, defaultVisibleColumnIds],
  )

  const sorted = useMemo(
    () => sortedColumnIds.map((id) => keyed[id]),
    [keyed, sortedColumnIds],
  )

  const visibleSorted = useMemo(
    () => sorted.filter((col) => visibleColumnsIds.includes(col.id)),
    [sorted, visibleColumnsIds],
  )

  const toggle = useCallback(
    (id: string) => {
      const newVisibleColumns = xor(visibleColumnsIds, [id])
      dispatch(setVisibleColumns(newVisibleColumns))
    },
    [dispatch, visibleColumnsIds],
  )

  const move = useCallback(
    (hoverIndex: number, dragIndex: number) => {
      const newColumnIds = [...sortedColumnIds]
      newColumnIds.splice(hoverIndex, 0, newColumnIds.splice(dragIndex, 1)[0])
      dispatch(setSortedColumns(newColumnIds))
    },
    [dispatch, sortedColumnIds],
  )

  const isVisible = useCallback(
    (id: string) => visibleColumnsIds.includes(id),
    [visibleColumnsIds],
  )

  const reset = useCallback(() => {
    dispatch(resetColumns())
  }, [dispatch])

  const setColor = useCallback(
    (columnName: string, color: string) => {
      dispatch(setColumnColor(columnName, color))
    },
    [dispatch],
  )

  const resetColors = useCallback(() => {
    dispatch(resetColumnColors())
  }, [dispatch])

  const setPreset = useCallback(
    (preset: ColumnPreset) => {
      dispatch(setPresetAction(preset))
    },
    [dispatch],
  )

  const apiLoadPresets = useApi<GetAllTableColumnPresetsApiResult>(
    () => ({
      action: 'tableColumnPresets_getAll',
      tableName,
    }),
    null,
    () => ({ autoPerform: tableName != null }),
  )

  const presets: ColumnPreset[] = useMemo(
    () =>
      apiLoadPresets.result?.rows.map((row) => ({
        value: row.name,
        label: row.name,
        visibleColumns: row.visible_columns.split(','),
        sortedColumns: row.sorted_columns.split(','),
      })) ?? [],
    [apiLoadPresets],
  )

  const selectedPreset = useMemo(() => {
    const currentState = JSON.stringify({
      visible: visibleSorted.map((c) => c.id),
      sorted: sorted.map((c) => c.id),
    })

    for (const preset of presets) {
      const presetState = JSON.stringify({
        visible: preset.visibleColumns,
        sorted: preset.sortedColumns,
      })

      if (currentState === presetState) {
        return preset
      }
    }

    return null
  }, [presets, visibleSorted, sorted])

  const apiSavePreset = useApi(
    () => ({
      action: 'tableColumnPresets_save',
      tableName,
    }),
    null,
    () => ({ errorModal: true }),
  )

  const apiDeletePreset = useApi(
    () => ({
      action: 'tableColumnPresets_delete',
      tableName,
    }),
    null,
    () => ({ errorModal: true }),
  )

  return useMemo(
    (): Columns => ({
      sorted,
      visibleSorted,
      visibleColumnsIds,
      keyed,
      toggle,
      move,
      isVisible,
      reset,
      setColor,
      colors: state.columnColors,
      resetColors,
      presets,
      apiLoadPresets,
      apiSavePreset,
      apiDeletePreset,
      tableName,
      setPreset,
      selectedPreset,
    }),
    [
      sorted,
      visibleSorted,
      visibleColumnsIds,
      keyed,
      toggle,
      move,
      isVisible,
      reset,
      setColor,
      state.columnColors,
      resetColors,
      presets,
      apiLoadPresets,
      apiSavePreset,
      apiDeletePreset,
      tableName,
      setPreset,
      selectedPreset,
    ],
  )
}

export default useTableColumns
