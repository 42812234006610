import trimStart from 'lodash/trimStart'

import { getStorage } from './storage'
export { getStorage }

import { formatDateReadable } from './date'
export { formatDateReadable }

import { formatPrice } from './price'
export { formatPrice }

import { uiApiRequest } from './api'
export { uiApiRequest }

import { sleep } from './sleep'
export { sleep }

import { askQuestion, askQuestionWithReason, showError } from './alert'
export { showError, askQuestion, askQuestionWithReason }

export const assetPath = (path: string) => {
  return `/assets/${trimStart(path, '/')}`
}

export const decodeHtml = (html: string) => {
  const e = document.createElement('div')
  e.innerHTML = html
  return e.textContent
}

export const parentHasClass = (node: Element, className: string) => {
  if (
    node?.className?.split &&
    node.className.split(' ').indexOf(className) >= 0
  ) {
    return true
  }
  return node?.parentNode && parentHasClass(node.parentElement, className)
}

export const debugLog = (msg: string) => {
  console.log(msg) // eslint-disable-line
}

export const loginRedirect = () => {
  const url = localStorage.getItem('logout_redirect')

  window.location.href = url ? url : '/login'
}
