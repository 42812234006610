import moment from 'moment'

/* Convert YYYY-MM-DD -> MM/DD/YYYY */
export const formatDate = (dateStr: string) => {
  const m = dateStr.match(/(\d{4})-(\d{2})-(\d{2})/)
  if (m) {
    return `${m[2]}/${m[3]}/${m[1]}`
  }

  return dateStr
}

/* Convert YYYY-MM-DD -> Month DD, YYYY */
export const formatDateReadable = (
  dateStr: string,
  withTime = false,
  fromUtc = false,
) => {
  let format = null

  if (String(dateStr).match(/(\d{4})-(\d{2})-(\d{2})/)) {
    format = 'YYYY-MM-DD'
  }

  if (String(dateStr).match(/(\d{2})\/(\d{2})\/(\d{4})/)) {
    format = 'MM/DD/YYYY'
  }

  if (withTime) {
    format += ' HH:mm'
  }

  let d = null

  if (fromUtc) {
    d = moment.utc(dateStr, format)
    d.local()
  } else {
    d = moment(dateStr, format)
  }

  if (d.isValid()) {
    if (withTime) {
      return d.format('MMM D, YYYY HH:mm')
    }
    return d.format('MMM D, YYYY')
  }

  return ''
}
