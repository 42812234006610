import type { TableCellRenderProps } from 'app/components/Table/types'
import { FilterBar, PageNavHead, PageNavTail } from 'components'
import {
  Table,
  type Row as TableRow,
  defaultCellRenderer,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import {
  useApi,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdater,
  usePageTitle,
  usePanelControl,
  useSidebar,
} from 'hooks'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'
import Filters from './Filters'
import Sidebar from './Sidebar'
import columnDef from './columnDef'

// XXX Sidebar disabled for now
const hasSidebar = false

const pageTitle = 'Manage Customers'

const storageKey = 'manageCustomers'

const [setItem, getItem] = getStorage(storageKey)

const visibleFilters = ['q', 'subscription']

const ManageCustomers = (_props: { readOnly: boolean }) => {
  const navigate = useNavigate()

  const filters = useFiltersWithUrlUpdater(
    'manageCustomersFilters',
    visibleFilters,
  )

  const query = filters.searchQueryString

  const [state, dispatch] = useTable({
    cacheKey: 'manageCustomers',
    getItem,
    setItem,
  })
  const { isLoading, selectedRows } = state
  const [triggerSearch] = useTableApi('customers_getAll', state, dispatch, {
    query: '',
    ...filters.requestProps,
  })

  const sidebar = useSidebar({
    openForSingleRow: hasSidebar,
    openForMultiRows: false,
    selectedRows,
  })

  const selectedCustomerId = selectedRows.length === 1 ? selectedRows[0] : null
  const getCustomer = useApi(
    useMemo(
      () => ({ action: 'customers_get', id: selectedCustomerId }),
      [selectedCustomerId],
    ),
    null,
    { autoPerform: sidebar.isOpen && selectedCustomerId != null },
  )

  /* Trigger search when filters change. */
  useEffectIfObjectChanges(triggerSearch, filters.requestProps)

  usePageTitle(pageTitle)

  const columns = useTableColumns(storageKey, state, dispatch, columnDef)

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters],
  )

  const manageColumnsPanel = usePanelControl()

  const handleClickIdCell = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement>) => {
      if (e.metaKey || e.ctrlKey) {
        /* Allow opening link in new tab. */
        return
      }

      e.preventDefault()

      const td = (e.target as HTMLElement).closest('td')
      if (td) {
        const id = td.getAttribute('data-id')
        navigate(`/customers/${id}`)
      }
    },
    [navigate],
  )

  const renderCell = useCallback(
    (props: TableCellRenderProps<TableRow>) => {
      if (props.col.id === 'id') {
        return (
          <td
            onClick={handleClickIdCell}
            data-id={props.value}
            className="cell id"
            style={{ cursor: 'pointer' }}
          >
            <a href={`/customers/${props.value}`}>{props.value}</a>
          </td>
        )
      }

      return defaultCellRenderer(props)
    },
    [handleClickIdCell],
  )

  return (
    <>
      <PageNavHead
        {...{ isLoading, pageTitle, onClickReload: triggerSearch }}
      />
      <PageNavTail {...{ isLoading, query, handleChangeSearch }} />
      <FilterBar {...{ filters, manageColumnsPanel, columns }}>
        <Filters {...{ filters }} />
      </FilterBar>
      <Container fluid className="mt-4">
        <div className="manage-customers animated fadeIn">
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <Row>
            <Col>
              <Table
                entityName="customers"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                  renderCell,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
      {hasSidebar ? (
        <Sidebar
          {...{
            selectedRows,
            getCustomer,
            onToggle: sidebar.userToggle,
          }}
        />
      ) : null}
    </>
  )
}

export default ManageCustomers
