import { EditPanelCard, Loading } from 'components'
import { useApi, useStateful } from 'hooks'
import { useCallback, useEffect } from 'react'
import { Button } from 'reactstrap'
import { formatDateReadable } from 'utils'

type LogEntry = {
  id: number
  message: string
  created_at: string
  created_by_name: string
}

const Log = ({ returnId }) => {
  const log = useStateful(null)

  const apiGetLog = useApi(() => ({ action: 'returns_getLog' }))

  const fetchLog = useCallback(async () => {
    log.set([])

    const ret = await apiGetLog.performRequest({ json: { id: returnId } })

    if (ret?.rows) {
      log.set(ret.rows)
    }
  }, [returnId, log, apiGetLog])

  useEffect(() => {
    if (log.value != null) {
      return
    }

    fetchLog()
  }, [log, fetchLog])

  const entries = log.value ? log.value : []

  if (apiGetLog.isLoading) {
    return <Loading />
  }

  if (apiGetLog.hasResult && entries.length === 0) {
    return <div>Log empty</div>
  }

  return (
    <>
      <div className="float-right">
        <Button className="btn-sm m-0 p-0 btn btn-link" onClick={fetchLog}>
          Reload
        </Button>
      </div>
      <ul>
        {entries.map((e: LogEntry) => {
          return (
            <li key={e.id}>
              <div style={{ whiteSpace: 'pre-wrap' }}>{e.message}</div>
              <div style={{ fontSize: 11, fontStyle: 'italic' }}>
                {e.created_by_name} on {formatDateReadable(e.created_at, true)}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

const LogCard = ({ returnId }) => {
  const isLoading = false

  return (
    <EditPanelCard
      stateId="return_log"
      caption="Log"
      defaultIsOpen={false}
      lazyLoadBody
      {...{ isLoading }}
    >
      <Log key={returnId} {...{ returnId }} />
    </EditPanelCard>
  )
}

export default LogCard
