import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import PurchaseOrderStatusBadge from 'components/Badges/PurchaseOrderStatusBadge'
import { Link } from 'react-router'
import { formatDateReadable } from 'utils/date'

const PurchaseOrderListItem = ({
  purchaseOrder,
}: {
  purchaseOrder: PurchaseOrder
}) => {
  const { id, status, supplier, order_date } = purchaseOrder

  return (
    <li key={id}>
      <div>
        <Link to={`/purchase-orders/edit/${id}`}>#{id}</Link> -{' '}
        <PurchaseOrderStatusBadge value={status} /> - {supplier.name}
      </div>
      <div style={{ fontSize: 11, fontStyle: 'italic' }}>
        {formatDateReadable(order_date)}
      </div>
    </li>
  )
}

export default PurchaseOrderListItem
