import { SHIPPINGEMPLOYEES_READWRITE } from 'caps'
import { useContext } from 'react'
import AuthContext from './../../AuthContext'
import ManageEmployees from './../ManageShippingEmployees/ManageEmployees'

const ManageScanappEmployees = () => {
  const { hasCap } = useContext(AuthContext)
  const canEdit = hasCap(SHIPPINGEMPLOYEES_READWRITE)

  const pageTitle = 'Manage Scanapp Employees'

  return (
    <ManageEmployees
      hasCapabilities
      storageKey="manageScanappEmployees"
      userRole="scanapp"
      {...{ pageTitle, canEdit }}
    />
  )
}

export default ManageScanappEmployees
