/* eslint react-refresh/only-export-components: 0 */

import type { State } from 'containers/AddOrder/useCart/types'
import Swal, { type SweetAlertIcon } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { sleep } from 'utils'
import { calculateFullRefundPlan } from './../utils'
import CancelOrderModal from './CancelOrderModal'
import ReasonModal, { type ReasonModalState } from './ReasonModal'
import RefundModal from './RefundModal'
import RefundOrChargeDifferenceModal, {
  type RefundOrChargeDifferenceModalState,
} from './RefundOrChargeDifferenceModal'

const ReactSwal = withReactContent(Swal)

type Props = {
  autoClose?: boolean
  preConfirm?: () => Promise<void>
  preDeny?: () => Promise<void>
  yesButtonLabel?: string
  noButtonLabel?: false | string
  hasCancelButton?: boolean
  showCloseButton?: boolean
  icon?: SweetAlertIcon
  width?: string
}

const validateRefundAmount = (amount: string) => {
  if (/,/.test(amount)) {
    throw new Error('Refund amount may not contain thousands separators.')
  }

  // Only allow numbers with 2 decimal places and without thousands separators.
  const isValidAmount = /^[0-9]*(\.[0-9]{2})?$/.test(amount)

  if (!isValidAmount) {
    throw new Error('Please enter a valid refund amount.')
  }

  if (Number(amount) === 0) {
    throw new Error('Refund amount may not be zero.')
  }
}

export const askQuestion = (
  content: React.ReactNode | string,
  props: Props = {},
): Promise<boolean> => {
  return new Promise((resolve) => {
    const handleClickYes = async () => {
      if (Swal.isLoading()) {
        return
      }

      if (props.preConfirm) {
        try {
          Swal.showLoading()
          Swal.disableButtons()
          await props.preConfirm()
          Swal.enableButtons()
          Swal.hideLoading()
        } catch (e) {
          Swal.hideLoading()
          Swal.showValidationMessage(e.message)
          Swal.enableButtons()
          return
        }
      }

      resolve(true)

      if (props.autoClose !== false) {
        Swal.close()
      } else {
        Swal.enableButtons()
      }
    }

    const handleClickNo = async () => {
      if (Swal.isLoading()) {
        return
      }

      if (props.preDeny) {
        try {
          Swal.showLoading()
          Swal.disableButtons()
          await props.preDeny()
          Swal.enableButtons()
          Swal.hideLoading()
        } catch (e) {
          Swal.hideLoading()
          Swal.showValidationMessage(e.message)
          Swal.enableButtons()
          return
        }
      }

      resolve(false)
      Swal.close()
    }

    const handleClickCancel = () => {
      if (Swal.isLoading()) {
        return
      }

      resolve(null)
      Swal.close()
    }

    ReactSwal.fire({
      html: (
        <>
          {content}
          <br />
          <button
            type="button"
            onClick={handleClickYes}
            id="swal-yes"
            className="btn btn-danger btn-lg mr-2"
          >
            {props.yesButtonLabel ? props.yesButtonLabel : 'Yes'}
          </button>
          {props.noButtonLabel !== false ? (
            <button
              type="button"
              onClick={handleClickNo}
              id="swal-no"
              className="btn btn-lg btn-primary mr-2"
            >
              {props.yesButtonLabel ? props.noButtonLabel : 'No'}
            </button>
          ) : null}
          {props.hasCancelButton !== false ? (
            <button
              type="button"
              onClick={handleClickCancel}
              id="swal-cancel"
              className="btn btn-lg btn-secondary mr-2"
            >
              Cancel
            </button>
          ) : null}
        </>
      ),
      icon: props.icon ? props.icon : 'question',
      showConfirmButton: false,
      showCloseButton: props.showCloseButton || false,
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
      width: props.width ? props.width : null,
    })
  })
}

export const askShouldRefundDifference = async (
  originalAmount: number,
  updateAmount: number,
): Promise<{ result: null | boolean; reason: string }> => {
  let state: RefundOrChargeDifferenceModalState = { reason: '' }

  const handleStateChange = (_state: RefundOrChargeDifferenceModalState) => {
    state = _state
  }

  let diffAmount = originalAmount - updateAmount
  diffAmount = diffAmount < 0 ? diffAmount * -1 : diffAmount
  const isRefund = originalAmount > updateAmount

  const body = (
    <RefundOrChargeDifferenceModal
      {...{
        isRefund,
        originalAmount,
        updateAmount,
        diffAmount,
        onChangeState: handleStateChange,
      }}
    />
  )

  const yesButtonLabel = isRefund ? 'Update & Refund' : 'Update & Charge'
  const noButtonLabel = isRefund
    ? 'Update without a refund'
    : 'Update without a charge'

  const preConfirm = async () => {
    if (state.reason === '') {
      throw new Error(
        isRefund
          ? 'Please specify a refund reason.'
          : 'Please specify a charge reason.',
      )
    }
  }

  const result = await askQuestion(body, {
    yesButtonLabel,
    noButtonLabel,
    width: '700px',
    preConfirm,
    preDeny: preConfirm,
  })

  return { result, reason: state.reason }
}

export const askCancelOrder = async (
  orderNumber: string,
  cartState: State,
): Promise<{
  shouldRefund?: boolean
  refundAmount?: string
  reason?: string
  skipRestoreInventoryProductIds?: number[]
}> => {
  let state: any = {}

  const handleStateChange = (_state: any) => {
    state = _state
  }

  const refundPlan = calculateFullRefundPlan(cartState)

  const body = (
    <CancelOrderModal
      onChangeState={handleStateChange}
      title={`Cancel Order ${orderNumber}?`}
      subtitle="This will cancel the parent order and all open splits."
      {...{ orderNumber, refundPlan, cartItems: cartState.cartItems }}
    />
  )

  const isYes = await askQuestion(body, {
    hasCancelButton: false,
    autoClose: false,
    showCloseButton: true,
    yesButtonLabel: 'CANCEL ORDER',
    noButtonLabel: false,
    preConfirm: async () => {
      validateRefundAmount(state.refundAmount)

      if (state.reason === '') {
        throw new Error('Please specify a refund reason.')
      }
    },
  })

  return isYes ? state : false
}

export const askCancelOrderSplit = async (
  orderNumber: string,
  cartState: State,
): Promise<{
  shouldRefund?: boolean
  refundAmount?: string
  reason?: string
  skipRestoreInventoryProductIds?: number[]
}> => {
  let state = {}

  const handleStateChange = (_state: any) => {
    state = _state
  }

  const refundPlan = calculateFullRefundPlan(cartState, orderNumber)

  const cartItems = cartState.cartItems.filter(
    (cartItem) => orderNumber === cartItem.orderNumber,
  )

  const body = (
    <CancelOrderModal
      onChangeState={handleStateChange}
      title={`Cancel Order Split ${orderNumber}?`}
      {...{ orderNumber, refundPlan, cartItems }}
    />
  )

  const isYes = await askQuestion(body, {
    hasCancelButton: false,
    autoClose: false,
    showCloseButton: true,
    yesButtonLabel: 'CANCEL SPLIT',
    noButtonLabel: false,
  })

  return isYes ? state : false
}

export const askPartialRefund = async (
  orderNumber: string,
  refundAmount: number,
  handleRefund: (amount: string, reason: string) => Promise<void>,
) => {
  let state: any = {}

  const handleStateChange = (_state: any) => {
    state = _state
  }

  const body = (
    <RefundModal
      onChangeState={handleStateChange}
      {...{ orderNumber, refundAmount }}
    />
  )

  const isYes = await askQuestion(body, {
    icon: 'warning',
    hasCancelButton: false,
    yesButtonLabel: 'REFUND',
    noButtonLabel: 'Cancel',
    autoClose: false,
    preConfirm: async () => {
      validateRefundAmount(state.amount)

      if (state.reason === '') {
        throw new Error('Please specify a refund reason.')
      }

      await handleRefund(state.amount, state.reason)

      Swal.fire({
        text: `Successfully refunded $${state.amount}`,
        showCancelButton: false,
        showConfirmButton: false,
      })

      await sleep(1500)
    },
  })

  return isYes ? state : false
}

export const askReasonModal = async (
  orderNumber: string,
): Promise<false | string> => {
  let state: ReasonModalState = { reason: '' }

  const handleStateChange = (_state: RefundOrChargeDifferenceModalState) => {
    state = _state
  }

  const body = (
    <ReasonModal
      {...{
        orderNumber,
        onChangeState: handleStateChange,
      }}
    />
  )

  const isYes = await askQuestion(body, {
    yesButtonLabel: 'Confirm',
    noButtonLabel: false,
    preConfirm: async () => {
      if (state.reason === '') {
        throw new Error('Please specify a reason.')
      }
    },
  })

  return isYes ? state.reason : false
}
