import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Link, useMatch } from 'react-router'

type NavLinkProps = {
  to: string
  className?: string
  children: React.ReactNode
}

const NavLink = (props: NavLinkProps) => {
  const match = useMatch(props.to + '/*')

  return (
    <Link to={props.to} className={match ? 'nav-link active' : 'nav-link'}>
      {props.children}
    </Link>
  )
}

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navConfig: PropTypes.any,
  navFunc: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isOpen: PropTypes.bool,
  staticContext: PropTypes.any,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}

const defaultProps = {
  tag: 'nav',
  navConfig: {
    items: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        badge: { variant: 'info', text: 'NEW' },
      },
    ],
  },
  isOpen: false,
}

class AppSidebarNav extends Component<any> {
  static propTypes = propTypes
  static defaultProps = defaultProps

  constructor(props: any) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.activeRoute = this.activeRoute.bind(this)
    this.hideMobile = this.hideMobile.bind(this)
  }

  handleClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    e.currentTarget.parentElement.classList.toggle('open')
  }

  activeRoute(routeName: string) {
    return window.location.pathname.indexOf(routeName) > -1
      ? 'nav-item nav-dropdown open'
      : 'nav-item nav-dropdown'
  }

  hideMobile() {
    if (document.body.classList.contains('sidebar-show')) {
      document.body.classList.toggle('sidebar-show')
    }
  }

  // nav list
  navList(items: any[]) {
    return items.map((item, index) => this.navType(item, index))
  }

  // nav type
  navType(item: any, idx: number) {
    return item.title
      ? this.navTitle(item, idx)
      : item.divider
        ? this.navDivider(item, String(idx))
        : item.label
          ? this.navLabel(item, String(idx))
          : item.children
            ? this.navDropdown(item, String(idx))
            : this.navItem(item, String(idx))
  }

  // nav list section title
  navTitle(title, key) {
    const classes = classNames('nav-title', title.class)
    return (
      <li key={key} className={classes}>
        {this.navWrapper(title)}{' '}
      </li>
    )
  }

  // simple wrapper for nav-title item
  navWrapper(item: any) {
    return item.wrapper?.element
      ? React.createElement(
          item.wrapper.element,
          item.wrapper.attributes,
          item.name,
        )
      : item.name
  }

  // nav list divider
  navDivider(divider: any, key: string) {
    const classes = classNames('divider', divider.class)
    return <li key={key} className={classes} />
  }

  // nav label with nav link
  navLabel(item: any, key: string) {
    const classes = {
      item: classNames('hidden-cn', item.class),
      link: classNames('nav-label', item.class ? item.class : ''),
      icon: classNames(
        'nav-icon',
        !item.icon ? 'fa fa-circle' : item.icon,
        item.label.variant ? `text-${item.label.variant}` : '',
        item.label.class ? item.label.class : '',
      ),
    }
    return this.navLink(item, key, classes)
  }

  // nav dropdown
  navDropdown(item: any, key: string) {
    const classIcon = classNames('nav-icon', item.icon)
    return (
      <li key={key} className={this.activeRoute(item.url)}>
        <a
          className="nav-link nav-dropdown-toggle"
          href="#"
          onClick={this.handleClick}
        >
          <i className={classIcon} />
          {item.name}
          {this.navBadge(item.badge)}
        </a>
        <ul className="nav-dropdown-items">{this.navList(item.children)}</ul>
      </li>
    )
  }

  // nav item with nav link
  navItem(item: any, key: string) {
    const classes = {
      item: classNames(item.class),
      link: classNames(
        'nav-link',
        item.variant ? `nav-link-${item.variant}` : '',
      ),
      icon: classNames('nav-icon', item.icon),
    }
    return this.navLink(item, key, classes)
  }

  // nav link
  navLink(item: any, key: string, classes: any) {
    const url = item.url ? item.url : ''
    const itemIcon = <i className={classes.icon} />
    const itemBadge = this.navBadge(item.badge)
    const attributes = item.attributes || {}
    return (
      <NavItem key={key} className={classes.item}>
        {attributes.disabled ? (
          <RsNavLink href={''} className={classes.link} {...attributes}>
            {itemIcon}
            {item.name}
            {itemBadge}
          </RsNavLink>
        ) : this.isExternal(url) ? (
          <RsNavLink href={url} className={classes.link} active {...attributes}>
            {itemIcon}
            {item.name}
            {itemBadge}
          </RsNavLink>
        ) : (
          <NavLink
            item={item}
            to={url}
            className={classes.link}
            {...attributes}
          >
            {itemIcon}
            {item.name}
            {itemBadge}
          </NavLink>
        )}
      </NavItem>
    )
  }

  // badge addon to NavItem
  navBadge(badge: { text: string; variant: string; class: string }) {
    if (badge) {
      const classes = classNames(badge.class)
      return (
        <Badge className={classes} color={badge.variant}>
          {badge.text}
        </Badge>
      )
    }
    return null
  }

  isExternal(url: string) {
    const link = url ? url.substring(0, 4) : ''
    return link === 'http'
  }

  render() {
    const { className, children, navConfig, ...attributes } = this.props

    const attr = { ...attributes }
    delete attr.isOpen
    delete attr.staticContext
    delete attr.Tag
    delete attr.navigate
    delete attr.apiStats

    const navClasses = classNames(className, 'sidebar-nav')

    // ToDo: find better rtl fix
    const isRtl =
      getComputedStyle(document.querySelector('html')).direction === 'rtl'

    // sidebar-nav root
    return (
      <PerfectScrollbar
        className={navClasses}
        {...attr}
        options={{ suppressScrollX: !isRtl }}
      >
        <Nav>{children || this.navList(navConfig.items)}</Nav>
      </PerfectScrollbar>
    )
  }
}

export default AppSidebarNav
