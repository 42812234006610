import { apiRequest } from 'api'
import { Header, Loading, SelectCustomer } from 'components'
import { useApi, usePageTitle, useStateful } from 'hooks'
import get from 'lodash/get'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Button, Col, Container, CustomInput, Progress, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import AddressesForm from './AddressesForm'
import PaymentForm from './PaymentForm'
import ShoppingCart from './ShoppingCart'
import useCart, {
  addCartItem,
  fetchCustomerDetails,
  resetCustomer,
  setValues,
  reset,
  setReturnId,
  toggleIsReplacementOrder,
} from './useCart'
import { placeOrder } from './useCart/api'

const pageTitle = 'Add Order'

const AddOrder = () => {
  const location = useLocation()

  const [state, dispatch] = useCart('addorder')
  const [isLoading, setIsLoading] = useState(false)
  const {
    canPlaceOrder,
    cartItems,
    customer,
    orderProgress,
    orderPlaced,
    orderNumber,
    returnId,
    isReplacementOrder,
  } = state

  usePageTitle(pageTitle)

  const handleSelectCustomer = useCallback(
    async (val) => {
      setIsLoading(false)
      dispatch(
        setValues({
          customer: null,
        }),
      )

      if (!val?.value) {
        return
      }

      setIsLoading(true)
      dispatch(
        setValues({
          customer: val,
        }),
      )

      const customerId = val.value

      let fallbackEmail = ''
      const matches = get(val, 'label', '').match(/[.a-z0-9_-]+@[a-z0-9-.]+/i)
      if (matches && matches.length >= 1) {
        fallbackEmail = matches[0]
      }

      let data = {}

      if (Number(customerId) > 0) {
        try {
          data = await fetchCustomerDetails(customerId, fallbackEmail)
        } catch (e) {
          Swal.fire({
            text: `Error loading customer data: ${e.message}`,
            icon: 'error',
          })
          dispatch(
            setValues({
              customer: null,
            }),
          )
          setIsLoading(false)
          return
        }
      } else {
        dispatch(resetCustomer())

        if (fallbackEmail && customerId) {
          const email = customerId

          try {
            await apiRequest({
              action: 'customers_validateNewCustomerEmail',
              email,
            })
          } catch (e) {
            Swal.fire({
              text: e.message,
              icon: 'error',
            })
            setIsLoading(false)
            dispatch(reset())
            return
          }

          data = { ...data, shippingEmail: email }
        }
      }

      dispatch(
        setValues({
          ...data,
          isBillingSameAsShipping:
            null /* null means: figure out automatically. */,
        }),
      )
      setIsLoading(false)
    },
    [dispatch],
  )

  const handleClickNewOrder = useCallback(async () => {
    dispatch(reset())
  }, [dispatch])

  const handleClickPlaceOrder = useCallback(async () => {
    try {
      await placeOrder(state, dispatch)
    } catch (e) {
      Swal.fire({
        text: e.message,
        icon: 'error',
      })
    }
  }, [state, dispatch])

  const apiGetReturn = useApi({ action: 'returns_get' })

  const importFromReturn = useCallback(
    async (returnId) => {
      dispatch(reset())

      const ret = await apiGetReturn.performRequest({
        json: { id: returnId, withItems: true },
      })

      if (!ret) {
        return
      }

      if (ret.customer_id) {
        await handleSelectCustomer({ value: ret.customer_id })
      }

      if (ret.items) {
        for (const item of ret.items) {
          if (item.received_qty <= 0) {
            continue
          }

          dispatch(
            addCartItem(
              item.product_data,
              item.product_data.price,
              item.received_qty,
              false,
            ),
          )
        }
      }

      dispatch(setReturnId(returnId))
    },
    [dispatch, apiGetReturn, handleSelectCustomer],
  )

  /* Initialize form based on query param or navigate()-props. */
  const didInitialLoad = useStateful(false)
  useEffect(() => {
    if (didInitialLoad.value) {
      return
    }

    didInitialLoad.set(true)

    /* Load user passed via navigate(..., { userId: ... }) */

    const gotoUserId = location?.state ? (location.state as any).userId : null

    if (gotoUserId) {
      handleSelectCustomer({ value: gotoUserId })
      return
    }

    /* Check for returnId query param. */

    const params = new URLSearchParams(window.location.search)
    const returnId = params.get('returnId')

    if (returnId) {
      importFromReturn(returnId)
      return
    }
  }, [didInitialLoad, location, handleSelectCustomer, importFromReturn])

  const handleClickReplacementOrderCheckbox = useCallback(() => {
    dispatch(toggleIsReplacementOrder())
  }, [dispatch])

  return (
    <Container fluid>
      <div className="mt-4 addorder animated fadeIn">
        <Header isLoading={false}>{pageTitle}</Header>
        <Row style={{ maxWidth: 1200, minHeight: 1600 }}>
          <Col className="pr-4">
            <div className="floating-labels pb-4">
              <h5 className="mb-3">Customer Information</h5>
              <SelectCustomer
                className="mb-3"
                value={customer}
                onSelect={handleSelectCustomer}
              />

              {returnId ? (
                <CustomInput
                  className="checkbox"
                  type="checkbox"
                  bsSize="sm"
                  name="warranty"
                  label={`This is a replacement order for Return #${returnId}`}
                  id="checkbox-replacement-order"
                  checked={isReplacementOrder}
                  onChange={handleClickReplacementOrderCheckbox}
                />
              ) : null}

              {isLoading ? <Loading /> : null}

              {!isLoading && customer != null ? (
                <AddressesForm {...{ dispatch, ...state }} />
              ) : null}
            </div>
            {!isLoading && customer != null ? (
              <PaymentForm {...{ dispatch, ...state }} />
            ) : null}
          </Col>
          <Col style={{ paddingRight: '60px' }}>
            {!isLoading && customer != null ? (
              <>
                <ShoppingCart {...{ dispatch, ...state }} />

                {cartItems.length > 0 ? (
                  <Button
                    disabled={!canPlaceOrder}
                    style={{ fontWeight: 'bold', marginLeft: 'auto' }}
                    className="d-block mt-3"
                    color="primary"
                    onClick={handleClickPlaceOrder}
                  >
                    PLACE ORDER
                  </Button>
                ) : null}

                {orderProgress ? (
                  <Progress
                    className="rounded font-weight-bold mt-3 p-1"
                    bar
                    value="100"
                    animated
                    color="success"
                  >
                    {orderProgress}
                  </Progress>
                ) : null}

                {orderPlaced ? (
                  <div className="mt-3">
                    <div
                      className="font-weight-bold p-3 bg-success"
                      style={{ borderRadius: '5px' }}
                    >
                      <div>Order has been placed</div>
                      <div>Order Number: {orderNumber}</div>
                    </div>
                    <Button
                      style={{ fontWeight: 'bold', marginLeft: 'auto' }}
                      className="d-block mt-3"
                      color="primary"
                      onClick={handleClickNewOrder}
                    >
                      NEW ORDER
                    </Button>
                  </div>
                ) : null}
              </>
            ) : null}
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default AddOrder
