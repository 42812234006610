import { apiRequest } from 'api'
import { useCallback } from 'react'
import type { MultiValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import styles from './../selectStyles'

const SelectCategories = ({
  className,
  value,
  isDisabled,
  onChange,
  placeholder,
}: {
  className?: string
  value?: any
  isDisabled?: boolean
  onChange: (newValue: MultiValue<string>) => void
  placeholder?: string
}) => {
  const loadOptions = useCallback(async (query) => {
    try {
      const ret = await apiRequest({
        action: 'inventory_getCategories',
        query,
      })

      if (ret?.rows) {
        return ret.rows.map((row: { id: string; label: string }) => {
          return {
            value: row.id,
            label: row.label,
          }
        })
      }
      throw new Error('Invalid response')
    } catch (e) {
      throw new Error(e.message)
    }
  }, [])

  const Select = AsyncSelect

  return (
    <Select
      {...{
        className,
        onChange,
        loadOptions,
        value,
        isDisabled,
      }}
      styles={styles}
      isMulti
      cacheOptions
      placeholder={placeholder || 'Select Categories'}
    />
  )
}

export default SelectCategories
