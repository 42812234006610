import { useApi, usePrevious } from 'hooks'
import type { ApiCall } from 'hooks'
import { Fragment, useCallback, useEffect, useReducer } from 'react'
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap'
import { useDebouncedCallback } from 'use-debounce'
import states from 'utils/us-states'
import type { AddressType } from './types'

type State = {
  verifyResult: any
  verifiedAddress?: AddressType
  address: AddressType
}

const initialState: State = {
  verifyResult: null,
  verifiedAddress: null,
  address: {
    company: '',
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
  },
}

type AddressEditorProps = {
  orderId: number
  onClose: () => void
  addressType: 'shipping' | 'billing'
  getOrder: ApiCall
}

const getInitialState = (props: any) => {
  return { ...initialState, address: { ...props } }
}

type Action =
  | { type: 'SET_VALUE'; name: string; value: string }
  | { type: 'SET_VERIFIED_ADDRESS'; address: AddressType }
  | { type: 'SET_VERIFY_RESULT'; address: AddressType; result: any }

const reducer: React.Reducer<State, Action> = (
  state: State,
  action: Action,
) => {
  switch (action.type) {
    case 'SET_VALUE': {
      return {
        ...state,
        address: { ...state.address, [action.name]: action.value },
        verifyResult: null,
      }
    }
    case 'SET_VERIFIED_ADDRESS': {
      return { ...state, verifiedAddress: action.address }
    }
    case 'SET_VERIFY_RESULT': {
      const { address, result } = action

      if (JSON.stringify(address) === JSON.stringify(state.address)) {
        return { ...state, verifyResult: result }
      }

      return state
    }
    default:
      return state
  }
}

const VerifyResult = ({ status, messages }) => {
  if (status == null) {
    return null
  }

  if (status === 'verified') {
    return (
      <>
        <i
          style={{ color: 'rgb(37, 108, 43)', paddingRight: 3 }}
          className="fa fa-check-circle"
        />
        <span>Address verified.</span>
      </>
    )
  }

  if (Array.isArray(messages)) {
    return (
      <>
        {messages.map((m) => {
          return (
            <Fragment key={m.message}>
              <i
                style={{ paddingRight: 3 }}
                className="text-danger fa fa-times-circle"
              />
              <span key={m.message}>{m.message}</span>
            </Fragment>
          )
        })}
      </>
    )
  }

  return null
}

const AddressEditor = (props: AddressEditorProps) => {
  const { orderId, onClose, addressType, getOrder } = props
  const isShipping = addressType == 'shipping'

  const [state, dispatch] = useReducer(reducer, props as any, getInitialState)

  const verifyApi = useApi(() => ({ action: 'editOrder_verifyAddress' }))

  const verifyAddressNow = useCallback(() => {
    const doRequest = async () => {
      const address = state.address

      const result = await verifyApi.performRequest({
        json: {
          address_1: state.address.address,
          address_2: state.address.address2,
          city: state.address.city,
          state: state.address.state,
          postcode: state.address.zip,
        },
      })

      dispatch({ type: 'SET_VERIFY_RESULT', address, result })
    }

    doRequest()
  }, [state.address, verifyApi])

  const verifyAddress = useDebouncedCallback(
    useCallback(() => {
      verifyAddressNow()
    }, [verifyAddressNow]),
    750,
  )

  const prevAddressData = usePrevious(state.address)
  useEffect(() => {
    dispatch({ type: 'SET_VERIFIED_ADDRESS', address: state.address })

    if (JSON.stringify(state.address) !== JSON.stringify(prevAddressData)) {
      verifyAddress()
    }
  }, [state.address, prevAddressData, verifyAddress])

  const saveApi = useApi(
    () => ({ action: 'orders_updateAddress' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      const ret = await saveApi.performRequest({
        json: {
          id: orderId,
          addressType,
          address: state.address,
        },
      })

      if (ret) {
        getOrder.performRequest()
        onClose()
      }
    },
    [getOrder, orderId, addressType, state.address, saveApi, onClose],
  )

  const handleChange = useCallback((e) => {
    dispatch({
      type: 'SET_VALUE',
      name: e.target.name,
      value: e.target.value,
    })
  }, [])

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <FormGroup className="mb-1">
            <Input
              onChange={handleChange}
              value={state.address.company}
              type="text"
              id="addressEditor-company"
              name="company"
              placeholder="Company"
              autoComplete="nope"
              autoCorrect="off"
              spellCheck={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-1">
            <Input
              onChange={handleChange}
              value={state.address.firstName}
              type="text"
              id="addressEditor-firstName"
              name="firstName"
              placeholder="First name"
              autoComplete="nope"
              autoCorrect="off"
              spellCheck={false}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-1">
            <Input
              onChange={handleChange}
              value={state.address.lastName}
              type="text"
              id="addressEditor-lastName"
              name="lastName"
              placeholder="Last name"
              autoComplete="nope"
              autoCorrect="off"
              spellCheck={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-1">
            <Input
              onChange={handleChange}
              value={state.address.address}
              type="text"
              id="addressEditor-address"
              name="address"
              placeholder="Address"
              autoComplete="nope"
              autoCorrect="off"
              spellCheck={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-1">
            <Input
              onChange={handleChange}
              value={state.address.address2}
              type="text"
              id="addressEditor-address2"
              name="address2"
              placeholder="Apt, suite, etc."
              autoComplete="nope"
              autoCorrect="off"
              spellCheck={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-1">
            <Input
              onChange={handleChange}
              value={state.address.city}
              id="addressEditor-city"
              type="text"
              name="city"
              placeholder="City"
              autoComplete="nope"
              autoCorrect="off"
              spellCheck={false}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-1">
            <Input
              onChange={handleChange}
              id="addressEditor-zip"
              value={state.address.zip}
              type="text"
              name="zip"
              placeholder="Zip Code"
              autoComplete="nope"
              autoCorrect="off"
              spellCheck={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-1">
            <Input
              onChange={handleChange}
              value={state.address.state}
              placeholder="State"
              type="select"
              name="state"
              id="addressEditor-state"
              autoComplete="nope"
              autoCorrect="off"
              spellCheck={false}
            >
              <option value="">Select State...</option>
              {states.map((s) => {
                return (
                  <option key={s.abbreviation} value={s.abbreviation}>
                    {s.name}
                  </option>
                )
              })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      {isShipping ? (
        <>
          <Row>
            <Col>
              <FormGroup className="mb-1">
                <Input
                  onChange={handleChange}
                  value={state.address.email}
                  type="email"
                  name="email"
                  placeholder="Email address"
                  autoComplete="nope"
                  autoCorrect="off"
                  spellCheck={false}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-1">
                <Input
                  onChange={handleChange}
                  value={state.address.phone}
                  id="addressEditor-phone"
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  autoComplete="nope"
                  autoCorrect="off"
                  spellCheck={false}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <Col>
          <FormGroup className="mb-1">
            <Button type="submit" onClick={handleSubmit}>
              Save
            </Button>
          </FormGroup>
        </Col>
        <Col>
          <VerifyResult {...state.verifyResult} />
        </Col>
      </Row>
    </Form>
  )
}

export default AddressEditor
