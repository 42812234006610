import useBoolean from 'hooks/useBoolean'
import useFilters from 'hooks/useFilters'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { decodeQuery, parseQueryStr, updateQueryStr } from 'utils/url'

/**
 * This hook synchronizes the URL query string with the filter state.
 * */
export function useFiltersWithUrlUpdater(
  storagePrefix: string,
  visibleFilters: string[],
  staticFilters: string[] = [],
  onHydrated?: (filters: Record<string, any>) => void,
): FiltersTypeWithUrlUpdater {
  const hasFiltersInQueryString = window.location.search.length > 0

  const filters = useFilters(
    storagePrefix,
    visibleFilters,
    staticFilters,
    !hasFiltersInQueryString,
  )
  const { state } = filters

  const pathNameRef = useRef<string>(null)

  useEffect(() => {
    pathNameRef.current = window.location.pathname
  }, [])

  const isHydrated = useBoolean(false)

  const syncUrlWithState = useCallback(() => {
    const decodedFilters = decodeQuery(window.location.search)
    state.set(decodedFilters)

    if (onHydrated) {
      onHydrated(decodedFilters)
    }
  }, [state, onHydrated])

  /* If the query string has filters, load them and discard the state from
   * localStorage. */
  useEffect(() => {
    if (isHydrated.value) {
      return
    }

    if (hasFiltersInQueryString) {
      syncUrlWithState()
    }

    isHydrated.on()
  }, [hasFiltersInQueryString, syncUrlWithState, isHydrated])

  const queryStringProps = useMemo(() => {
    if (!isHydrated.value && window.location.search.length > 0) {
      return parseQueryStr(window.location.search)
    }

    const props = {}

    for (const key of Object.keys(state.value)) {
      const values = state.value[key].map((o: string | { value: string }) =>
        o && typeof o === 'object' ? o.value : o,
      )

      props[key] = values.join('~')
    }

    return props
  }, [state, isHydrated])

  const updateUrl = useCallback(() => {
    updateQueryStr(queryStringProps)
  }, [queryStringProps])

  useEffect(() => updateUrl(), [updateUrl])

  /* Handle back/forward navigation. */
  useEffect(() => {
    const handlePopState = () => {
      if (pathNameRef.current !== window.location.pathname) {
        return
      }

      syncUrlWithState()
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [syncUrlWithState])

  const searchQueryString = filters.q?.values.length ? filters.q.values[0] : ''

  return useMemo(
    () => ({
      ...filters,
      queryStringProps,
      searchQueryString,
      syncUrlWithState,
    }),
    [filters, queryStringProps, searchQueryString, syncUrlWithState],
  )
}
