import { EditPanelCard } from 'components'
import { usePrevious, useSet } from 'hooks'
import type { ApiCall } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { Badge, Nav } from 'reactstrap'
import ProductLine from './ProductLine'
import ReturnActionButton from './ReturnActionButton'

export type ProductType = {
  id: string
  name: string
  size: string
  sku: string
  quantity: string
  parentProductId: number
}

type OrphanedCardProps = {
  readOnly: boolean
  getOrders: ApiCall
  batchId: string
  triggerSearch: () => void
}

const OrphanedCard = (props: OrphanedCardProps) => {
  const { readOnly, getOrders, batchId, triggerSearch } = props
  const { hasResult, result } = getOrders
  const selectedProducts = useSet<number>()

  const handleClick = useCallback(
    (id) => {
      selectedProducts.toggle(Number(id))
    },
    [selectedProducts],
  )

  const products: ProductType[] = useMemo(
    () => (result?.orphanedProducts.length ? result.orphanedProducts : []),
    [result],
  )

  const numOrphanedPlants = hasResult ? result.numOrphanedPlants : 0

  const cleanupSelectedProducts = useCallback(() => {
    const ids = products.map((p) => Number(p.id))
    if (ids.length) {
      selectedProducts.remove(ids)
    }
  }, [products, selectedProducts])

  const prevProducts = usePrevious(products)

  /* Make sure "selectedProducts" doesn't include IDs that have been removed. */
  useEffect(() => {
    if (products !== prevProducts) {
      cleanupSelectedProducts()
    }
  }, [prevProducts, products, cleanupSelectedProducts])

  if (!products.length) {
    return null
  }

  const caption = (
    <>
      Orphaned Plants
      {products.length ? (
        <Badge style={{ fontSize: 13 }} className="ml-2" pill color="danger">
          {numOrphanedPlants}
        </Badge>
      ) : null}
    </>
  )

  return (
    <EditPanelCard
      caption={caption}
      stateId="orphaned_plants"
      bodyProps={{ className: 'p-0' }}
    >
      {!readOnly ? (
        <Nav className="p-1" pills>
          <ReturnActionButton
            {...{ batchId, selectedProducts, products, triggerSearch }}
          />
        </Nav>
      ) : null}

      {products.map((item) => {
        return (
          <ProductLine
            key={item.id}
            {...{
              product: item,
              onClick: handleClick,
              checked: selectedProducts.has(Number(item.id)),
            }}
          />
        )
      })}
    </EditPanelCard>
  )
}

export default OrphanedCard
