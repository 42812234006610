import { LoadingImage } from 'components'
import { useApi } from 'hooks'
import type { SetType } from 'hooks'
import { useCallback } from 'react'
import { NavItem, NavLink } from 'reactstrap'
import { askQuestion } from 'utils'
import type { ProductType } from '.'

type ReturnActionButtonProps = {
  selectedProducts: SetType<number>
  batchId: string
  triggerSearch: () => void
  products: ProductType[]
}

const ReturnActionButton = (props: ReturnActionButtonProps) => {
  const { selectedProducts, batchId, triggerSearch, products } = props

  const apiAction = useApi(null, null, () => ({ errorModal: true }))

  const handleClickReturn = useCallback(
    async (e) => {
      e.preventDefault()

      if (
        !(await askQuestion(
          'Are you sure you want to return the selected plants?',
        ))
      ) {
        return
      }

      await apiAction.performRequest({
        action: 'batches_dropCheckedInProducts',
        json: {
          batchId,
          droppedQtys: products
            .filter((p) => selectedProducts.has(Number.parseInt(p.id)))
            .map((p) => ({
              productId: Number.parseInt(p.id),
              droppedQty: Number.parseInt(p.quantity),
            })),
        },
      })

      triggerSearch()
    },
    [selectedProducts, triggerSearch, products, apiAction, batchId],
  )

  const isLoading = apiAction.isLoading
  const disabled = isLoading || selectedProducts.values.length === 0

  return (
    <NavItem>
      <NavLink
        href="#"
        onClick={handleClickReturn}
        className={disabled ? 'disabled' : ''}
      >
        Drop plants {isLoading ? <LoadingImage small /> : null}
      </NavLink>
    </NavItem>
  )
}

export default ReturnActionButton
