import { LoadingImage } from 'components'
import SidebarToggleButton from 'components/SidebarToggleButton'
import { type CSSProperties, useCallback } from 'react'
import { Link } from 'react-router'
import { BreadcrumbItem } from 'reactstrap'

const PageNavHead = ({
  children,
  pageTitle,
  isLoading,
  sidebar,
  onClickReload,
  prevPageLink,
  prevPageLabel,
  hasTail,
}: {
  children?: React.ReactNode
  pageTitle?: React.ReactNode
  isLoading?: boolean
  sidebar?: any
  onClickReload?: () => void
  prevPageLink?: string
  prevPageLabel?: string
  hasTail?: boolean
}) => {
  const handleClickReload = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      if (onClickReload) {
        onClickReload()
      }
    },
    [onClickReload],
  )

  const style: CSSProperties = {
    minHeight: 46,
    padding: '5px 4px 5px 1rem',
  }

  if (hasTail !== false) {
    style.margin = 0
    style.border = 'none'
  }

  return (
    <nav>
      <ol className="breadcrumb align-items-center" style={style}>
        {prevPageLink ? (
          <BreadcrumbItem>
            <Link to={prevPageLink}>{prevPageLabel}</Link>
          </BreadcrumbItem>
        ) : null}
        <BreadcrumbItem active>
          {onClickReload ? (
            <a
              style={{ color: '#73818f' }}
              href="#"
              onClick={handleClickReload}
            >
              {pageTitle}
            </a>
          ) : (
            <>{pageTitle}</>
          )}
          {isLoading ? <LoadingImage className="ml-2" small /> : null}
        </BreadcrumbItem>
        {children}
        {sidebar && !sidebar.isOpen && (
          <div style={{ marginLeft: 'auto' }}>
            <SidebarToggleButton sidebar={sidebar} />
          </div>
        )}
      </ol>
    </nav>
  )
}

export default PageNavHead
