import type { Discount } from '@ttc/api/discounts'
import { DropDownItemCheckbox } from 'components'
import type { ApiCall } from 'hooks'
import { useDropDownToggler } from 'hooks'
import keyBy from 'lodash/keyBy'
import { useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import type { ManageInventoryFiltersType } from '.'

type DiscountFilterButtonProps = {
  filters: ManageInventoryFiltersType
  apiLoadDiscounts: ApiCall
}

const DiscountFilterButton = (props: DiscountFilterButtonProps) => {
  const { filters, apiLoadDiscounts } = props

  const discountsFilter = filters.discount as FilterType

  const toggler = useDropDownToggler()

  const discounts = useMemo(
    () => (apiLoadDiscounts.result ? apiLoadDiscounts.result.rows : []),
    [apiLoadDiscounts],
  )

  const discountsById = useMemo(() => keyBy(discounts, 'id'), [discounts])

  if (apiLoadDiscounts.isLoading) {
    return null
  }

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle
        nav
        caret
        className={discountsFilter.values.length ? 'hasValue' : ''}
      >
        {discountsFilter.values.length === 0 ? (
          <span>Discount</span>
        ) : discountsFilter.values.length === 1 ? (
          <span>{discountsById[discountsFilter.values[0]]?.name}</span>
        ) : (
          <span>
            {discountsFilter.values
              .map((id: string) => discountsById[id]?.name)
              .join(',')}
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu className="pre-scrollable">
        <DropdownItem onClick={discountsFilter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {discounts.map((discount: Discount) => {
          return (
            <DropDownItemCheckbox
              id={String(discount.id)}
              key={discount.id}
              onClick={discountsFilter.toggle}
              isChecked={discountsFilter.has(String(discount.id))}
            >
              {discount.name}
            </DropDownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DiscountFilterButton
