import { LoadingImage } from 'components/Loading'
import { Component } from 'react'
import {
  Card,
  CardBody,
  type CardBodyProps,
  CardHeader,
  type CardProps,
  Col,
  Row,
} from 'reactstrap'

type EditPanelCardProps = {
  defaultIsOpen?: boolean
  stateId?: string
  isLoading?: boolean
  caption?: string | React.JSX.Element
  bodyProps?: CardBodyProps
  cardProps?: CardProps
  lazyLoadBody?: boolean
  headerActions?: React.ReactNode
  children: React.ReactNode
}

type EditPanelCardState = {
  isOpen: boolean
}

/**
 * `EditPanelCard` is a collapsible, responsive card structure. Collapsed state is kept in local storage.
 *
 * - Displays loading states (`isLoading`)
 * - Customizable content of header and body (`caption`, `children`)
 * - Provides element property customizability (e.g., `bodyProps`, `cardProps`)
 * - Supports conditional lazy loading of the body content (`lazyLoadBody`)
 */
export default class EditPanelCard extends Component<
  EditPanelCardProps,
  EditPanelCardState
> {
  constructor(props: EditPanelCardProps) {
    super(props)

    const defaultIsOpen =
      props.defaultIsOpen != null ? props.defaultIsOpen : true
    let isOpen = defaultIsOpen

    if (props.stateId != null) {
      const state = localStorage.getItem(this.getStorageKey(props.stateId))
      if (state != null) {
        isOpen = state === '1'
      }
    }

    this.state = { isOpen }
  }

  getStorageKey(stateId: string) {
    return `editpanel.${stateId}.isOpen`
  }

  handleClickToggle = (e: React.MouseEvent<HTMLElement>) => {
    const { stateId } = this.props

    e.preventDefault()

    const isOpen = !this.state.isOpen

    if (stateId != null) {
      localStorage.setItem(this.getStorageKey(stateId), isOpen ? '1' : '0')
    }

    this.setState({ isOpen })
  }

  render() {
    const {
      isLoading,
      caption,
      bodyProps,
      cardProps,
      lazyLoadBody,
      headerActions,
    } = this.props
    const { isOpen } = this.state

    return (
      <Row>
        <Col>
          <Card {...cardProps}>
            <CardHeader
              className="pl-3 pr-3 pt-2 pb-2 d-flex justify-content-between align-items-center card-header card-header"
              onClick={this.handleClickToggle}
              style={isOpen ? {} : { borderWidth: 0 }}
            >
              <span>
                {caption}
                {isLoading ? <LoadingImage small /> : ''}
              </span>
              <div className="card-header-actions">
                {headerActions}
                <a
                  className="card-header-action btn btn-minimize"
                  onClick={this.handleClickToggle}
                >
                  {isOpen ? (
                    <i className="icon-arrow-up" />
                  ) : (
                    <i className="icon-arrow-down" />
                  )}
                </a>
              </div>
            </CardHeader>
            <div style={{ display: this.state.isOpen ? 'block' : 'none' }}>
              {!lazyLoadBody || (lazyLoadBody && isOpen) ? (
                <CardBody {...bodyProps}>{this.props.children}</CardBody>
              ) : null}
            </div>
          </Card>
        </Col>
      </Row>
    )
  }
}
