import { apiRequest } from 'api'
import type {
  TableCellChangeEventProps,
  TableCellRenderProps,
} from 'app/components/Table/types'
import {
  FilterBar,
  NotesCell,
  PageNavHead,
  PageNavTail,
  useNotesCell,
} from 'components'
import {
  Table,
  type Row as TableRow,
  defaultCellRenderer,
  setCellValue,
  setEditCell,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import ManageTagsPanel from 'containers/common/ManageTagsPanel'
import SelectSupplierPanel from 'containers/common/SelectSupplierPanel'
import TagActionButton from 'containers/common/TagActionButton'
import {
  useApi,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdater,
  usePageTitle,
  usePanelControl,
  useStateful,
} from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { Badge, Button, Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'
import Filters, { type ManagePurchaseOrdersFiltersType } from './Filters'
import { getColumnDef } from './columnDef'

const defaultMaxYear = new Date().getFullYear()

const visibleFilters = ['q', 'year', 'tags']

const ManagePurchaseOrders = (props: {
  readOnly: boolean
  isDraftsView?: boolean
}) => {
  const navigate = useNavigate()

  const { readOnly, isDraftsView } = props

  const manageTagsPanel = usePanelControl()
  const manageColumnsPanel = usePanelControl()

  const filters = useFiltersWithUrlUpdater(
    'managePurchaseOrdersFilters',
    visibleFilters,
  ) as ManagePurchaseOrdersFiltersType

  const addPanel = usePanelControl()

  const cacheKey = useMemo(
    () => (isDraftsView ? 'manageDraftPurchaseOrders' : 'managePurchaseOrders'),
    [isDraftsView],
  )

  const [setItem, getItem] = getStorage(cacheKey)

  const [state, dispatch] = useTable({
    cacheKey,
    getItem,
    setItem,
  })
  const { query, isLoading, rows, selectedRows } = state
  const [triggerSearch] = useTableApi(
    'purchaseOrders_getAll',
    state,
    dispatch,
    {
      query: '',
      filterStatus: isDraftsView ? 'DRAFT,REQUESTED' : 'CONFIRMED',
      ...filters.requestProps,
    },
  )

  const pageTitle = useMemo(
    () =>
      isDraftsView ? 'Manage Draft Purchase Orders' : 'Manage Purchase Orders',
    [isDraftsView],
  )
  usePageTitle(pageTitle)

  const columnDef = useMemo(
    () => getColumnDef(isDraftsView, readOnly),
    [isDraftsView, readOnly],
  )

  const columns = useTableColumns(
    'managePurchaseOrders',
    state,
    dispatch,
    columnDef,
  )

  const maxYear = useStateful(defaultMaxYear)

  useEffect(() => {
    if (state.response?.maxYear && state.response.maxYear !== maxYear.value) {
      maxYear.set(state.response.maxYear)
    }
  }, [state.response, maxYear])

  /* Trigger search when filters change. */
  useEffectIfObjectChanges(triggerSearch, filters.requestProps)

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  const linkToPurchaseOrder = useCallback(
    (id: string) => {
      return `/${isDraftsView ? 'draft-' : ''}purchase-orders/edit/${id}`
    },
    [isDraftsView],
  )

  const handleClickIdCell = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement>) => {
      const td = (e.target as HTMLElement).closest('td')
      if (!td) {
        return
      }
      const id = td.getAttribute('data-id')
      navigate(linkToPurchaseOrder(id))
    },
    [navigate, linkToPurchaseOrder],
  )

  const notesCell = useNotesCell()

  const handleNoteUpdated = useCallback(
    (id: number, note: string) => {
      dispatch(setCellValue(String(id), 'note', note))
    },
    [dispatch],
  )

  const renderNotesCell = useCallback(
    (props: TableCellRenderProps<TableRow>) => {
      return (
        <td>
          <NotesCell
            {...{
              dropDownState: notesCell.dropDownState,
              readOnly,
              note: props.row.note,
              id: Number(props.row.id),
              onNoteUpdated: handleNoteUpdated,
              apiAction: 'purchaseOrders_setNote',
            }}
          />
        </td>
      )
    },
    [notesCell, readOnly, handleNoteUpdated],
  )

  const renderCell = useCallback(
    (props: TableCellRenderProps) => {
      if (props.col.id === 'id') {
        return (
          <td
            onClick={handleClickIdCell}
            data-id={props.value}
            className="cell id"
          >
            {props.value}
          </td>
        )
      }
      if (props.col.id === 'invoice') {
        return (
          <td className="inactive">
            <i className="fa fa-file" />
          </td>
        )
      }
      if (props.col.id === 'notes') {
        return (
          <td className="inactive">
            <i className="fa fa-sticky-note" />
          </td>
        )
      }
      if (props.col.id === 'payment') {
        return (
          <td>
            <Badge
              pill
              color={`${props.value === 'PAID' ? 'success' : 'danger'}`}
            >
              {props.value}
            </Badge>
          </td>
        )
      }
      if (props.col.id === 'status') {
        return (
          <td>
            <Badge pill color="light">
              {props.value}
            </Badge>
          </td>
        )
      }
      if (props.col.id === 'note') {
        return renderNotesCell(props)
      }

      return defaultCellRenderer(props)
    },
    [handleClickIdCell, renderNotesCell],
  )

  const handleAddPurchaseOrder = useCallback(
    async (id: string) => {
      const ret = await apiRequest({
        action: 'purchaseOrders_add',
        json: {
          supplier_id: id,
          draft: isDraftsView,
        },
      })

      if (ret?.id) {
        navigate(linkToPurchaseOrder(ret.id))
      }
    },
    [navigate, linkToPurchaseOrder, isDraftsView],
  )

  const getTags = useApi(
    () => ({ action: 'purchaseOrderTags_getAll' }),
    () => [],
    () => ({
      autoPerform: true,
      normalize: (input) => input.rows,
    }),
  )

  const handleCloseTagsPanel = useCallback(() => {
    manageTagsPanel.close()
    getTags.performRequest()
  }, [manageTagsPanel, getTags])

  const saveApi = useApi(
    () => ({ action: 'purchaseOrders_update' }),
    null,
    () => ({ throws: true }),
  )

  const handleChangeCell = useCallback(
    async (props: TableCellChangeEventProps) => {
      const { value, rowId } = props

      await saveApi.performRequest({ id: rowId, order_status: value })

      triggerSearch()
    },
    [saveApi, triggerSearch],
  )

  const handleClickCell = useCallback(
    (rowId: string, colId: string) => {
      if (readOnly) {
        return false
      }

      // Toggle edit-mode of status column.
      dispatch(setEditCell(rowId, colId))

      return false
    },
    [readOnly, dispatch],
  )

  return (
    <>
      <PageNavHead {...{ isLoading, pageTitle, onClickReload: triggerSearch }}>
        <div style={{ marginLeft: 'auto' }}>
          {!readOnly && isDraftsView ? (
            <Button className="mr-2" onClick={addPanel.open}>
              Add Draft Purchase Order
            </Button>
          ) : null}
          {!readOnly && !isDraftsView ? (
            <Button className="mr-2" onClick={addPanel.open}>
              Add Purchase Order
            </Button>
          ) : null}
        </div>
      </PageNavHead>
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
        }}
      >
        {!readOnly ? (
          <TagActionButton
            toggleTagApiAction="purchaseOrders_toggleTags"
            {...{
              selectedRows,
              rows,
              tags: getTags.result,
              onClickManage: manageTagsPanel.open,
              onModified: triggerSearch,
            }}
          />
        ) : null}
      </PageNavTail>
      <FilterBar {...{ filters, manageColumnsPanel, columns }}>
        <Filters
          {...{ filters, tags: getTags.result, maxYear: maxYear.value }}
        />
      </FilterBar>
      <Container fluid className="mt-4">
        <div className="manage-orders animated fadeIn">
          <ManageTagsPanel
            apiPrefix="purchaseOrderTags"
            isOpen={manageTagsPanel.isOpen}
            onClose={handleCloseTagsPanel}
          />
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <SelectSupplierPanel
            caption={
              isDraftsView ? 'Add Draft Purchase Order' : 'Add Purchase Order'
            }
            isOpen={addPanel.isOpen}
            onClose={addPanel.close}
            onSave={handleAddPurchaseOrder}
          />
          <Row>
            <Col>
              <Table
                onChangeCell={handleChangeCell}
                onClickCell={handleClickCell}
                entityName="orders"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                  renderCell,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ManagePurchaseOrders
