import { usePrevious } from '@ttc/hooks'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Form, Input } from 'reactstrap'

type SearchFormProps = {
  query: string
  placeholder?: string
  onChange: (query: string) => void
  onPaste?: (e: ClipboardEvent) => string
}

const SearchForm = (props: SearchFormProps) => {
  const { query, placeholder, onChange, onPaste } = props
  const [input, setInput] = useState(query)
  const inputEl = useRef<HTMLInputElement>(null)

  const handleChangeSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value)
    },
    [],
  )

  const handleSubmitSearch = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onChange(input)
    },
    [input, onChange],
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        setInput('')
        onChange('')
      }
    },
    [onChange],
  )

  const handleBlur = useCallback(() => {
    onChange(input)
  }, [onChange, input])

  const handleClipboardPaste = useCallback(
    (e: ClipboardEvent) => {
      if (onPaste) {
        const str = onPaste(e)

        if (str != null) {
          setInput(str)
          onChange(str)
        }
      }
    },
    [onPaste, onChange],
  )

  // Update input value if query changes, for example on back/forward navigation.
  const prevQuery = usePrevious(query)
  useEffect(() => {
    if (prevQuery !== query && input !== query) {
      setInput(query)
    }
  }, [prevQuery, query, input])

  useEffect(() => {
    const ref = inputEl.current

    ref.addEventListener('paste', handleClipboardPaste)

    return () => ref.removeEventListener('paste', handleClipboardPaste)
  }, [handleClipboardPaste])

  return (
    <div className="form-group has-search mb-0">
      <span className="fa fa-search form-control-feedback" />
      <Form onSubmit={handleSubmitSearch}>
        <Input
          innerRef={inputEl}
          type="search"
          onKeyDown={handleKeyDown}
          onChange={handleChangeSearch}
          onBlur={handleBlur}
          placeholder={placeholder || 'Search'}
          value={input}
        />
      </Form>
    </div>
  )
}

export default SearchForm
