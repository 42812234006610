import Loading from 'components/Loading'
import TextField from 'components/TextField'
import { useApi } from 'hooks'
import { useCallback, useEffect, useReducer } from 'react'
import { Button } from 'reactstrap'

const initialState = {
  couponAmount: '0',
}

const reducer = (state, values) => {
  return { ...state, ...values }
}

const ConfigForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { couponAmount } = state

  const apiGet = useApi(
    () => ({ action: 'storeCredits_getDefaults' }),
    null,
    () => ({
      autoPerform: true,
    }),
  )

  useEffect(() => {
    dispatch(apiGet.result)
  }, [apiGet.result])

  const apiSave = useApi(
    () => ({ action: 'storeCredits_setDefaults' }),
    null,
    () => ({
      errorModal: true,
    }),
  )

  const handleChangeInput = useCallback((e) => {
    const { type, name, value, checked } = e.currentTarget

    dispatch({ [name]: type === 'checkbox' ? checked : value })
  }, [])

  const handleClickSave = useCallback(async () => {
    await apiSave.performRequest({ json: { couponAmount } })
  }, [apiSave, couponAmount])

  const canSave = couponAmount != ''

  if (apiGet.error) {
    return <div className="text-danger">{apiGet.error}</div>
  }

  return (
    <>
      <div className="floating-labels">
        <TextField
          name="couponAmount"
          value={couponAmount}
          onChange={handleChangeInput}
          id="input-couponAmount"
          label="Default Dollar Amount"
          required
        />
        <div className="mt-2 mb-2 text-right">
          <Button
            disabled={!canSave || Boolean(apiSave.isLoading)}
            color="primary"
            onClick={handleClickSave}
          >
            <i className="fa fa-save mr-2" />
            Save
          </Button>
        </div>
        {apiSave.isLoading ? <Loading /> : null}
      </div>
    </>
  )
}

export default ConfigForm
