import { Component } from 'react'
import { Button, Col, Form, FormGroup, Row } from 'reactstrap'

type EditPanelProps = {
  isOpen?: boolean
  isLoading?: boolean
  onClose?: () => void
  embedded?: boolean
  error?: string
  caption?: string | React.JSX.Element
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void
  noHeader?: boolean
  submitButtonText?: string
  canSubmit?: boolean
  submitButtonLabel?: string
  children: React.ReactNode
  hasButtons?: boolean
}

/**
 * `EditPanel` is used to create sidebars or other embedded forms.
 *
 * - Handles form encapsulation and management (`onSubmit`)
 * - Operates as a modal or an embedded component (`embedded`)
 * - Visibility can be controlled on demand (`isOpen`)
 * - Progress indicators can be displayed (`isLoading`)
 * - Displays error messages as necessary (`error`)
 * - Customize titles and other aspects: (e.g., `noHeader`, `submitButtonText`)
 */
export default class EditPanel extends Component<EditPanelProps> {
  hasEvent = false

  componentDidMount() {
    this.updateEvent()
  }

  componentDidUpdate() {
    this.updateEvent()
  }

  handleClickClose = (e: React.MouseEvent<HTMLElement>) => {
    const { isLoading, onClose } = this.props

    e.preventDefault()

    if (isLoading) {
      return
    }

    if (onClose) {
      onClose()
    }
  }

  handleKeyUp = (e: KeyboardEvent) => {
    const { isOpen, onClose } = this.props

    if (e.target instanceof HTMLInputElement) {
      return
    }

    if (isOpen && onClose && e.key === 'Escape') {
      onClose()
    }
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { onSubmit } = this.props

    e.preventDefault()

    if (onSubmit) {
      onSubmit(e)
    }
  }

  updateEvent() {
    const { isOpen } = this.props

    if (!isOpen && this.hasEvent) {
      document.removeEventListener('keyup', this.handleKeyUp)
      this.hasEvent = false
      return
    }

    if (isOpen && !this.hasEvent) {
      document.addEventListener('keyup', this.handleKeyUp)
      this.hasEvent = true
    }
  }

  render() {
    const {
      embedded,
      error,
      caption,
      isOpen,
      onSubmit,
      canSubmit,
      onClose,
      noHeader,
      submitButtonLabel,
      hasButtons,
    } = this.props

    return (
      <>
        {!embedded ? (
          <div
            className={`edit-panel-bg-layer ${isOpen ? 'show' : ''}`}
            onClick={this.handleClickClose}
          />
        ) : null}
        <div
          className={`edit-panel ${isOpen ? 'show' : ''} ${
            embedded ? '' : 'fixed'
          }`}
        >
          <Form onSubmit={this.handleSubmit}>
            {!noHeader ? (
              <Row>
                <Col className="d-flex justify-content-between align-items-center m-3 pl-1 pr-1">
                  <span style={{ fontSize: '1rem' }}>{caption}</span>
                  {onClose ? (
                    <a
                      href="#"
                      onClick={this.handleClickClose}
                      style={{ fontSize: '20px', color: '#a7c5de' }}
                      className="btn btn-setting p-0 m-0"
                    >
                      <i className="icon-close" />
                    </a>
                  ) : null}
                </Col>
              </Row>
            ) : null}
            {error ? (
              <div className="m-3 text-danger">{error}</div>
            ) : (
              this.props.children
            )}
            {hasButtons !== false && !error && onSubmit ? (
              <Row>
                <Col>
                  <FormGroup className="form-actions mt-3 text-center">
                    {onClose ? (
                      <Button
                        size="lg"
                        onClick={this.handleClickClose}
                        outline
                        className="cancel mr-2"
                      >
                        Cancel
                      </Button>
                    ) : null}
                    <Button
                      disabled={canSubmit === false}
                      size="lg"
                      type="submit"
                      color="primary"
                      className="mr-2"
                    >
                      {submitButtonLabel ? submitButtonLabel : 'Save Changes'}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
          </Form>
        </div>
      </>
    )
  }
}
