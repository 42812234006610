import { useCallback, useMemo } from 'react'
import { useBoolean } from '.'

export const useDropDownToggler = () => {
  const isOpen = useBoolean(false)

  const toggle = useCallback(
    (e) => {
      if (e?.currentTarget?.getAttribute) {
        const a = e.currentTarget.getAttribute('data-auto-close')
        if (a === 'false') {
          return
        }
      }

      isOpen.toggle()
    },
    [isOpen],
  )

  return useMemo(
    () => ({
      isOpen: isOpen.value,
      toggle,
    }),
    [isOpen, toggle],
  )
}

export default useDropDownToggler
