import { useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const ImportActionButton = () => {
  const navigate = useNavigate()

  const toggler = useDropDownToggler()

  const handleClickImportProducts = useCallback(async () => {
    navigate('/inventory/import-products')
  }, [navigate])

  const handleClickImportVariations = useCallback(async () => {
    navigate('/inventory/import-variations')
  }, [navigate])

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret>
        Import
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickImportProducts}>
          Products
        </DropdownItem>
        <DropdownItem onClick={handleClickImportVariations}>
          Variations
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ImportActionButton
