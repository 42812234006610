import type { InventoryGetAttributesResult } from '@ttc/api/inventory'
import { DropDownItemCheckbox, Loading } from 'components'
import { useApi, useDropDownToggler, usePrevious } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const FilterButton = ({ onClick, label, value }) => {
  const handleClick = useCallback(async () => {
    onClick(value)
  }, [onClick, value])

  return <DropdownItem onClick={handleClick}>{label}</DropdownItem>
}

type AttributeFilterButtonProps = {
  filter: FilterType
  label: string
  options: any[]
}

const AttributeFilterButton = (props: AttributeFilterButtonProps) => {
  const { filter, label, options } = props

  const toggler = useDropDownToggler()

  const optionLabels = useMemo(() => {
    const ret = {}

    for (const attr of options) {
      ret[attr.value] = attr.label
    }

    return ret
  }, [options])

  const naValueId = options.find((option) => option.label === 'n/a')?.value

  const isSpecialId = useCallback(
    (id: string) => {
      return id === naValueId || id === '(empty)'
    },
    [naValueId],
  )

  // The filters "n/a" and "(empty)" are mutally exclusive.
  const handleClickFilter = useCallback(
    (id: string) => {
      if (isSpecialId(id)) {
        filter.setValues([id])
        return
      }

      let values = [...filter.values]

      if (values.includes(id)) {
        values = values.filter((val) => val !== id)
      } else {
        values.push(id)
      }

      values = values.filter((val) => !isSpecialId(val))

      filter.setValues(values)
    },
    [filter, isSpecialId],
  )

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle
        nav
        caret
        className={filter.values.length > 0 ? 'hasValue' : ''}
      >
        <span>
          {filter.values.length === 1 && isSpecialId(filter.values[0])
            ? `${label}: ${optionLabels[filter.values[0]]}`
            : filter.values.length === 1
              ? optionLabels[filter.values[0]]
              : filter.values.length > 1
                ? `${filter.values.length} ${label}`
                : label}
        </span>
      </DropdownToggle>
      <DropdownMenu className="pre-scrollable">
        <DropdownItem onClick={filter.clear}>
          <i className="mr-1 fa fa-ban" /> Remove
        </DropdownItem>
        {options.map((option) => {
          const { label, value } = option

          const isSpecial = label === 'empty' || label === 'n/a'

          return (
            <DropDownItemCheckbox
              id={value}
              key={value}
              onClick={handleClickFilter}
              isChecked={filter.values
                .map((a) => String(a))
                .includes(String(value))}
            >
              {isSpecial ? <span className="font-italic">{label}</span> : label}
            </DropDownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

type ProductAttributesFilterButtonProps = {
  filters: FiltersType
}

const ProductAttributesFilterButton = (
  props: ProductAttributesFilterButtonProps,
) => {
  const { filters } = props

  const toggler = useDropDownToggler()

  const apiLoad = useApi<InventoryGetAttributesResult | null>(
    { action: 'inventory_getAttributes' },
    () => [],
    () => ({ autoPerform: true }),
  )

  const apiLoadResult = apiLoad.result

  const togglerIsOpenPrev = usePrevious(toggler.isOpen)
  useEffect(() => {
    if (togglerIsOpenPrev != toggler.isOpen && toggler.isOpen) {
      apiLoad.performRequest()
    }
  }, [togglerIsOpenPrev, toggler.isOpen, apiLoad])

  const attributeFilters = Object.keys(filters.filters)
    .filter((key) => key.indexOf('pa_') === 0)
    .map((filter) => {
      return {
        key: filter,
        filter: filters.filters[filter],
      }
    })

  const attributeLabels = useMemo(() => {
    const ret = {}

    for (const attr of apiLoadResult) {
      ret[attr.value] = attr.label
    }

    return ret
  }, [apiLoadResult])

  const attributeOptions = useMemo(() => {
    const ret = {}

    for (const attr of apiLoadResult) {
      const options = attr.options.map((option) => ({
        ...option,
        value: String(option.value),
      }))

      ret[attr.value] = [{ value: '(empty)', label: 'empty' }, ...options]
    }

    return ret
  }, [apiLoadResult])

  return (
    <>
      <Dropdown nav {...toggler}>
        <DropdownToggle nav caret>
          <span>Attributes</span>
        </DropdownToggle>
        <DropdownMenu className="pre-scrollable">
          {apiLoad.isLoading && (apiLoadResult || []).length === 0 ? (
            <DropdownItem>
              <Loading />
            </DropdownItem>
          ) : null}
          {(apiLoadResult || []).map((row) => {
            return (
              <FilterButton
                label={row.label}
                value={row.value}
                key={row.value}
                onClick={filters.addFilter}
              />
            )
          })}
        </DropdownMenu>
      </Dropdown>
      {!apiLoad.hasResult
        ? null
        : attributeFilters.map(({ key, filter }) => {
            const label = attributeLabels[key] || key
            const options = attributeOptions[key] || []

            return (
              <AttributeFilterButton
                key={key}
                {...{
                  filter,
                  label,
                  options,
                }}
              />
            )
          })}
    </>
  )
}

export default ProductAttributesFilterButton
