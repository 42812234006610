import { EditPanel, EditPanelCard, Loading } from 'components'
import { useApi, usePrevious } from 'hooks'
import { Fragment, useCallback, useEffect, useReducer } from 'react'
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'

type Capability = {
  id: string
  label: string
  isFullAccess: boolean
}

type State = {
  isLoading: boolean
  error: string | null
  errorScope: string | null
  values: {
    username: string
    firstName: string
    lastName: string
    caps: Capability[]
  }
}

type Action =
  | { type: 'SET_ERROR'; error: string; scope: string }
  | { type: 'SET_VALUE'; name: string; value: string }
  | { type: 'SET_VALUES'; values: any }
  | { type: 'TOGGLE_CAP'; name: string }
  | { type: 'RESET' }

const initialState: State = {
  isLoading: false,
  error: null,
  errorScope: null,
  values: {
    username: '',
    firstName: '',
    lastName: '',
    caps: [],
  },
}

const SET_ERROR = 'SET_ERROR'
const SET_VALUE = 'SET_VALUE'
const SET_VALUES = 'SET_VALUES'
const TOGGLE_CAP = 'TOGGLE_CAP'
const RESET = 'RESET'

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_ERROR: {
      return {
        ...state,
        error: action.error,
        errorScope: action.scope,
      }
    }
    case SET_VALUE: {
      return {
        ...state,
        values: { ...state.values, [action.name]: action.value },
      }
    }
    case TOGGLE_CAP: {
      return {
        ...state,
        values: {
          ...state.values,
          caps: {
            ...state.values.caps,
            [action.name]: !state.values.caps[action.name],
          },
        },
      }
    }
    case SET_VALUES: {
      return { ...initialState, values: action.values }
    }
    case RESET: {
      return { ...initialState }
    }
    default:
      return state
  }
}

const CardCaps = ({
  caps,
  state,
  handleChangeCap,
}: {
  caps: Capability[]
  state: State
  handleChangeCap: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <EditPanelCard caption="Capabilities" stateId="employee_caps">
      {caps.map((cap: Capability) => {
        if (!cap.isFullAccess) {
          return null
        }

        const id = cap.id + '_readwrite'

        return (
          <Row key={id}>
            <Col>
              <FormGroup className="mb-0">
                <CustomInput
                  onChange={handleChangeCap}
                  bsSize="lg"
                  checked={state.values.caps[id] || false}
                  label={cap.label}
                  type="checkbox"
                  id={`cap-checkbox-${id}`}
                  name={id}
                />
              </FormGroup>
            </Col>
          </Row>
        )
      })}
    </EditPanelCard>
  )
}

const CardContactInfo = ({ state, handleChange }) => {
  const { error, errorScope, values } = state

  return (
    <EditPanelCard caption="Employee info" stateId="shipemployee_info">
      {error && errorScope === 'form' ? (
        <Row>
          <Col className="text-danger">{error}</Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          <FormGroup className="mb-0">
            <Label className="mb-0" htmlFor="employee-username">
              Username
            </Label>
            <Input
              onChange={handleChange}
              value={values.username}
              type="text"
              id="employee-username"
              name="username"
              placeholder="Username"
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-0">
            <Label className="mb-0" htmlFor="employee-first-name">
              First Name
            </Label>
            <Input
              onChange={handleChange}
              value={values.firstName}
              type="text"
              id="employee-first-name"
              name="firstName"
              placeholder="First Name"
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-0">
            <Label className="mb-0" htmlFor="employee-last-name">
              Last Name
            </Label>
            <Input
              onChange={handleChange}
              value={values.lastName}
              type="text"
              id="employee-last-name"
              name="lastName"
              placeholder="Last Name"
              required
            />
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

const CardActions = ({ handleClickDelete, handleClickResetBadge }) => {
  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickDelete}
            >
              Delete Employee
            </Button>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickResetBadge}
            >
              Reset Badge
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type EmployeeEditorProps = {
  isOpen: boolean
  onClose: () => void
  editId: string
  onClickDelete: (editId: string, name: string) => void
  role: string
  caps: any
  hasCapabilities?: boolean | ((capability: string) => boolean)
}

const EmployeeEditor = (props: EmployeeEditorProps) => {
  const {
    isOpen,
    onClose,
    editId,
    onClickDelete,
    role,
    caps,
    hasCapabilities,
  } = props
  const [state, dispatch] = useReducer(reducer, initialState)
  const { error, errorScope } = state

  const apiGet = useApi(() => ({ action: 'users_get' }), null, {
    errorModal: true,
  })

  const loadData = useCallback(async () => {
    dispatch({ type: SET_VALUES, values: { ...initialState.values } })

    if (editId == null) {
      dispatch({ type: RESET })
      return
    }

    const values = await apiGet.performRequest({ id: editId })

    if (!values) {
      onClose()
    }

    dispatch({ type: SET_VALUES, values })
  }, [apiGet, onClose, editId])

  const apiSave = useApi(() => ({}), null, {
    throws: true,
  })

  const saveData = useCallback(async () => {
    try {
      await apiSave.performRequest({
        action: editId ? 'users_save' : 'users_create',
        json: {
          id: editId,
          role,
          ...state.values,
        },
      })

      onClose()

      dispatch({ type: RESET })
    } catch (e) {
      dispatch({ type: SET_ERROR, error: e.message, scope: 'form' })
    }
  }, [apiSave, editId, state, onClose, role])

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (isOpen && prevIsOpen !== isOpen) {
      loadData()
    }
  }, [prevIsOpen, isOpen, loadData])

  const handleChange = useCallback((e) => {
    dispatch({ type: SET_VALUE, name: e.target.name, value: e.target.value })
  }, [])

  const handleChangeCap = useCallback((e) => {
    dispatch({ type: TOGGLE_CAP, name: e.target.name })
  }, [])

  const handleClickDelete = useCallback(() => {
    if (onClickDelete) {
      onClickDelete(editId, state.values.username)
    }
  }, [onClickDelete, editId, state.values.username])

  const apiReset = useApi(() => ({ action: 'users_resetBadge' }), null, {
    errorModal: true,
  })

  const handleClickResetBadge = useCallback(async () => {
    await apiReset.performRequest({ id: editId })
    onClose()
  }, [apiReset, editId, onClose])

  const caption = `${editId == null ? 'Add' : 'Edit'} Employee`

  const isLoading = apiSave.isLoading || apiGet.isLoading

  return (
    <EditPanel
      {...{
        onSubmit: isLoading ? null : saveData,
        error: errorScope != null ? null : error,
        isOpen,
        onClose,
        caption,
        isLoading,
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment key={editId}>
          {editId != null ? (
            <CardActions {...{ handleClickDelete, handleClickResetBadge }} />
          ) : null}
          <CardContactInfo {...{ state, handleChange }} />
          {hasCapabilities ? (
            <CardCaps {...{ state, caps, handleChangeCap }} />
          ) : null}
        </Fragment>
      )}
    </EditPanel>
  )
}

export default EmployeeEditor
