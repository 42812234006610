import get from 'lodash/get'
import { Fragment, memo } from 'react'
import defaultCellRenderer from './defaultCellRenderer'
import type {
  Action,
  BaseRow,
  Row,
  TableCell,
  TableCellLoading,
} from './reducer/types'
import type {
  ColumnDef,
  TableCellChangeEventProps,
  TableCellRenderProps,
} from './types'

// Enable this to log when a row is re-rendered.
// Useful to debug performance issues.
const DEBUG_ROW_RENDER = false

export type TableRowProps<RowType extends BaseRow> = {
  /** Definitions of the table's columns. */
  columnDef: ColumnDef<RowType>[]
  /** Data for the current row. */
  row: RowType
  /** Optional callback for when a cell's content changes. */
  onChangeCell?: (props: TableCellChangeEventProps) => void
  /** Information about the cell being edited, if any. */
  editCell: TableCell | null
  /** True if the row is currently selected. */
  isSelectedRow: boolean
  /** Information about the cell that is currently loading, if any. */
  cellsLoading: { [key: string]: TableCellLoading }
  /** Dispatch function to emit actions to the table's reducer. */
  dispatch: React.Dispatch<Action<RowType>>
  /** Optional custom renderer for cells. */
  renderCell?: (props: TableCellRenderProps<RowType>) => React.JSX.Element
  /** Optional function to compute the class name for the row based on its data. */
  renderRowClassName?: ({ row }: { row: Row }) => string
}

// Adaption of https://github.com/facebook/react/blob/main/packages/shared/shallowEqual.js
// Difference: compares the `row` object properly.
function rowPropsEqual(objA: any, objB: any): boolean {
  if (Object.is(objA, objB)) {
    return true
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) {
    return false
  }

  for (let i = 0; i < keysA.length; i++) {
    const currentKey = keysA[i]

    if (['row', 'columnDef'].includes(currentKey)) {
      if (
        JSON.stringify(objA[currentKey]) !== JSON.stringify(objB[currentKey])
      ) {
        return false
      }

      continue
    }

    if (
      !Object.hasOwn(objB, currentKey) ||
      !Object.is(objA[currentKey], objB[currentKey])
    ) {
      if (DEBUG_ROW_RENDER) {
        // eslint-disable-next-line no-console
        console.log('rowPropsEqual different: ', currentKey)
      }
      return false
    }
  }

  return true
}

/**
 * `TableRow` is a generic React component for rendering a single row within a table.
 * It supports custom cell rendering, edit and loading states for cells, and row selection.
 */
const TableRow = <RowType extends BaseRow>(props: TableRowProps<RowType>) => {
  const {
    columnDef,
    row,
    onChangeCell,
    editCell,
    isSelectedRow,
    cellsLoading,
    dispatch,
    renderCell,
    renderRowClassName,
  } = props

  const rowClassName = renderRowClassName ? renderRowClassName({ row }) : null
  const selClassName = isSelectedRow ? 'selected' : ''
  const className = `${rowClassName} ${selClassName}`

  if (DEBUG_ROW_RENDER) {
    // eslint-disable-next-line no-console
    console.log('Rendering table row ID ' + row.id)
  }

  return (
    <tr data-id={row.id} className={className}>
      {columnDef.map((col: ColumnDef<RowType>) => {
        const renderer = col.renderer || renderCell || defaultCellRenderer
        const isEditing = editCell
          ? editCell.rowId === row.id && editCell.colId === col.id
          : false
        const value = col.valueColumn ? get(row, col.valueColumn) : row[col.id]

        const isLoading = cellsLoading && Object.hasOwn(cellsLoading, col.id)
        const optimisticValue =
          cellsLoading && Object.hasOwn(cellsLoading, col.id)
            ? cellsLoading[col.id].value
            : null

        const renderProps = {
          isLoading,
          isEditing,
          value: optimisticValue || value,
          col,
          row,
          onChangeCell,
          dispatch,
          isSelectedRow,
        }

        return <Fragment key={col.id}>{renderer(renderProps)}</Fragment>
      })}
    </tr>
  )
}

export const MemoTableRow = memo(TableRow, rowPropsEqual)

export default TableRow
