import type { Action } from 'containers/AddOrder/useCart/types'
import { formatPrice } from 'utils/price'
import { getFreeShippingThresholdCents } from 'utils/settings'
import type { ShoppingCartProps } from '.'
import CartTotalItem from './CartTotalItem'

function renderTotalRow(
  cartState: ShoppingCartProps,
  total: number,
  valueCalculated?: string,
) {
  const { dispatch, refund } = cartState

  let value = `${formatPrice(total)}`
  let oldvalue = null
  if (refund) {
    value = `${formatPrice(total - refund)}`
    oldvalue = `${formatPrice(total)}`
  }

  return (
    <CartTotalItem
      {...{ dispatch }}
      bold
      label="Total"
      name="total"
      value={value}
      valueCalculated={valueCalculated}
      oldvalue={oldvalue}
      gray={!!valueCalculated}
    />
  )
}

function formatDiffPrice(value: number): string {
  const prefix = value < 0 ? '-' : '+'
  return `${prefix} $${formatPrice(value < 0 ? value * -1 : value)}`
}

type TotalRowProps = {
  dispatch: React.Dispatch<Action>
  readOnly: boolean
  cartState: ShoppingCartProps
}

const TotalRow = (props: TotalRowProps) => {
  const { dispatch, total, refund, originaltotal, originaltotal_calculated } =
    props.cartState

  if (
    originaltotal != null &&
    (total != originaltotal || total != originaltotal_calculated)
  ) {
    return (
      <>
        {renderTotalRow(
          props.cartState,
          originaltotal,
          formatDiffPrice(total - originaltotal),
        )}
        <CartTotalItem
          {...{ dispatch }}
          label="New Total"
          name="total"
          value={`${formatPrice(total - refund)}`}
          bold
        />
        {originaltotal_calculated != null &&
        originaltotal != originaltotal_calculated ? (
          <div
            className="cartfooteritem"
            style={{ color: 'red', fontWeight: 'bold' }}
          >
            Warning: Calculated total (${formatPrice(originaltotal_calculated)})
            does not match stored total (${formatPrice(originaltotal)}). Please
            report this as a bug.
          </div>
        ) : null}
      </>
    )
  }

  return renderTotalRow(props.cartState, total)
}

type CartTotalItemsProps = {
  dispatch: React.Dispatch<Action>
  readOnly: boolean
  cartState: ShoppingCartProps
}

const CartTotalItems = (props: CartTotalItemsProps) => {
  const { dispatch, readOnly, cartState } = props

  const freeShippingThresholdCents = getFreeShippingThresholdCents()

  return (
    <div style={{ paddingLeft: '30%' }}>
      <CartTotalItem
        {...{ dispatch, readOnly }}
        name="subtotal"
        value={`${formatPrice(cartState.subtotal)}`}
        label="Subtotal"
      />
      <CartTotalItem
        {...{ dispatch, readOnly }}
        editable={!readOnly}
        name="shipping"
        value={cartState.shipping}
        toggleName="hasShipping"
        toggleState={cartState.hasShipping}
        label={`Shipping (Free < $${formatPrice(freeShippingThresholdCents)})`}
        type="text"
      />
      <CartTotalItem
        {...{ dispatch, readOnly }}
        name="tax"
        value={`${formatPrice(cartState.tax)}`}
        toggleName="hasTax"
        toggleState={cartState.hasTax}
        label="MD Tax"
      />
      <CartTotalItem
        {...{ dispatch, readOnly }}
        editable={!readOnly}
        name="oversizeFee"
        value={cartState.oversizeFee}
        toggleName="hasOversizeFee"
        toggleState={cartState.hasOversizeFee}
        label="Oversize Fee"
      />
      <CartTotalItem
        {...{ dispatch, readOnly }}
        editable={!readOnly}
        name="warranty"
        value={cartState.warranty}
        valueCalculated={
          cartState.warranty.indexOf('%') > 0 && cartState.hasWarranty
            ? `$${formatPrice(cartState.warrantyCalculated)}`
            : `${
                cartState.warrantyPercentCalculated
                  ? formatPrice(cartState.warrantyPercentCalculated * 100) + '%'
                  : ''
              }`
        }
        toggleName="hasWarranty"
        toggleState={cartState.hasWarranty}
        label="1 Y Warranty"
      />
      <CartTotalItem
        {...{ dispatch, readOnly }}
        editable={!readOnly}
        name="discount"
        value={cartState.discount}
        valueCalculated={
          cartState.discount.indexOf('%') > 0 && cartState.hasDiscount
            ? `$${formatPrice(cartState.discountCalculated)}`
            : `${
                cartState.discountPercentCalculated
                  ? formatPrice(cartState.discountPercentCalculated * 100) + '%'
                  : ''
              }`
        }
        toggleName="hasDiscount"
        toggleState={cartState.hasDiscount}
        label="Discount"
      />
      {cartState.refunds.length > 0
        ? cartState.refunds.map((refund: any) => {
            return (
              <CartTotalItem
                key={refund.id}
                {...{ dispatch, readOnly }}
                label={`Refund (${refund.date})`}
                value={`${formatPrice(refund.amount)}`}
              />
            )
          })
        : null}
      {cartState.refund > 0 ? (
        <CartTotalItem
          {...{ dispatch, readOnly }}
          label="Total Refunded"
          value={`${formatPrice(cartState.refund)}`}
        />
      ) : null}
      <TotalRow {...props} />
      {!readOnly ? (
        <CartTotalItem
          {...{ dispatch, readOnly }}
          editable={!readOnly}
          name="shipDate"
          type="date"
          value={cartState.shipDate}
          toggleName="hasShipDate"
          toggleState={cartState.hasShipDate}
          label="Ship Later"
        />
      ) : null}
      {cartState.coupons.map((coupon: any) => {
        return (
          <div
            key={coupon.id}
            className="d-flex pb-2 pt-2 position-relative"
            style={{
              borderBottom: '1px dotted #c8ced3',
              alignItems: 'center',
              minHeight: 52,
            }}
          >
            {coupon.desc}
          </div>
        )
      })}
    </div>
  )
}

export default CartTotalItems
