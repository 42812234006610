import type { StoreCredit } from '@ttc/api/storeCredits'
import type { ApiCall } from 'app/hooks/useApi'
import { Portal } from 'components'
import Panels from './Panels'

const Sidebar = ({
  readOnly,
  selectedRows,
  onToggle,
  getStoreCredit,
  onUpdate,
}: {
  readOnly?: boolean
  selectedRows?: string[]
  onToggle?: (e: any) => void
  getStoreCredit?: ApiCall<StoreCredit>
  onUpdate: () => void
}) => {
  if (selectedRows.length === 1) {
    return (
      <Portal id="sidebar-content">
        <Panels
          {...{
            readOnly,
            id: selectedRows[0],
            getStoreCredit,
            onToggle,
            onUpdate,
          }}
        />
      </Portal>
    )
  }

  return (
    <Portal id="sidebar-content">
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list" />
          </a>
        </li>
      </ul>

      <div className="tab-content">
        {selectedRows.length ? (
          <div className="p-3">
            {selectedRows.length} store credits selected.
          </div>
        ) : (
          <div className="p-3">No store credit selected.</div>
        )}
      </div>
    </Portal>
  )
}

export default Sidebar
