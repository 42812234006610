import type {
  GetAllApiParams,
  GetAllOrdersSummaryApiParams,
  GetAllOrdersSummaryApiResult,
  GetOrderApiParams,
  GetOrderApiResult,
  GetOrdersSummaryApiParams,
  GetOrdersSummaryApiResult,
} from '@ttc/api/orders'
import { usePreviousJson } from '@ttc/hooks/usePreviousJson'
import type { TableCellRenderProps } from 'app/components/Table/types'
import { FilterBar, PageNavHead, PageNavTail } from 'components'
import {
  Table,
  defaultCellRenderer,
  setRow,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import EditReturnPanel from 'containers/ManageReturns/EditReturnPanel'
import ReturnSidebar from 'containers/ManageReturns/Sidebar'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import ManageTagsPanel from 'containers/common/ManageTagsPanel'
import {
  MultiOrderSummarySidebar,
  NoOrderSummarySidebar,
  SingleOrderSummarySidebar,
} from 'containers/common/OrderSummarySidebar'
import TagActionButton from 'containers/common/TagActionButton'
import {
  useApi,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdaterWithTableSync,
  usePageTitle,
  usePanelControl,
  useSidebar,
  useStateful,
} from 'hooks'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Col, Container, Row } from 'reactstrap'
import { useDebouncedCallback } from 'use-debounce'
import { getStorage } from 'utils/storage'
import {
  BatchActionButton,
  DropshipActionButton,
  ExportActionButton,
  PrintActionButton,
  RestoreActionButton,
  ScheduleActionButton,
  SplitActionButton,
} from './Actions'
import Filters, { type ManageOrdersFiltersType } from './Filters'
import NotesCell from './NotesCell'
import columnDef, { type OrderRow } from './columnDef'
import { useNotesCell } from './useNotesCell'

const statusLabel = {
  all: 'All Orders',
  scheduled: 'Scheduled',
  processing: 'Processing',
  completed: 'Completed',
}

const visibleFilters = [
  'q',
  'selected',
  'products',
  'availability',
  'av',
  'scheduledFor',
  'orderDate',
  'tags',
  'status',
  'batch',
  'zones',
]

type ManageOrdersProps = {
  filterStatus?: string
  canModifyBatches: boolean
  canBlockUnblockOrders: boolean
  canModifyTags: boolean
  canModifySchedule: boolean
  canModifyNotes: boolean
  canModifyReturns: boolean
  canSplit: boolean
  canDropship: boolean
}

const ManageOrdersInner = (props: ManageOrdersProps) => {
  const {
    canModifyBatches,
    canBlockUnblockOrders,
    canModifyTags,
    canModifySchedule,
    canModifyNotes,
    canModifyReturns,
    canSplit,
    canDropship,
    filterStatus,
  } = props

  const [setItem, getItem] = useMemo(
    () => getStorage(`manageOrders_${filterStatus}`),
    [filterStatus],
  )
  const pageTitle = useMemo(
    () => `Manage Orders - ${statusLabel[filterStatus]}`,
    [filterStatus],
  )
  usePageTitle(pageTitle)

  const manageTagsPanel = usePanelControl()
  const manageColumnsPanel = usePanelControl()

  const [state, dispatch] = useTable<OrderRow>({
    cacheKey: `manageOrders_${filterStatus}`,
    getItem,
    setItem,
  })
  const { rows, selectedRows, isLoading, resultTotal, sortColumn, sortAsc } =
    state

  //region Search and filters
  const filters = useFiltersWithUrlUpdaterWithTableSync(
    'manageOrders.filters',
    visibleFilters,
    state,
    dispatch,
  ) as ManageOrdersFiltersType

  const query = filters.searchQueryString

  const getAllOrdersRequestParams = useMemo(() => {
    const filterStatusValues = filters?.status ? filters.status.values : []

    // We don't need `fitlerSelected` in the request.
    const { filterSelected: _, ...requestProps } = filters.requestProps

    return {
      query: '',
      ...requestProps,
      filterStatus:
        filterStatus === 'all' && filterStatusValues.length
          ? filterStatusValues
          : [
              filterStatus,
              ...filterStatusValues.filter((s) => s.indexOf('internal_') === 0),
            ],
      includeIsFulfillable:
        state.visibleColumns.length === 0 ||
        state.visibleColumns.includes('isFulfillable') ||
        state.visibleColumns.includes('isDropshipable')
          ? 1
          : 0,
    }
  }, [filters, filterStatus, state.visibleColumns])

  const [triggerSearch] = useTableApi<OrderRow, GetAllApiParams>(
    'orders_getAll',
    state,
    dispatch,
    getAllOrdersRequestParams,
    { autoReloadInterval: 2 * 60 },
  )
  useEffectIfObjectChanges(triggerSearch, getAllOrdersRequestParams)

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters],
  )

  //endregion

  //region Barcode scan
  const handleScan = useCallback(
    (e: CustomEvent) => {
      const input = e.detail
      const isOrderNumber = /^[0-9-]+$/.test(input)

      if (isOrderNumber) {
        filters.q.setValues([input])
      }
    },
    [filters],
  )

  useEffect(() => {
    document.addEventListener('barcodeScan', handleScan)

    return () => {
      document.removeEventListener('barcodeScan', handleScan)
    }
  })
  //endregion

  const handleClickRow = useCallback(
    (_rowId: string, e: React.MouseEvent<HTMLTableElement>) => {
      const target = e.target as HTMLTableElement

      return target?.className && target.className.indexOf('fa-comment') !== -1
    },
    [],
  )

  const renderRowClassName = useCallback(() => 'cursor-pointer', [])

  const columns = useTableColumns('manageOrders', state, dispatch, columnDef)

  //region Tags

  const getTags = useApi(
    () => ({ action: 'orderTags_getAll' }),
    () => [],
    () => ({
      autoPerform: true,
      normalize: (input) => input.rows,
    }),
  )

  const handleCloseTagsPanel = useCallback(() => {
    manageTagsPanel.close()
    getTags.performRequest()
  }, [manageTagsPanel, getTags])
  const summaryRows =
    selectedRows.length > 0 ? selectedRows : rows.map((r) => r.id)
  //endregion

  const sidebar = useSidebar({
    openForSingleRow: true,
    openForMultiRows: true,
    selectedRows,
  })

  //region Orders Summary

  // compute summary for sidebar, using two methods:
  // * if there are selected rows, then ask for the summary of the selected order ids using getOrdersSummary
  // * if no rows are selected, and the sidebar is open, and at least one filter is selected, use the getAllOrdersSummary call
  const [showSummary, setShowSummary] = useState(false)
  const showGetAllOrdersSummary =
    sidebar.isOpen && selectedRows.length === 0 && !filters.isEmpty
  const showGetOrdersSummary = sidebar.isOpen && selectedRows.length > 0

  const getOrdersSummary = useApi<
    GetOrdersSummaryApiResult,
    GetOrdersSummaryApiParams
  >(
    useMemo(() => {
      return {
        action: 'editOrder_getOrdersSummary',
        order_ids: summaryRows,
      }
    }, [summaryRows]),
    null,
  )

  const getAllOrdersSummary = useApi<
    GetAllOrdersSummaryApiResult,
    GetAllOrdersSummaryApiParams
  >(
    useMemo(() => {
      return {
        action: 'editOrder_getAllOrdersSummary',
        ...getAllOrdersRequestParams,
      }
    }, [getAllOrdersRequestParams]),
    null,
  )

  // we pass a single summary ApiCall to the MultiOrderEditor
  const ordersSummaryApiCall = showGetAllOrdersSummary
    ? getAllOrdersSummary
    : getOrdersSummary

  const triggerGetAllOrdersSummary = useDebouncedCallback(
    useCallback(() => {
      getAllOrdersSummary.performRequest()
    }, [getAllOrdersSummary]),
    1000,
    {
      leading: true,
      trailing: true,
    },
  )

  const triggerGetOrdersSummary = useDebouncedCallback(
    useCallback(() => {
      getOrdersSummary.performRequest()
    }, [getOrdersSummary]),
    1000,
    {
      leading: true,
      trailing: true,
    },
  )

  // We actually want to trigger a refresh only when one of the following changes:
  // * filterProps
  // * selectedRows
  // * visibility of getAllOrdersSummary / getOrdersSummary
  //
  // to avoid doing a deep compare on every render of the table itself,
  // we use the `usePreviousJSON` + useEffect(prevJson !== JSON.stringify(current)) pattern
  const prevSummaryDeps = usePreviousJson([
    showGetOrdersSummary,
    showGetAllOrdersSummary,
    filters.requestProps,
    selectedRows,
    showSummary,
  ])

  useEffect(() => {
    const newSummaryDeps = JSON.stringify([
      showGetOrdersSummary,
      showGetAllOrdersSummary,
      filters.requestProps,
      selectedRows,
      showSummary,
    ])
    if (prevSummaryDeps !== newSummaryDeps) {
      if (showGetOrdersSummary) {
        triggerGetOrdersSummary()
      } else if (showGetAllOrdersSummary && showSummary) {
        triggerGetAllOrdersSummary()
      }
    }
  }, [
    prevSummaryDeps,
    showGetOrdersSummary,
    showGetAllOrdersSummary,
    filters.requestProps,
    selectedRows,
    triggerGetOrdersSummary,
    triggerGetAllOrdersSummary,
    showSummary,
  ])

  //endregion

  const selectedOrderId = selectedRows.length === 1 ? selectedRows[0] : null
  const getOrder = useApi<GetOrderApiResult, GetOrderApiParams>(
    useMemo(
      () => ({
        action: 'editOrder_getOrder',
        order_id: selectedOrderId,
      }),
      [selectedOrderId],
    ),
    null,
    {
      autoPerform: sidebar.isOpen && selectedOrderId != null,
      cache: true,
    },
  )

  const handleOrderModified = useCallback(() => {
    triggerSearch()

    if (getOrder.hasResult) {
      getOrder.performRequest()
    }

    if (global.updateOrderStats) {
      global.updateOrderStats()
    }
  }, [getOrder, triggerSearch])

  const updateRow = useCallback(
    (rowId: string, row: OrderRow) => {
      dispatch(setRow(rowId, row))

      triggerSearch()
    },
    [dispatch, triggerSearch],
  )

  //region Note Cells
  const notesCell = useNotesCell()

  const renderNotesCell = useCallback(
    (props: TableCellRenderProps<OrderRow>) => {
      return (
        <td>
          <NotesCell
            {...{
              updateRow,
              dropDownState: notesCell.dropDownState,
              readOnly: !canModifyNotes,
              hasNotesToBuyer: props.row.hasNotesToBuyer,
              hasNotesFromBuyer: props.row.hasNotesFromBuyer,
              hasNotesInternal: props.row.hasNotesInternal,
              id: props.row.id,
            }}
          />
        </td>
      )
    },
    [notesCell, updateRow, canModifyNotes],
  )

  const renderCell = useCallback(
    (props: TableCellRenderProps<OrderRow>) => {
      if (props.col.id === 'notes') {
        return renderNotesCell(props)
      }

      return defaultCellRenderer(props)
    },
    [renderNotesCell],
  )
  //endregion

  //region Returns
  const editReturnPanel = usePanelControl()

  const selectedReturn = useStateful(null)

  const getReturn = useApi(
    useMemo(
      () => ({
        action: 'returns_get',
        id: selectedReturn.value,
        withNotes: true,
        withItems: true,
        withImages: true,
        withReplacementOrders: true,
      }),
      [selectedReturn.value],
    ),
    null,
    { autoPerform: sidebar.isOpen && selectedReturn.value != null },
  )

  useEffect(() => {
    const performance = (getAllOrdersSummary.result as any)?.performance
    if (performance != null) {
      // eslint-disable-next-line no-console
      console.log('performance', performance)
    }
  }, [getAllOrdersSummary.result])

  const handleClickReturnId = useCallback(
    (id: string) => {
      selectedReturn.set(id)
    },
    [selectedReturn],
  )

  const handleClickCreateReturn = useCallback(() => {
    selectedReturn.set(null)
    editReturnPanel.open()
  }, [selectedReturn, editReturnPanel])

  const handleReturnSidebarBack = useCallback(() => {
    selectedReturn.set(null)
  }, [selectedReturn])

  /* Dismiss selected return if sidebar closes. */
  useEffect(() => {
    if (!sidebar.isOpen && selectedReturn.value) {
      selectedReturn.set(null)
    }
  }, [sidebar.isOpen, selectedReturn])

  const getReturnStatuses = useApi(
    () => ({ action: 'returns_getStatuses' }),
    null,
    { autoPerform: canModifyReturns },
  )

  const handleCloseReturnPanel = useCallback(
    (id: string, isNew: boolean) => {
      editReturnPanel.close()

      if (selectedReturn.value) {
        getReturn.performRequest()
      }

      if (isNew) {
        getOrder.performRequest()
        selectedReturn.set(id)
      }
    },
    [getReturn, selectedReturn, editReturnPanel, getOrder],
  )
  //endregion

  return (
    <Fragment key={filterStatus}>
      <PageNavHead
        {...{
          isLoading,
          pageTitle,
          sidebar,
          onClickReload: triggerSearch,
        }}
      />
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
        }}
      >
        <PrintActionButton
          {...{
            selectedRows,
            rows,
          }}
        />
        {canModifyBatches || canBlockUnblockOrders ? (
          <BatchActionButton
            {...{
              selectedRows,
              rows,
              triggerSearch: triggerSearch,
              query,
              filters,
              filterStatus,
              resultTotal,
              onOrderModified: handleOrderModified,
              canModifyBatches,
              canBlockUnblockOrders,
            }}
          />
        ) : null}
        {canModifyTags ? (
          <TagActionButton
            toggleTagApiAction="orders_toggleTags"
            {...{
              selectedRows,
              rows,
              tags: getTags.result,
              onClickManage: manageTagsPanel.open,
              onModified: handleOrderModified,
            }}
          />
        ) : null}
        {canModifySchedule ? (
          ['all', 'processing', 'scheduled'].includes(filterStatus) ? (
            <ScheduleActionButton
              {...{
                onOrderModified: handleOrderModified,
                selectedRows,
              }}
            />
          ) : null
        ) : null}
        {canModifySchedule ? (
          ['all', 'scheduled'].includes(filterStatus) ? (
            <RestoreActionButton
              {...{
                onOrderModified: handleOrderModified,
                selectedRows,
              }}
            />
          ) : null
        ) : null}
        {canSplit ? (
          ['all', 'processing', 'scheduled'].includes(filterStatus) ? (
            <SplitActionButton
              {...{
                onOrderModified: handleOrderModified,
                selectedRows,
              }}
            />
          ) : null
        ) : null}
        {canDropship ? (
          <DropshipActionButton
            {...{
              onOrderModified: handleOrderModified,
              selectedRows,
            }}
          />
        ) : null}
        <ExportActionButton
          {...{
            requestProps: filters.requestProps,
            query,
            sortColumn,
            sortAsc,
            columns,
          }}
        />
      </PageNavTail>
      <FilterBar {...{ filters, manageColumnsPanel, columns }}>
        <Filters
          {...{
            filterStatus,
            filters,
            tags: getTags.result,
            visibleColumns: state.visibleColumns,
          }}
        />
      </FilterBar>
      <Container fluid className="mt-4">
        <div className="manage-orders animated fadeIn">
          <ManageTagsPanel
            apiPrefix="orderTags"
            isOpen={manageTagsPanel.isOpen}
            onClose={handleCloseTagsPanel}
          />
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <EditReturnPanel
            editId={selectedReturn.value}
            isOpen={editReturnPanel.isOpen}
            onClose={handleCloseReturnPanel}
            defaultValues={{
              order_number: getOrder.result
                ? getOrder.result.orderNumber
                : null,
            }}
            {...{ getStatuses: getReturnStatuses }}
          />
          <Row>
            <Col>
              <Table
                entityName="orders"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                  renderCell,
                  renderRowClassName,
                  onClickRow: handleClickRow,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
      {selectedReturn.value ? (
        <ReturnSidebar
          {...{
            getReturn,
            onToggle: sidebar.userToggle,
            onBack: handleReturnSidebarBack,
            selectedRows: [selectedReturn.value],
            onClickEdit: editReturnPanel.open,
          }}
        />
      ) : getOrder.params.order_id != null || getOrder.params.id != null ? (
        <SingleOrderSummarySidebar
          showDropshipAvailability
          {...{
            getOrder,
            onToggle: sidebar.userToggle,
            onClickReturnId: handleClickReturnId,
            onClickCreateReturn: canModifyReturns
              ? handleClickCreateReturn
              : null,
          }}
        />
      ) : showGetOrdersSummary || showGetAllOrdersSummary ? (
        <MultiOrderSummarySidebar
          onToggle={sidebar.userToggle}
          showSummary={showSummary}
          onToggleShowSummary={() => {
            getAllOrdersSummary.reset()
            setShowSummary(!showSummary)
          }}
          getOrdersSummary={ordersSummaryApiCall}
        />
      ) : (
        <NoOrderSummarySidebar onToggle={sidebar.userToggle} />
      )}
    </Fragment>
  )
}

const ManageOrders = (props: ManageOrdersProps) => {
  const { filterStatus } = useParams()

  return (
    <Fragment key={filterStatus}>
      <ManageOrdersInner filterStatus={filterStatus} {...props} />
    </Fragment>
  )
}

export default ManageOrders
