import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import { apiRequest } from 'api'
import { Loading, LoadingImage } from 'components'
import { useCallback, useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { askQuestion, uiApiRequest } from 'utils'
import InvoiceDropZone from './InvoiceDropZone'

const InvoicesList = (props: { id: number }) => {
  const { id } = props
  const [invoices, setInvoices] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const loadData = useCallback(async () => {
    try {
      setLoading(true)

      const ret = await apiRequest({
        action: 'purchaseOrders_getInvoices',
        id,
      })
      setInvoices(ret)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(e.message)
    }
  }, [id])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleRemoveInvoice = useCallback(
    async (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute('data-id')
      const name = e.currentTarget.getAttribute('data-name')

      const isYes = await askQuestion(
        `Are you sure you want to remove invoice "${name}"?`,
      )

      if (isYes) {
        await uiApiRequest({
          action: 'purchaseOrders_trashInvoice',
          id,
        })

        loadData()
      }
    },
    [loadData],
  )

  if (error != null) {
    return <div className="text-danger">{error}</div>
  }

  if (!invoices.length && isLoading) {
    return <LoadingImage />
  }

  if (!invoices.length) {
    return null
  }

  return (
    <ul className="p-0" style={{ listStyle: 'none' }}>
      {invoices.map((invoice) => {
        return (
          <li key={invoice.id} className="invoice-file">
            <a
              target="noopener"
              href={`${global.appConfig.wpBaseUrl}${invoice.url_path}`}
            >
              <i className="fa fa-file mr-2" /> {invoice.name}
            </a>
            <Button
              data-id={invoice.id}
              data-name={invoice.name}
              title="Delete invoice file"
              style={{ position: 'absolute', right: 10 }}
              color="link"
              className="p-0 remove"
              onClick={handleRemoveInvoice}
            >
              <i className="fa fa-times text-danger" />
            </Button>
          </li>
        )
      })}
    </ul>
  )
}

type InvoicesCardProps = {
  isLoading: boolean
  onClickEdit: () => void
  purchaseOrder: PurchaseOrder
  readOnly: boolean
}

const InvoicesCard = (props: InvoicesCardProps) => {
  const { isLoading, onClickEdit, purchaseOrder, readOnly } = props
  const [reloadKey, setReloadKey] = useState(0)

  const handleClickEdit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      onClickEdit()
    },
    [onClickEdit],
  )

  const handleUploadDone = useCallback(() => {
    setReloadKey(reloadKey + 1)
  }, [reloadKey])

  return (
    <Card className="h-100">
      <CardHeader>
        <span className="align-bottom">Reference No.</span>
        <div className="card-header-actions">
          {onClickEdit ? (
            <a
              href="#"
              onClick={handleClickEdit}
              className="card-header-action btn btn-setting"
            >
              <i className="fa fa-pencil" />
            </a>
          ) : null}
        </div>
      </CardHeader>
      <CardBody className="d-flex flex-column">
        {!purchaseOrder && isLoading ? (
          <Loading />
        ) : purchaseOrder ? (
          <>
            <Row style={{ flex: 1 }}>
              <Col className="text-nowrap">Reference Number:</Col>
              <Col className="text-nowrap text-right">
                {purchaseOrder.reference_number ? (
                  purchaseOrder.reference_number
                ) : (
                  <>&mdash;</>
                )}
              </Col>
            </Row>
            <Row style={{ flex: 1 }}>
              <Col>
                <InvoicesList key={reloadKey} id={purchaseOrder.id} />
              </Col>
            </Row>
            {!readOnly ? (
              <Row style={{ flex: 1 }}>
                <Col>
                  <InvoiceDropZone
                    id={purchaseOrder.id}
                    onUploadDone={handleUploadDone}
                  />
                </Col>
              </Row>
            ) : null}
          </>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default InvoicesCard
