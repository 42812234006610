import type { Subscription } from '@ttc/api/subscriptions'
import AuthContext from 'AuthContext'
import { MANAGECUSTOMERS_READ } from 'caps'
import { ClipboardButton } from 'components'
import { useContext } from 'react'
import { Link } from 'react-router'
import { Col, Row } from 'reactstrap'

type LinkToCustomerProps = {
  email?: string
  customerId: string
  customerName: string
  subscription?: Subscription
}

const LinkToCustomer = ({
  email,
  customerId,
  customerName,
  subscription,
}: LinkToCustomerProps) => {
  const { hasCap } = useContext(AuthContext)
  const canOpenCustomer = hasCap(MANAGECUSTOMERS_READ)

  const subscriptionInfo = subscription ? (
    <>
      <span title={subscription.plan_description}>
        {subscription.plan_admin_icon}
      </span>{' '}
    </>
  ) : null

  return (
    <>
      {!canOpenCustomer ? (
        <Row>
          <Col>
            {subscriptionInfo}
            {customerName} (ID: {customerId})
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Link to={`/customers/${customerId}`}>
              {subscriptionInfo}
              {customerName} (ID: {customerId})
            </Link>
          </Col>
        </Row>
      )}
      {email ? (
        <Row>
          <Col>
            <ClipboardButton value={email} text={email} />
          </Col>
        </Row>
      ) : null}
    </>
  )
}

export default LinkToCustomer
