import type { ApiCall } from 'hooks'
import { useCallback } from 'react'
import { useMatch, useNavigate } from 'react-router'
import { Col, Row } from 'reactstrap'
import TruckItemsTable from './TruckItemsTable'
import TrucksTable from './TrucksTable'

type DeliveriesTableProps = {
  id: number
  onChange: () => void
  referenceNumber: string
  reloadKey: number
  canAddTrucks: boolean
  numProducts: number
  handleClickProductName: (productName: string) => void
  apiLoadZones: ApiCall
  readOnly: boolean
}

const DeliveriesTable = (props: DeliveriesTableProps) => {
  const navigate = useNavigate()

  const {
    id,
    onChange,
    referenceNumber,
    reloadKey,
    canAddTrucks,
    numProducts,
    handleClickProductName,
    apiLoadZones,
    readOnly,
  } = props

  const selectedRow = useMatch('/purchase-orders/edit/:truck/deliveries/:id')
    ?.params.id

  const handleSelectRow = useCallback(
    (rowId: string) => {
      navigate(`/purchase-orders/edit/${id}/deliveries/${rowId}`)
    },
    [navigate, id],
  )

  return (
    <Row noGutters>
      <Col lg={6} xl={4} style={{ borderRight: '1px solid #c8ced3' }}>
        <TrucksTable
          {...{ selectedRow, onChange, referenceNumber, reloadKey, readOnly }}
          onSelectRow={handleSelectRow}
          canAdd={canAddTrucks}
          id={props.id}
        />
      </Col>
      <Col lg={6} xl={8} style={{ minHeight: 140 + numProducts * 55 }}>
        {!selectedRow ? (
          <div className="m-3">No truck selected.</div>
        ) : (
          <TruckItemsTable
            {...{ apiLoadZones, handleClickProductName, readOnly }}
            onChangeTruck={onChange}
            poId={props.id}
            id={Number(selectedRow)}
            key={selectedRow}
            {...{ reloadKey }}
          />
        )}
      </Col>
    </Row>
  )
}

export default DeliveriesTable
