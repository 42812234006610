import { TagsCell } from 'components'
import PurchaseOrderStatusBadge from 'components/Badges/PurchaseOrderStatusBadge'
import { CellEditable } from 'components/Table'
import type { ColumnDef } from 'components/Table/types'
import { formatDateReadable } from 'utils/date'
import { poStatusOptions } from './options'

type CustomColumnDef = ColumnDef & {
  showInDraftsView?: boolean
}

export const getColumnDef = (isDraftsView: boolean, readOnly: boolean) => {
  const columnDef: CustomColumnDef[] = [
    {
      label: '',
      id: 'checkbox',
      sortColumn: false,
      canExport: false,
    },
    {
      label: 'ID',
      id: 'id',
      sortColumn: true,
    },
    {
      label: 'Supplier',
      id: 'name',
      sortColumn: true,
    },
    {
      label: 'Tags',
      id: 'tags',
      sortColumn: false,
      renderer: ({ value }) => (value ? <TagsCell {...{ value }} /> : <td />),
      canExport: false,
    },
    {
      label: 'Notes',
      id: 'note',
      sortColumn: false,
    },
    {
      label: 'Status',
      id: 'order_status',
      sortColumn: true,
      isSelect: true,
      isEditable: !readOnly,
      selectOptions: poStatusOptions,
      renderValue: (value) => <PurchaseOrderStatusBadge value={value} />,
      renderer: (props) => <CellEditable {...props} />,
    },
    {
      label: 'Delivery Status',
      id: 'status',
      sortColumn: true,
      renderer: ({ value }) => {
        return (
          <td>
            <PurchaseOrderStatusBadge value={value} />
          </td>
        )
      },
      showInDraftsView: false,
    },
    {
      label: 'Total Items',
      id: 'num_items',
      valueColumn: 'num_items_format',
      sortColumn: true,
    },
    {
      label: 'Order Date',
      id: 'order_date',
      sortColumn: true,
      renderer: ({ value }) => <td>{formatDateReadable(value)}</td>,
    },
    {
      label: 'Invoice No',
      id: 'invoice_number',
      sortColumn: true,
    },
    {
      label: '% Listed',
      id: 'percent_listed',
      valueColumn: 'percent_listed_format',
      sortColumn: false,
      showInDraftsView: false,
    },
    {
      label: '% Received',
      id: 'percent_arrived',
      valueColumn: 'percent_arrived_format',
      sortColumn: false,
      showInDraftsView: false,
    },
  ]

  if (isDraftsView) {
    return columnDef.filter((column) => column.showInDraftsView !== false)
  }

  return columnDef
}
