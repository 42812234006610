import type { Zone } from '@ttc/api/zones'
import { DropDownItemCheckbox } from 'components'
import type { ApiCall } from 'hooks'
import { useDropDownToggler } from 'hooks'
import keyBy from 'lodash/keyBy'
import { useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import type { ManageInventoryFiltersType } from '.'

type ZoneFilterButtonProps = {
  filters: ManageInventoryFiltersType
  apiLoadZones: ApiCall
}

const ZoneFilterButton = (props: ZoneFilterButtonProps) => {
  const { filters, apiLoadZones } = props

  const zonesFilter = filters.zones as FilterType

  const toggler = useDropDownToggler()

  const zones = useMemo(
    () => (apiLoadZones.result ? apiLoadZones.result.rows : []),
    [apiLoadZones],
  )

  const zonesById = useMemo(() => keyBy(zones, 'id'), [zones])

  if (apiLoadZones.isLoading) {
    return null
  }

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle
        nav
        caret
        className={zonesFilter.values.length ? 'hasValue' : ''}
      >
        {zonesFilter.values.length === 0 ? (
          <span>Zone</span>
        ) : zonesFilter.values.length === 1 ? (
          <span>{zonesById[zonesFilter.values[0]]?.column_label}</span>
        ) : (
          <span>
            {zonesFilter.values
              .map((id: string) => zonesById[id]?.column_label)
              .join(',')}
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu className="pre-scrollable">
        <DropdownItem onClick={zonesFilter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {zones.map((zone: Zone) => {
          return (
            <DropDownItemCheckbox
              id={String(zone.id)}
              key={zone.id}
              onClick={zonesFilter.toggle}
              isChecked={zonesFilter.has(String(zone.id))}
            >
              {zone.column_label}
            </DropDownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default ZoneFilterButton
