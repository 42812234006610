import AuthContext from 'AuthContext'
import type { GetOrderApiCall } from 'api/types'
import {
  EDITORDERS_READWRITE,
  MANAGEORDERS_READWRITE,
  MANAGESTORECREDIT_READ,
  MANAGESTORECREDIT_READWRITE,
} from 'caps'
import Portal from 'components/Portal'
import { type ApiCall, usePanelControl } from 'hooks'
import { useCallback, useContext } from 'react'
import type { PropsType } from 'utils/types'
import IssueStoreCreditPanel from '../IssueStoreCreditPanel'
import MultiOrderEditor from './MultiOrderEditor'
import OrderEditor from './OrderEditor'

type OrderSidebarProps = {
  getOrder: GetOrderApiCall
  onToggle: (e: React.MouseEvent<HTMLElement>) => void
  onBack?: () => void
  renderFirstOrderCard?: (a: ApiCall) => React.JSX.Element
  onClickCancel?: (splitOrderNumber: string) => void
  onClickReturnId?: (id: string) => void
  onClickCreateReturn?: () => void
  showDropshipAvailability?: boolean
}

export const SingleOrderSummarySidebar = (props: OrderSidebarProps) => {
  const { hasCap } = useContext(AuthContext)

  const {
    getOrder,
    onToggle,
    onBack,
    renderFirstOrderCard,
    onClickCancel,
    onClickReturnId,
    onClickCreateReturn,
    showDropshipAvailability,
  } = props

  const canEditOrder = hasCap(EDITORDERS_READWRITE)
  const canModifyOrderAddress = canEditOrder || hasCap(MANAGEORDERS_READWRITE)
  const canReadStoreCredits = hasCap(MANAGESTORECREDIT_READ)
  const canIssueNewStoreCredit =
    canEditOrder || hasCap(MANAGESTORECREDIT_READWRITE)

  const issueStoreCreditPanel = usePanelControl()

  const handleCloseIssueStoreCreditPanel = useCallback(() => {
    issueStoreCreditPanel.close()
    getOrder.performRequest()
  }, [issueStoreCreditPanel, getOrder])

  if (
    getOrder &&
    (getOrder.params.order_id != null || getOrder.params.id != null)
  ) {
    return (
      <>
        {canReadStoreCredits &&
          canIssueNewStoreCredit &&
          getOrder.result != null && (
            <IssueStoreCreditPanel
              key={getOrder.result.orderId}
              items={getOrder.result.line_items}
              orderId={getOrder.result.orderId}
              firstName={getOrder.result.billingFirstName}
              customerEmail={getOrder.result.email}
              isOpen={issueStoreCreditPanel.isOpen}
              onClose={handleCloseIssueStoreCreditPanel}
            />
          )}
        <Portal id="sidebar-content">
          <OrderEditor
            {...{
              issueStoreCreditPanel,
              canEditOrder,
              canModifyOrderAddress,
              canIssueNewStoreCredit,
              getOrder,
              onToggle,
              onBack,
              renderFirstOrderCard,
              onClickCancel,
              onClickReturnId,
              onClickCreateReturn,
              showDropshipAvailability,
            }}
          />
        </Portal>
      </>
    )
  }

  return (
    <Portal id="sidebar-content">
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list" />
          </a>
        </li>
      </ul>

      <div className="tab-content">
        <div className="p-3">No order selected.</div>
      </div>
    </Portal>
  )
}

export const MultiOrderSummarySidebar = (props: {
  getOrdersSummary?: PropsType<typeof MultiOrderEditor, 'getOrdersSummary'>
  showSummary: boolean
  onToggleShowSummary: () => void
  onToggle: (e: React.MouseEvent<HTMLElement>) => void
}) => {
  const { getOrdersSummary, onToggle, onToggleShowSummary, showSummary } = props

  return (
    <Portal id="sidebar-content">
      <MultiOrderEditor
        {...{
          showSummary,
          onToggleShowSummary: onToggleShowSummary,
          getOrdersSummary,
          onToggle,
        }}
      />
    </Portal>
  )
}

export const NoOrderSummarySidebar = (props: {
  onToggle: (e: React.MouseEvent<HTMLElement>) => void
}) => {
  const { onToggle } = props

  return (
    <Portal id="sidebar-content">
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list" />
          </a>
        </li>
      </ul>

      <div className="tab-content">
        <div className="p-3">No orders selected.</div>
      </div>
    </Portal>
  )
}
