import { datadogLogs } from '@datadog/browser-logs'
import { useInterval } from 'hooks'
import { useCallback, useEffect, useMemo, useReducer } from 'react'
import shortid from 'shortid'
import JobHandler from './JobHandler'
import { useZebraPrinter } from './useZebraPrinter'

// Queue Status
export const QUEUE_STATUS_IDLE = 'IDLE'
export const QUEUE_STATUS_WAIT = 'WAIT'
export const QUEUE_STATUS_RUNNING = 'RUNNING'
export const QUEUE_STATUS_PAUSED = 'PAUSED'

// Job Status
export const STATUS_NEW = 'NEW'
export const STATUS_QUEUED = 'QUEUED'
export const STATUS_FAILED = 'FAILED'
export const STATUS_RUNNING = 'RUNNING'
export const STATUS_DONE = 'DONE'

export type JobType = {
  id: string
  desc?: string
  error?: string
  status: 'NEW' | 'QUEUED' | 'RUNNING' | 'DONE' | 'FAILED'
  completedAt?: Date
}

type State = {
  jobs: JobType[]
  activeJob: JobType | null
  status: string | null
  reloadPrinterStatus: boolean
  isPaused: boolean
}

const initialState = {
  jobs: [],
  activeJob: null,
  status: localStorage.getItem('DEFAULT_QUEUE_STATUS') || null,
  reloadPrinterStatus: false,
  isPaused: false,
}

type Action =
  | { type: 'MARK_FAILED'; error: string }
  | { type: 'MARK_DONE' }
  | { type: 'MARK_RUNNING'; id?: string }
  | { type: 'NEXT_JOB'; id?: string }
  | { type: 'ADD_JOB'; job: any }
  | { type: 'RELOAD_PRINTER_STATUS' }
  | { type: 'RELOAD_PRINTER_STATUS_OFF' }
  | { type: 'SET_STATUS'; status: string }
  | { type: 'PAUSE' }
  | { type: 'RESUME' }

const job = (state: JobType, action: Action) => {
  switch (action.type) {
    case 'MARK_FAILED': {
      if (state.status === STATUS_RUNNING) {
        return {
          ...state,
          status: STATUS_FAILED,
          error: action.error,
        }
      }

      return state
    }
    case 'MARK_DONE': {
      if (state.status === STATUS_RUNNING) {
        return { ...state, status: STATUS_DONE, completedAt: new Date() }
      }

      return state
    }
    case 'MARK_RUNNING': {
      if (state.id === action.id) {
        return { ...state, status: STATUS_RUNNING }
      }

      return state
    }
    case 'NEXT_JOB': {
      if (state.id === action.id) {
        return { ...state, status: STATUS_QUEUED }
      }

      if (state.status === STATUS_DONE && state.completedAt) {
        const secondsElapsed = Math.round(
          (new Date().getTime() - state.completedAt.getTime()) / 1000,
        )

        if (secondsElapsed >= 10) {
          return null
        }
      }

      return state
    }
  }

  return state
}

const jobs = (state: JobType[], action: Action) => {
  switch (action.type) {
    case 'NEXT_JOB':
    case 'MARK_FAILED':
    case 'MARK_RUNNING':
    case 'MARK_DONE': {
      return state.map((j) => job(j, action)).filter((j) => j !== null)
    }
    case 'ADD_JOB': {
      return [
        ...state,
        { ...action.job, status: STATUS_NEW, id: shortid.generate() },
      ]
    }
  }

  return state
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'RELOAD_PRINTER_STATUS': {
      if (state.status === QUEUE_STATUS_IDLE) {
        return {
          ...state,
          reloadPrinterStatus: true,
          status: null,
        }
      }

      return { ...state, reloadPrinterStatus: true }
    }
    case 'RELOAD_PRINTER_STATUS_OFF': {
      return { ...state, reloadPrinterStatus: false }
    }
    case 'SET_STATUS': {
      return { ...state, status: action.status }
    }
    case 'ADD_JOB': {
      return {
        ...state,
        jobs: jobs(state.jobs, action),
      }
    }
    case 'PAUSE': {
      if (!state.status || state.status === QUEUE_STATUS_IDLE) {
        return { ...state, status: QUEUE_STATUS_PAUSED }
      }

      return state
    }
    case 'RESUME': {
      if (state.status === QUEUE_STATUS_PAUSED) {
        return { ...state, status: null }
      }

      return state
    }
    case 'MARK_DONE': {
      return {
        ...state,
        activeJob: null,
        status: null,
        jobs: jobs(state.jobs, action),
      }
    }
    case 'MARK_FAILED': {
      return {
        ...state,
        status: null,
        activeJob: null,
        jobs: jobs(state.jobs, action),
      }
    }
    case 'MARK_RUNNING': {
      if (state.activeJob == null) {
        return state
      }

      const queuedJob = state.jobs.find((job) => job.status === STATUS_QUEUED)

      if (queuedJob == null) {
        return state
      }

      if (state.status !== QUEUE_STATUS_IDLE) {
        return state
      }

      return {
        ...state,
        status: QUEUE_STATUS_RUNNING,
        jobs: jobs(state.jobs, { ...action, id: queuedJob.id }),
      }
    }
    case 'NEXT_JOB': {
      const nextJob = state.jobs.find(
        (job) => job.status !== STATUS_DONE && job.status !== STATUS_FAILED,
      )

      if (state.status !== QUEUE_STATUS_IDLE || nextJob == null) {
        return {
          ...state,
          jobs: jobs(state.jobs, action),
        }
      }

      return {
        ...state,
        activeJob: { ...nextJob },
        jobs: jobs(state.jobs, { ...action, id: nextJob.id }),
      }
    }
    default: {
      return state
    }
  }
}

let checkingIsPrinterReady = false

export type PrinterQueueType = {
  zebraPrinter: any
  addJob: any
  addSendFileDataJob: any
  resume: any
  jobs: JobType[]
  isPaused: boolean
  status: string
  reloadPrinterStatus: any
}

export const usePrinterQueue = (): PrinterQueueType => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const zebraPrinter = useZebraPrinter()

  const runJob = useCallback(
    async (job) => {
      if (Object.hasOwn(JobHandler, job.name)) {
        await JobHandler[job.name]({ job, zebraPrinter })
      }
    },
    [zebraPrinter],
  )

  /* Work around buggy BrowserPrinter. */
  useEffect(() => {
    const handler = () => {
      if (checkingIsPrinterReady) {
        console.log('Catched unhandled promise reject') // eslint-disable-line
        dispatch({ type: 'SET_STATUS', status: null })
        dispatch({ type: 'RELOAD_PRINTER_STATUS' })
      }
    }

    window.addEventListener('unhandledrejection', handler)

    return () => window.removeEventListener('unhandledrejection', handler)
  }, [])

  useInterval(
    useCallback(async () => {
      if (state.status === QUEUE_STATUS_PAUSED) {
        return
      }

      if (!zebraPrinter.isReady) {
        zebraPrinter.reload()
        return
      }

      if (
        state.reloadPrinterStatus &&
        state.status === QUEUE_STATUS_WAIT &&
        zebraPrinter.isReady
      ) {
        dispatch({ type: 'SET_STATUS', status: null })
        dispatch({ type: 'RELOAD_PRINTER_STATUS_OFF' })
        return
      }

      if (state.status === null) {
        dispatch({ type: 'SET_STATUS', status: QUEUE_STATUS_WAIT })

        if (state.reloadPrinterStatus) {
          zebraPrinter.reload()
          return
        }

        try {
          checkingIsPrinterReady = true
          await zebraPrinter.printer.isPrinterReady()
          checkingIsPrinterReady = false

          dispatch({ type: 'SET_STATUS', status: QUEUE_STATUS_IDLE })
        } catch (_e) {
          dispatch({ type: 'SET_STATUS', status: null })
        }

        return
      }

      if (
        state.status === QUEUE_STATUS_PAUSED ||
        state.status === QUEUE_STATUS_RUNNING
      ) {
        return
      }

      if (state.status === QUEUE_STATUS_IDLE && state.activeJob == null) {
        dispatch({ type: 'NEXT_JOB' })
        return
      }

      if (state.activeJob != null) {
        try {
          dispatch({ type: 'MARK_RUNNING' })

          await runJob(state.activeJob)

          dispatch({ type: 'MARK_DONE' })
        } catch (e) {
          console.error(e.message) // eslint-disable-line

          datadogLogs.logger.error(`Print job failed: ${e.message}`, {
            exception: e,
            job: state.activeJob,
          })

          dispatch({ type: 'MARK_FAILED', error: e.message })
        }
      }
    }, [
      runJob,
      zebraPrinter,
      state.activeJob,
      state.status,
      state.reloadPrinterStatus,
    ]),
    500,
  )

  const addJob = useCallback((job) => {
    dispatch({ type: 'ADD_JOB', job })
  }, [])

  const addSendFileDataJob = useCallback((desc, data) => {
    const job = { name: 'sendFileData', desc, data }

    dispatch({ type: 'ADD_JOB', job })
  }, [])

  const resume = useCallback(() => {
    dispatch({ type: 'RESUME' })
  }, [])

  const reloadPrinterStatus = useCallback(() => {
    dispatch({ type: 'RELOAD_PRINTER_STATUS' })
  }, [])

  const pause = useCallback(() => {
    dispatch({ type: 'PAUSE' })
  }, [])

  /* For debugging. */
  global.pauseQueue = pause

  return useMemo(
    () => ({
      zebraPrinter,
      addJob,
      addSendFileDataJob,
      resume,
      jobs: state.jobs,
      isPaused: state.isPaused,
      status: state.status,
      reloadPrinterStatus,
    }),
    [
      zebraPrinter,
      addJob,
      addSendFileDataJob,
      resume,
      state.jobs,
      state.isPaused,
      state.status,
      reloadPrinterStatus,
    ],
  )
}

export default usePrinterQueue
