import type { GetOrderApiParams, GetOrderApiResult } from '@ttc/api/orders'
import type { StoreCredit } from '@ttc/api/storeCredits'
import { EDITORDERS_READWRITE, MANAGECUSTOMERS_READWRITE } from 'caps'
import { Loading, PageNavHead } from 'components'
import SidebarToggleButton from 'components/SidebarToggleButton'
import { Table, setSelectedRow } from 'components/Table'
import ColumnPresetButton from 'containers/ManageOrders/Filters/ColumnPresetButton'
import Sidebar from 'containers/ManageStoreCredits/Sidebar'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import { SingleOrderSummarySidebar } from 'containers/common/OrderSummarySidebar'
import {
  useApi,
  usePageTitle,
  usePanelControl,
  usePersistedState,
  useSidebar,
} from 'hooks'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
  Badge,
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from 'reactstrap'
import AuthContext from './../../AuthContext'
import AddressCard from './AddressCard'
import AddressEditor from './AddressEditor'
import CustomerEditor from './CustomerEditor'
import { useOrdersTable } from './OrdersTable'
import OverviewCard from './OverviewCard'
import { useShipmentsTable } from './ShipmentsTable'
import { useStoreCreditsTable } from './StoreCreditsTable'
import SubscriptionLogPanel from './SubscriptionLogPanel'
import { useSubscriptionsTable } from './SubscriptionsTable'

const storageKey = 'editCustomer'

const TAB_ORDERS = 'orders'
const TAB_SHIPMENTS = 'shipments'
const TAB_STORECREDIT = 'storecredits'
const TAB_SUBSCRIPTIONS = 'subscriptions'

const Tab = (props) => {
  const { onClick, name, children, active } = props

  const handleClickTab = useCallback(() => {
    if (onClick) {
      onClick(name)
    }
  }, [name, onClick])

  return (
    <NavItem>
      <NavLink active={active} onClick={handleClickTab}>
        {children}
      </NavLink>
    </NavItem>
  )
}

const EditCustomer = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { hasCap } = useContext(AuthContext)
  const canEdit = hasCap(MANAGECUSTOMERS_READWRITE)

  const customerEditor = usePanelControl()
  const billingAddressEditor = usePanelControl()
  const shippingAddressEditor = usePanelControl()
  const manageColumnsPanel = usePanelControl()

  const getCustomer = useApi(
    useMemo(() => ({ action: 'customers_get', id }), [id]),
    null,
    { autoPerform: true, cache: true },
  )

  const customer = getCustomer.result

  const pageTitle = useMemo(
    () => (customer ? customer.fullName : 'Edit Customer'),
    [customer],
  )
  usePageTitle(pageTitle)

  const handleCloseEditor = useCallback(() => {
    customerEditor.close()
    shippingAddressEditor.close()
    billingAddressEditor.close()
    getCustomer.performRequest()
  }, [getCustomer, customerEditor, shippingAddressEditor, billingAddressEditor])

  const [activeTab, setActiveTab] = usePersistedState(
    TAB_ORDERS,
    `${storageKey}.activeTab`,
  )

  const subscriptionsTable = useSubscriptionsTable(id)
  const storeCreditsTable = useStoreCreditsTable(id)
  const shipmentsTable = useShipmentsTable(id)
  const ordersTable = useOrdersTable(id)

  let selectedRows = []
  let selectedOrderId = null
  let selectedStoreCreditId = null
  let selectedSubscriptionId = null

  if (activeTab === TAB_ORDERS) {
    selectedOrderId = ordersTable.selectedRowId
    selectedRows = ordersTable.selectedRows
  }

  if (activeTab === TAB_STORECREDIT) {
    selectedStoreCreditId = storeCreditsTable.selectedRowId
    selectedRows = storeCreditsTable.selectedRows
  }

  if (activeTab === TAB_SUBSCRIPTIONS) {
    selectedSubscriptionId = subscriptionsTable.selectedRowId
  }

  const handleCloseLogPanel = useCallback(() => {
    subscriptionsTable.dispatch(setSelectedRow(null))
  }, [subscriptionsTable])

  const sidebar = useSidebar({
    openForSingleRow: true,
    openForMultiRows: false,
    selectedRows,
  })

  const getStoreCredit = useApi<StoreCredit>(
    useMemo(
      () => ({
        action: 'storeCredits_get',
        json: {
          id: selectedStoreCreditId,
          withLog: true,
        },
      }),
      [selectedStoreCreditId],
    ),
    null,
    {
      autoPerform:
        activeTab === TAB_STORECREDIT &&
        sidebar.isOpen &&
        Number(selectedStoreCreditId) > 0,
    },
  )

  const handleClickTab = useCallback(
    (name: string) => {
      setActiveTab(name)
    },
    [setActiveTab],
  )

  const getOrder = useApi<GetOrderApiResult, GetOrderApiParams>(
    useMemo(
      () => ({ action: 'editOrder_getOrder', order_id: selectedOrderId }),
      [selectedOrderId],
    ),
    null,
    {
      autoPerform:
        activeTab === TAB_ORDERS && sidebar.isOpen && selectedOrderId != null,
      cache: true,
    },
  )

  const hasData = getCustomer.hasResult

  const handleClickAddOrder = useCallback(() => {
    navigate('/orders/add', { state: { userId: id } })
  }, [id, navigate])

  const handleClickReload = useCallback(() => {
    getCustomer.performRequest()
    ordersTable.triggerSearch()
    shipmentsTable.triggerSearch()
    storeCreditsTable.triggerSearch()
    subscriptionsTable.triggerSearch()
  }, [
    getCustomer,
    ordersTable,
    shipmentsTable,
    storeCreditsTable,
    subscriptionsTable,
  ])

  let columns = null

  switch (activeTab) {
    case TAB_ORDERS:
      columns = ordersTable.columns
      break
    case TAB_SHIPMENTS:
      columns = shipmentsTable.columns
      break
    case TAB_STORECREDIT:
      columns = storeCreditsTable.columns
      break
    case TAB_SUBSCRIPTIONS:
      columns = subscriptionsTable.columns
      break
  }

  return (
    <>
      <PageNavHead
        hasTail={false}
        prevPageLink="/customers"
        prevPageLabel="Customers"
        {...{
          isLoading: getCustomer.isLoading,
          pageTitle: `Edit Customer #${id}`,
          onClickReload: handleClickReload,
        }}
      >
        <div style={{ marginLeft: 'auto' }}>
          {!sidebar.isOpen && (
            <>
              {hasCap(EDITORDERS_READWRITE) && (
                <Button className="mr-2" onClick={handleClickAddOrder}>
                  Add Order
                </Button>
              )}
              <SidebarToggleButton sidebar={sidebar} />
            </>
          )}
        </div>
        {!sidebar.isOpen && !!columns && (
          <ColumnPresetButton {...{ manageColumnsPanel, columns }} />
        )}
      </PageNavHead>
      <Container fluid style={{ position: 'relative' }}>
        <div className="edit-customer animated fadeIn large-min-height">
          {!!columns && (
            <ManageColumnsPanel
              {...{ columns }}
              isOpen={manageColumnsPanel.isOpen}
              onClose={manageColumnsPanel.close}
            />
          )}
          <CustomerEditor
            {...{ getCustomer }}
            editId={id}
            isLoading={getCustomer.isLoading}
            isOpen={customerEditor.isOpen}
            onClose={handleCloseEditor}
          />
          <AddressEditor
            addressType="billing"
            {...{ getCustomer }}
            editId={id}
            isLoading={getCustomer.isLoading}
            isOpen={billingAddressEditor.isOpen}
            onClose={handleCloseEditor}
          />
          <AddressEditor
            addressType="shipping"
            {...{ getCustomer }}
            editId={id}
            isLoading={getCustomer.isLoading}
            isOpen={shippingAddressEditor.isOpen}
            onClose={handleCloseEditor}
          />
          <Row className="pb-3">
            <Col className="mb-2" xs="12" sm="6" md="6">
              <OverviewCard
                {...{
                  customer,
                  isLoading: !hasData,
                }}
                onClickEdit={canEdit && hasData ? customerEditor.open : null}
              />
            </Col>
            <Col className="mb-2" xs="12" sm="6" md="3">
              <AddressCard
                {...{
                  title: 'Default Shipping Address',
                  addressType: 'shipping',
                  customer,
                  isLoading: !hasData,
                }}
                onClickEdit={
                  canEdit && hasData ? shippingAddressEditor.open : null
                }
              />
            </Col>
            <Col className="mb-2" xs="12" sm="6" md="3">
              <AddressCard
                {...{
                  title: 'Default Billing Address',
                  addressType: 'billing',
                  customer,
                  isLoading: !hasData,
                }}
                onClickEdit={
                  canEdit && hasData ? billingAddressEditor.open : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav tabs style={{ border: 'none' }}>
                <Tab
                  name={TAB_ORDERS}
                  active={activeTab === TAB_ORDERS}
                  onClick={handleClickTab}
                >
                  Orders
                  {ordersTable.rows.length ? (
                    <Badge className="ml-2" pill color="success">
                      {ordersTable.rows.length}
                    </Badge>
                  ) : null}
                </Tab>
                <Tab
                  name={TAB_SHIPMENTS}
                  active={activeTab === TAB_SHIPMENTS}
                  onClick={handleClickTab}
                >
                  Shipments
                  {shipmentsTable.rows.length ? (
                    <Badge className="ml-2" pill color="success">
                      {shipmentsTable.rows.length}
                    </Badge>
                  ) : null}
                </Tab>
                <Tab
                  name={TAB_STORECREDIT}
                  active={activeTab === TAB_STORECREDIT}
                  onClick={handleClickTab}
                >
                  Store Credits
                  {storeCreditsTable.rows.length ? (
                    <Badge className="ml-2" pill color="success">
                      {storeCreditsTable.rows.length}
                    </Badge>
                  ) : null}
                </Tab>
                <Tab
                  name={TAB_SUBSCRIPTIONS}
                  active={activeTab === TAB_SUBSCRIPTIONS}
                  onClick={handleClickTab}
                >
                  Subscriptions
                  {subscriptionsTable.rows.length ? (
                    <Badge className="ml-2" pill color="success">
                      {subscriptionsTable.rows.length}
                    </Badge>
                  ) : null}
                </Tab>
              </Nav>
              <TabContent className="mb-5" style={{ minHeight: 50 }}>
                {activeTab === TAB_SUBSCRIPTIONS ? (
                  <>
                    {!subscriptionsTable.didLoad ? <Loading /> : null}
                    {subscriptionsTable.isEmpty ? (
                      <div className="m-4">No subscriptions yet.</div>
                    ) : null}
                    {subscriptionsTable.didLoad &&
                    !subscriptionsTable.isEmpty ? (
                      <Table
                        border={false}
                        hasPagination={false}
                        entityName="subscriptions"
                        {...subscriptionsTable}
                      />
                    ) : null}
                  </>
                ) : null}
                {activeTab === TAB_STORECREDIT ? (
                  <>
                    {!storeCreditsTable.didLoad ? <Loading /> : null}
                    {storeCreditsTable.isEmpty ? (
                      <div className="m-4">No store credits yet.</div>
                    ) : null}
                    {storeCreditsTable.didLoad && !storeCreditsTable.isEmpty ? (
                      <Table
                        border={false}
                        hasPagination={false}
                        entityName="store credit"
                        {...storeCreditsTable}
                      />
                    ) : null}
                  </>
                ) : null}
                {activeTab === TAB_ORDERS ? (
                  <>
                    {!ordersTable.didLoad ? <Loading /> : null}
                    {ordersTable.isEmpty ? (
                      <div className="m-4">No Orders yet.</div>
                    ) : null}
                    {ordersTable.didLoad && !ordersTable.isEmpty ? (
                      <Table
                        border={false}
                        hasPagination={false}
                        entityName="orders"
                        {...ordersTable}
                      />
                    ) : null}
                  </>
                ) : null}
                {activeTab === TAB_SHIPMENTS ? (
                  <>
                    {!shipmentsTable.didLoad ? <Loading /> : null}
                    {shipmentsTable.isEmpty ? (
                      <div className="m-4">No Shipments yet.</div>
                    ) : null}
                    {shipmentsTable.didLoad && !shipmentsTable.isEmpty ? (
                      <Table
                        border={false}
                        hasPagination={false}
                        entityName="shipments"
                        {...shipmentsTable}
                      />
                    ) : null}
                  </>
                ) : null}
              </TabContent>
            </Col>
          </Row>
        </div>
      </Container>
      {activeTab === TAB_SUBSCRIPTIONS ? (
        <SubscriptionLogPanel
          id={selectedSubscriptionId}
          isOpen={selectedSubscriptionId !== null}
          onClose={handleCloseLogPanel}
        />
      ) : null}
      {activeTab === TAB_STORECREDIT ? (
        <Sidebar
          {...{
            selectedRows: selectedStoreCreditId ? [selectedStoreCreditId] : [],
            getStoreCredit,
            onToggle: sidebar.userToggle,
            onUpdate: storeCreditsTable.triggerSearch,
          }}
        />
      ) : (
        <SingleOrderSummarySidebar
          {...{
            getOrder,
            onToggle: sidebar.userToggle,
          }}
        />
      )}
    </>
  )
}

export default EditCustomer
