import { apiRequest } from 'api'
import {
  type Row,
  Table,
  type State as TableState,
  defaultCellRenderer,
  setCellLoading,
  setEditCell,
  setRow,
  useTable,
  useTableApi,
} from 'components/Table'
import type { TableCellChangeEventProps } from 'components/Table/types'
import { usePrevious } from 'hooks'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router'
import { Button } from 'reactstrap'
import { askQuestion, showError, uiApiRequest } from 'utils'
import { getStorage } from 'utils/storage'
import { getColumnDef } from './columnDef'

const storageKey = 'trucksTable'
const [tableSetItem, tableGetItem] = getStorage(storageKey)

type TrucksTableProps = {
  id: number
  selectedRow: string
  onSelectRow: (rowId: string) => void
  onChange: () => void
  referenceNumber: string
  reloadKey: number
  canAdd: boolean
  readOnly: boolean
}

const TrucksTable = (props: TrucksTableProps) => {
  const navigate = useNavigate()

  const numberOfRows = useRef(0)
  const {
    id,
    selectedRow,
    onSelectRow,
    onChange,
    reloadKey,
    canAdd,
    readOnly,
  } = props

  const columnDef = useMemo(() => getColumnDef(readOnly), [readOnly])

  const handleChangeTable = useCallback(
    (state: TableState<Row>) => {
      // After a truck has been deleted, we need to navigate back to
      // "deliveries" to dismiss the truck items table.
      if (state.didLoad && selectedRow) {
        const hasSelectedRow =
          state.rows.filter((row) => String(row.id) === String(selectedRow))
            .length > 0
        if (!hasSelectedRow) {
          navigate(`/purchase-orders/edit/${id}/deliveries`)
        }
      }

      // If the number of rows changes, it means that a truck has been added
      // or removed. In this case we want to reload the purchase order
      // to update the cards displayed above the table.
      if (numberOfRows.current !== state.rows.length) {
        if (onChange) {
          onChange()
        }
        numberOfRows.current = state.rows.length
      }
    },
    [selectedRow, onChange, id, navigate],
  )

  const [tableState, tableDispatch] = useTable({
    cacheKey: `trucksTable_${props.id}`,
    getItem: tableGetItem,
    setItem: tableSetItem,
  })
  const [tableTriggerSearch] = useTableApi(
    'purchaseOrders_getTrucks',
    tableState,
    tableDispatch,
    { id: props.id },
    { onChange: handleChangeTable },
  )

  const prevReloadKey = usePrevious(reloadKey)
  useEffect(() => {
    if (reloadKey === prevReloadKey) {
      return
    }

    tableTriggerSearch()
  }, [tableTriggerSearch, prevReloadKey, reloadKey])

  const handleClickRow = useCallback(
    (rowId: string) => {
      if (onSelectRow) {
        onSelectRow(rowId)
      }

      return false
    },
    [onSelectRow],
  )

  const handleClickCell = useCallback(
    (rowId: string, colId: string) => {
      if (readOnly) {
        // Handled by handleClickRow
        return false
      }

      if (selectedRow === rowId) {
        tableDispatch(setEditCell(rowId, colId))
      } else {
        if (onSelectRow) {
          onSelectRow(rowId)
        }
      }

      return false
    },
    [tableDispatch, onSelectRow, selectedRow, readOnly],
  )

  const handleChangeCell = useCallback(
    async (props: TableCellChangeEventProps) => {
      const { value, oldValue, colId, rowId } = props

      const apiCall = {
        number: 'purchaseOrderTrucks_setNumber',
        date: 'purchaseOrderTrucks_setDate',
        status: 'purchaseOrderTrucks_setStatus',
      }

      if (apiCall[colId] == null) {
        return
      }

      try {
        tableDispatch(setCellLoading(rowId, colId))

        const row = await apiRequest({
          action: apiCall[colId],
          id: rowId,
          value,
          oldValue,
        })

        tableDispatch(setRow(rowId, row))
        tableDispatch(setCellLoading(null))

        tableTriggerSearch(true)

        if (onChange) {
          onChange()
        }
      } catch (e) {
        tableDispatch(setCellLoading(null))
        await showError(e.message)
        tableTriggerSearch(true)
      }
    },
    [tableDispatch, tableTriggerSearch, onChange],
  )

  const renderRowClassName = useCallback(
    ({ row }) => {
      return row.id === selectedRow ? 'selected' : ''
    },
    [selectedRow],
  )

  const handleClickAddTruck = useCallback(async () => {
    await uiApiRequest({
      action: 'purchaseOrders_addTruck',
      id: props.id,
    })
    tableTriggerSearch(true)
  }, [tableTriggerSearch, props.id])

  const handleClickRemoveTruck = useCallback(async () => {
    const isYes = await askQuestion(
      'Are you sure you want to remove this truck?',
    )
    if (isYes) {
      await uiApiRequest({
        action: 'purchaseOrders_trashTruck',
        id: selectedRow,
      })
      tableTriggerSearch(true)
    }
  }, [tableTriggerSearch, selectedRow])

  return (
    <>
      <Table
        border={false}
        onChangeCell={handleChangeCell}
        onClickCell={handleClickCell}
        onClickRow={readOnly ? handleClickRow : null}
        entityName="trucks"
        hasPagination={false}
        {...tableState}
        {...{
          selectedRows: [selectedRow],
          renderRowClassName,
          renderCell: defaultCellRenderer,
          setItem: tableSetItem,
          getItem: tableGetItem,
          columnDef,
          dispatch: tableDispatch,
        }}
      />
      {!readOnly ? (
        <div className="m-3">
          <Button
            disabled={!canAdd}
            className="mr-2"
            onClick={handleClickAddTruck}
            color="primary"
          >
            Add Truck
          </Button>
          <Button
            disabled={selectedRow == null}
            onClick={handleClickRemoveTruck}
            color="danger"
          >
            Remove Truck
          </Button>
        </div>
      ) : null}
    </>
  )
}

export default TrucksTable
