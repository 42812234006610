import { Link } from 'react-router'

export const LinkToInventory = ({
  discountId,
  children,
}: { discountId: number; children: React.ReactNode }) => {
  return <Link to={`/inventory?discount=${discountId}`}>{children}</Link>
}

export default LinkToInventory
