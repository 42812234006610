import type { OrderLineItem } from '@ttc/api/orders'
import { useCallback, useMemo, useReducer } from 'react'

type State = Record<string, any>

export type QtyEditor = {
  getProductQty: (productId: number) => number
  init: (state: State) => void
  reset: () => void
  inc: (productId: number) => void
  dec: (productId: number) => void
  state: State
  total: number
}

type Action =
  | { type: 'INIT'; state: State }
  | { type: 'RESET' }
  | { type: 'INC'; productId: number }
  | { type: 'DEC'; productId: number }

/**
 * useQtyEditor is a hook that manages the state for a list of items in a customer's order.
 * It offers several actions: INIT, RESET, INC, DEC.
 *
 * The useQtyEditor hook is meant to be used within the LineItemEditor component.
 * It provides the state and handlers required for managing and interacting
 * with the quantities of order items.
 */
export const useQtyEditor = (items: OrderLineItem[]): QtyEditor => {
  const productMaxQty = (productId: number) => {
    const item = items.find((item) => item.product_data.id == productId)
    return item ? item.quantity : 0
  }

  const [state, dispatch] = useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case 'INIT': {
          return { ...action.state }
        }
        case 'RESET': {
          return {}
        }
        case 'INC': {
          const qty = Object.hasOwn(state, action.productId)
            ? Number(state[action.productId] || 0)
            : 0

          if (qty >= productMaxQty(action.productId)) {
            return state
          }

          return {
            ...state,
            [action.productId]: qty + 1,
          }
        }
        case 'DEC': {
          const qty = Object.hasOwn(state, action.productId)
            ? Number(state[action.productId] || 0)
            : 0

          if (qty <= 0) {
            return state
          }

          return {
            ...state,
            [action.productId]: qty - 1,
          }
        }
        default:
          return state
      }
    },
    () => ({}),
  )

  const getProductQty = useCallback(
    (productId: number) => {
      return Object.hasOwn(state, productId) ? state[productId] : 0
    },
    [state],
  )

  const init = useCallback((state: State) => {
    return dispatch({
      type: 'INIT',
      state,
    })
  }, [])

  const reset = useCallback(() => {
    return dispatch({ type: 'RESET' })
  }, [])

  const inc = useCallback((productId: number) => {
    return dispatch({
      type: 'INC',
      productId,
    })
  }, [])

  const dec = useCallback((productId: number) => {
    return dispatch({
      type: 'DEC',
      productId,
    })
  }, [])

  const total = useMemo(() => {
    return Object.values(state).reduce(
      (acc: number, qty: number) => acc + qty,
      0,
    )
  }, [state])

  return useMemo(
    () => ({
      getProductQty,
      init,
      reset,
      inc,
      dec,
      state,
      total,
    }),
    [getProductQty, init, reset, inc, dec, state, total],
  )
}
