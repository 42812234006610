import { EditPanel, EditPanelCard, FormRenderer, Loading } from 'components'
import { useApi, useFormWithDef, usePrevious } from 'hooks'
import type { ApiCall } from 'hooks'
import { Fragment, useCallback, useEffect } from 'react'
import { Button, Col, FormGroup, Row } from 'reactstrap'
import { askQuestion } from 'utils'
import formDef from './formDef'
import Swal from 'sweetalert2'
import { showError, showInfo } from 'utils/alert'

const CardActions = ({
  editId,
  getCustomer,
  onClose,
  passwordResetAction,
  setPasswordAction,
  deleteAccountAction,
}) => {
  const email = getCustomer.result?.email

  const getSubscriptions = useApi({ action: 'subscriptions_getAll' })

  const handleClickReset = useCallback(async () => {
    const confirm = await askQuestion(
      `This will send a password reset link to ${email}. Continue?`,
    )

    if (!confirm) {
      return
    }

    const ret = await passwordResetAction.performRequest()

    if (ret) {
      onClose(true)
    }
  }, [onClose, passwordResetAction, email])

  const handleClickDeleteUserAccount = useCallback(async () => {
    // First check if active subscription exists.
    const ret = await getSubscriptions.performRequest({
      filterCustomerId: editId,
    })
    if (Array.isArray(ret.rows)) {
      for (const row of ret.rows) {
        if (row.status === 'ACTIVE') {
          showError(
            'Cannot delete account. Active subscription exists which needs to be cancelled first.',
          )
          return
        }
      }
    }

    await Swal.fire({
      icon: 'warning',
      text: `This will delete the user account for ${email}. The user will not be able to log in anymore and personal data will be deleted within a week. Continue?`,
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await deleteAccountAction.performRequest({
            json: { id: editId },
          })

          Swal.close()
          await showInfo(
            'Account has been locked and is scheduled for deletion.',
          )
          onClose(true)
        } catch (e) {
          Swal.showValidationMessage(e.message)
          return false
        }
      },
    })
  }, [email, deleteAccountAction, onClose, editId, getSubscriptions])

  const handleClickSetPassword = useCallback(async () => {
    const genPassword = () => {
      const randomChar = () => {
        const chars = 'ABCDEFGHJKMNPQRSTUVWXYZ123456789'
        return chars[Math.floor(Math.random() * chars.length)]
      }

      const segment = () => {
        return Array.from({ length: 4 }, randomChar).join('')
      }

      const password = `${segment()}-${segment()}-${segment()}`

      return password
    }

    await Swal.fire({
      icon: 'warning',
      text: 'With this you can set a custom password for the customer. Only use this if the customer is unable to reset the password himself for some reason.',
      showCancelButton: true,
      showCloseButton: true,
      input: 'text',
      inputLabel: 'Password',
      inputPlaceholder: 'Enter password',
      inputValue: genPassword(),
      inputValidator: (value) => {
        if (!value) {
          return 'Enter a password.'
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async (value) => {
        try {
          await setPasswordAction.performRequest({
            json: { id: editId, password: value },
          })

          Swal.close()
          await showInfo(`Password set successfully to ${value}`)
          onClose(true)
        } catch (e) {
          Swal.showValidationMessage(e.message)
          return false
        }
      },
    })
  }, [onClose, setPasswordAction, editId])

  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickReset}
            >
              Send password reset email...
            </Button>
          </FormGroup>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickSetPassword}
            >
              Manually set Password...
            </Button>
          </FormGroup>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickDeleteUserAccount}
            >
              Delete user account...
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type CustomerEditorProps = {
  getCustomer: ApiCall
  isOpen: boolean
  onClose: () => void
  editId: string
  isLoading: boolean
}

const CustomerEditor = (props: CustomerEditorProps) => {
  const { getCustomer, isOpen, onClose, editId } = props
  const form = useFormWithDef(formDef)

  const passwordResetAction = useApi(
    { action: 'customers_sendPasswordResetEmail', id: editId },
    null,
    { errorModal: true },
  )

  const setPasswordAction = useApi(
    { action: 'customers_setPassword', id: editId },
    null,
    { throws: true },
  )

  const deleteAccountAction = useApi(
    { action: 'customers_deleteAccount', id: editId },
    null,
    { throws: true },
  )

  // Fill form as soon as panel opens.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      if (isOpen && getCustomer.result != null) {
        const { firstName, lastName, email } = getCustomer.result

        form.setState({ firstName, lastName, email })
      } else if (!isOpen) {
        form.reset()
      }
    }
  }, [form, prevIsOpen, isOpen, getCustomer.result])

  const updateCustomer = useApi({ action: 'customers_update' })

  const handleSubmit = useCallback(() => {
    const formSubmit = async () => {
      const ret = await updateCustomer.performRequest({
        id: editId,
        ...form.state,
      })

      if (ret) {
        onClose()
      }
    }

    formSubmit()
  }, [editId, form.state, onClose, updateCustomer])

  const isLoading =
    getCustomer.isLoading ||
    updateCustomer.isLoading ||
    passwordResetAction.isLoading ||
    deleteAccountAction.isLoading

  return (
    <EditPanel
      caption="Customer Info"
      onSubmit={isLoading ? null : handleSubmit}
      {...{ isOpen, onClose, isLoading }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment key={editId}>
          {editId != null ? (
            <CardActions
              {...{
                editId,
                getCustomer,
                onClose,
                passwordResetAction,
                deleteAccountAction,
                setPasswordAction,
              }}
            />
          ) : null}
          <EditPanelCard caption="Edit Customer Info" stateId="customer_info">
            {updateCustomer.error ? (
              <Row>
                <Col className="text-danger">{updateCustomer.error}</Col>
              </Row>
            ) : null}
            <FormRenderer {...{ form, formDef }} />
          </EditPanelCard>
        </Fragment>
      )}
    </EditPanel>
  )
}

export default CustomerEditor
