import { EditPanel, EditPanelCard, Loading } from 'components'
import { useApi } from 'hooks'
import { Fragment, useCallback, useEffect, useReducer, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { Button, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap'
import { askQuestion } from 'utils'

type Tag = {
  id: string
  label: string
  color: string
  priority: number
}

const normalize = (input: { rows: Tag[] }) => input.rows

type TagEditorProps = {
  id: string
  label: string
  color: string
  priority: number
  onSaved: () => void
  apiPrefix: string
  apiContext?: string
}

const TagEditor = (props: TagEditorProps) => {
  const { id, onSaved, apiPrefix, apiContext } = props

  const [state, dispatch] = useReducer((state: any, action: any) => {
    return { ...state, ...action }
  }, props)

  const api = useApi()

  const handleClickSave = useCallback(async () => {
    const { label, color, priority } = state

    const ret = await api.performRequest({
      action: id === 'add' ? `${apiPrefix}_add` : `${apiPrefix}_update`,
      json: {
        id,
        label,
        color,
        priority,
        context: apiContext,
      },
    })

    if (ret) {
      onSaved()
    }
  }, [api, id, state, onSaved, apiPrefix, apiContext])

  const handleClickDelete = useCallback(async () => {
    if (
      !(await askQuestion(
        'Are you sure you want to delete this tag? This will remove this tag from existing entities.',
      ))
    ) {
      return
    }

    const ret = await api.performRequest({
      action: `${apiPrefix}_trash`,
      json: { id, context: apiContext },
    })

    if (ret) {
      onSaved()
    }
  }, [api, id, onSaved, apiPrefix, apiContext])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget

    dispatch({ [name]: value })
  }, [])

  return (
    <ListGroupItem>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-label">
          Label
        </Label>
        <input
          className="form-control"
          id="input-label"
          type="text"
          name="label"
          value={state.label}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-color">
          Color
        </Label>
        <input
          className="form-control"
          id="input-color"
          type="color"
          name="color"
          value={state.color}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-priority">
          Priority
        </Label>
        <input
          className="form-control"
          id="input-priority"
          type="number"
          name="priority"
          value={state.priority}
          onChange={handleChange}
        />
      </FormGroup>
      <Button
        onClick={handleClickSave}
        className="mr-1"
        color="primary"
        disabled={api.isLoading}
      >
        {id === 'add' ? 'Add' : 'Save'}
      </Button>
      {id !== 'add' ? (
        <Button
          onClick={handleClickDelete}
          color="danger"
          disabled={api.isLoading}
        >
          Delete
        </Button>
      ) : null}
      <div className="clearfix" />
      {api.error ? <div className="text-danger mt-2">{api.error}</div> : null}
      {api.isLoading ? <Loading /> : null}
    </ListGroupItem>
  )
}

type ManageTagsPanelProps = {
  isOpen: boolean
  onClose: () => void
  apiPrefix: string
  apiContext?: string
}

const ManageTagsPanel = (props: ManageTagsPanelProps) => {
  const { isOpen, onClose, apiPrefix, apiContext } = props

  const getTags = useApi(
    () => ({ action: `${apiPrefix}_getAll`, json: { context: apiContext } }),
    () => [],
    { normalize },
  )
  const { error, isLoading } = getTags

  const [editId, setEditId] = useState(null)

  const handleClickEdit = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()

      const id = e.currentTarget.getAttribute('data-id')

      setEditId(String(editId) === String(id) ? null : id)
    },
    [editId],
  )

  const handleSaved = useCallback(() => {
    getTags.performRequest()
    setEditId(null)
  }, [getTags])

  useEffect(() => {
    if (isOpen) {
      getTags.performRequest()
    }
  }, [isOpen, getTags.performRequest]) // eslint-disable-line

  return (
    <EditPanel caption="Manage Tags" {...{ error, isOpen, onClose, isLoading }}>
      {getTags.isLoading ? (
        <Loading />
      ) : (
        <>
          <EditPanelCard caption="Tags">
            <Row>
              <Col>
                <ListGroup>
                  {(getTags.result || []).map((tag: Tag) => {
                    const { id } = tag

                    return (
                      <Fragment key={id}>
                        <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ cursor: 'pointer' }}
                          active={String(id) === String(editId)}
                          data-id={id}
                          onClick={handleClickEdit}
                        >
                          <div
                            className="mr-2 d-inline-block"
                            style={{
                              width: 10,
                              height: 10,
                              borderRadius: 3,
                              background: tag.color,
                            }}
                          />
                          {tag.label} ({tag.priority})
                        </ListGroupItem>
                        {String(id) === String(editId) ? (
                          <TagEditor
                            onSaved={handleSaved}
                            {...tag}
                            {...{ apiPrefix, apiContext }}
                          />
                        ) : null}
                      </Fragment>
                    )
                  })}
                  <ListGroupItem
                    tag="a"
                    href="#"
                    active={editId === 'add'}
                    data-id="add"
                    onClick={handleClickEdit}
                  >
                    Add Tag...
                  </ListGroupItem>
                  {editId === 'add' ? (
                    <TagEditor
                      onSaved={handleSaved}
                      id="add"
                      label=""
                      priority={0}
                      color="#ffffff"
                      {...{ apiPrefix, apiContext }}
                    />
                  ) : null}
                </ListGroup>
              </Col>
            </Row>
          </EditPanelCard>
        </>
      )}
    </EditPanel>
  )
}

export default ManageTagsPanel
