import { CellEditable } from 'components/Table'
import type { CellValueType } from 'components/Table/types'
import { useCallback, useMemo, useState } from 'react'

type TableFooterProps = {
  onChangeCharge: (charge: CellValueType, oldCharge: CellValueType) => void
  charge: number
  error: string | null
  readOnly: boolean
}

const TableFooter = (props: TableFooterProps) => {
  const { onChangeCharge, charge, error, readOnly } = props

  const [isEditing, setIsEditing] = useState(false)

  const chargeProps = useMemo(
    () => ({
      onChangeCell: (p: { value: CellValueType; oldValue: CellValueType }) => {
        if (onChangeCharge) {
          onChangeCharge(p.value, p.oldValue)
        }
      },
      dispatch: (a: { type: string; rowId?: string }) => {
        if (isEditing && a.type === 'SET_EDIT_CELL' && a.rowId === null) {
          setIsEditing(false)
        }
      },
      value: charge || 0,
      row: { id: '0' },
      col: { id: '0', isMoney: true },
      isEditing,
    }),
    [isEditing, charge, onChangeCharge],
  )

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLTableSectionElement>) => {
      const target = e.target as HTMLTableSectionElement

      if (!(target?.closest('td') && target.closest('tr'))) {
        return
      }

      setIsEditing(true)
    },
    [],
  )

  if (error != null) {
    return (
      <table className="mb-0 d-sm-table table">
        <tbody>
          <tr>
            <td className="text-danger">{error}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <table className="mb-0 d-sm-table table">
      <colgroup>
        <col style={{ width: 120 }} />
        <col style={{ width: 120 }} />
        <col style={{ width: '100%' }} />
      </colgroup>
      <tbody onClick={handleClick}>
        <tr>
          <td>
            <strong>Freight Charge:</strong>
          </td>
          {readOnly ? <td>${charge}</td> : <CellEditable {...chargeProps} />}
          <td />
        </tr>
      </tbody>
    </table>
  )
}

export default TableFooter
