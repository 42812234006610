/**
 * Parses a query string into a key-value pair object.
 *
 * Uses the JavaScript built-in function decodeURIComponent
 * to decode the keys and values in the query string.
 *
 * @param queryStr - The query string to parse.
 * @returns An object representing the key-value pairs in the query string.
 */
export function parseQueryStr(queryStr: string): Record<string, string> {
  const params = {}

  const str = queryStr.substring(queryStr.indexOf('?') === 0 ? 1 : 0)
  if (str === '') {
    return {}
  }

  for (const keyval of str.split('&')) {
    const [key, val] = keyval.split('=')
    params[decodeURIComponent(key)] =
      val == null || val === '' ? null : decodeURIComponent(val)
  }

  return params
}

/**
 * Encodes an object into a query string.
 *
 * Uses the JavaScript built-in function encodeURIComponent
 * to encode the keys and values in the object.
 *
 * @param params - The object to encode into a query string.
 * @returns  A query string representing the property-value pairs in the object.
 */
export function encodeQueryStr(params: {
  [key: string]: string | number | boolean
}) {
  return Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => {
      const encKey = encodeURIComponent(key)
      if (params[key] == null) {
        return encKey
      }

      const encValue = encodeURIComponent(params[key])
      return `${encKey}=${encValue}`
    })
    .join('&')
}

/**
 * Updates the current URL's query string with the given parameters.
 *
 * Encodes the object into a query string and updates
 * the current URL's query string with it.
 *
 * If forceReplace is true or the current URL doesn't have a query string,
 * it replaces the current URL's query string with the new one.
 *
 * Otherwise, it pushes a new entry onto the history stack.
 *
 * If the query string is already the same as the new one, it does nothing.
 *
 * @param params - The parameters to update the query string with.
 * @param forceReplace - Whether to replace the current URL even if there is a search string present
 */
export function updateQueryStr(
  params: { [key: string]: any },
  forceReplace = false,
) {
  const paramsStr = encodeQueryStr(params)

  const { protocol, host, pathname } = window.location
  const query = `${paramsStr !== '' ? '?' : ''}${paramsStr}`
  const newUrl = `${protocol}//${host}${pathname}${query}`

  if (forceReplace || window.location.search === '') {
    window.history.replaceState({ path: newUrl }, '', newUrl)
    return
  }

  if (window.location.search === `?${paramsStr}`) {
    return
  }

  window.history.pushState({ path: newUrl }, '', newUrl)
}

/**
 * Decodes a query string into an object where each value is an array of strings, by splitting query values on '~'.
 *
 * @param queryStr - The query string to decode.
 * @returns An object where each value is an array of strings.
 */
export const decodeQuery = (queryStr: string) => {
  const queryParams = parseQueryStr(queryStr)

  const ret = {}

  Object.keys(queryParams).map((key: string) => {
    const value = queryParams[key]

    if (value != null) {
      ret[key] = value.split('~')
    }
  })

  return ret
}
