import { OrderStatusBadge, ReturnStatusBadge, TagsCell } from 'components'
import type { ColumnDef } from 'components/Table/types'
import { customerCellRenderer } from 'components/cellRenderer'
import { Badge } from 'reactstrap'
import { formatDateReadable, formatPrice } from 'utils'

export type OrderRow = {
  id: string
  checkbox: boolean
  action: string
  age: string
  createdAt: string
  orderNumber: string
  status: string
  returnStatus: string
  paymentMethod: string
  tags: string[]
  total: number
  numItems: number
  itemName: string
  customerName: string
  batch: string
  scheduledFor: string
  isFulfillable: boolean
  shippingZone: string
  addressVerifyStatus: string
  hasNotesToBuyer: boolean
  hasNotesFromBuyer: boolean
  hasNotesInternal: boolean
}

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
    sortColumn: false,
    canExport: false,
  },
  {
    label: 'ID',
    id: 'id',
    defaultVisible: false,
    hasClipboardButton: true,
  },
  {
    label: 'Action',
    id: 'action',
    sortColumn: false,
    defaultVisible: false,
    isSecret: true,
    canExport: false,
  },
  {
    label: 'Age',
    id: 'age',
    sortColumn: true,
  },
  {
    label: 'Order Date',
    id: 'createdAt',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td>{value ? formatDateReadable(String(value)) : ''}</td>
    },
  },
  {
    label: 'Order Number',
    id: 'orderNumberNumeric',
    valueColumn: 'orderNumber',
    sortColumn: true,
    hasClipboardButton: true,
  },
  {
    label: 'Status',
    id: 'status',
    sortColumn: true,
    renderer: ({ value, row }) => {
      return (
        <td>
          <OrderStatusBadge value={String(value)} />
          {value === 'processing' || value === 'scheduled' ? (
            row.isAssembled ? (
              <Badge title="Assembled" color="primary">
                A
              </Badge>
            ) : row.batchId && row.batchStatus !== 'NEW' ? (
              <Badge title="Batched" color="warning">
                B
              </Badge>
            ) : null
          ) : (
            ''
          )}
        </td>
      )
    },
  },
  {
    label: 'Return Status',
    id: 'return_status',
    valueColumn: 'returnStatus',
    sortColumn: true,
    renderer: ({ value }) => {
      return (
        <td>
          <ReturnStatusBadge value={String(value)} />
        </td>
      )
    },
    defaultVisible: false,
  },
  {
    label: 'Payment Method',
    id: 'paymentMethod',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Tags',
    id: 'tags',
    sortColumn: false,
    renderer: ({ value }) => <TagsCell {...{ value }} />,
    canExport: false,
  },
  {
    label: 'Total',
    id: 'total',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td>${formatPrice(Number(value) * 100)}</td>
    },
  },
  {
    label: 'Qty',
    id: 'numItems',
    sortColumn: true,
  },
  {
    label: 'Item Name',
    id: 'itemName',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td>{value === '' ? '(Multiple Items)' : value}</td>
    },
  },
  {
    label: 'Customer Name',
    id: 'customerName',
    defaultVisible: false,
    sortColumn: false,
    renderer: customerCellRenderer,
  },
  {
    label: 'Notes',
    id: 'notes',
    sortColumn: false,
    canExport: false,
    defaultVisible: false,
  },
  {
    label: 'Scheduled',
    id: 'scheduledFor',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td>{value ? formatDateReadable(String(value)) : ''}</td>
    },
  },
  {
    label: 'Batch',
    id: 'batch',
    sortColumn: true,
    renderer: ({ value, row }) => {
      if (row.isBlocked) {
        return (
          <td className="text-danger" title="Blocked">
            <i className="fa fa-ban" />
          </td>
        )
      }

      return (
        <td title={`Batch ${value} Status: ${row.batchStatus}`}>{value}</td>
      )
    },
  },
  {
    label: 'Items',
    id: 'isFulfillable',
    title: 'Is the order fulfillable?',
    sortColumn: false,
    renderer: ({ value }) => {
      return (
        <td>
          {value === false ? (
            <i
              className="text-danger fa fa-times-circle"
              title="Not Fulfillable"
            />
          ) : value === true ? (
            <i
              style={{ color: '#256c2b' }}
              className="fa fa-check-circle"
              title="Fulfillable"
            />
          ) : value === 'batched' ? (
            <i
              style={{ color: '#1aa1f2' }}
              className="fa fa-dot-circle-o"
              title="Batched"
            />
          ) : null}
        </td>
      )
    },
  },
  {
    label: 'DS-ITEMS',
    title: 'Is it possible to dropship?',
    id: 'isDropshipable',
    sortColumn: false,
    renderer: ({ value, row }) => {
      return (
        <td>
          {row.hasDropshipOrder ? (
            <i
              style={{ color: '#1aa1f2' }}
              className="fa fa-ship"
              title="Dropship order exists"
            />
          ) : value === true ? (
            <i
              style={{ color: '#256c2b' }}
              className="fa fa-check-circle"
              title="Fulfillable via dropshipping"
            />
          ) : value === 'partial' ? (
            <i
              className="fa fa-check-circle text-warning"
              title="Partially fulfillable via dropshipping"
            />
          ) : (
            <i
              className="text-danger fa fa-times-circle"
              title="Not fulfillable via dropshipping"
            />
          )}
        </td>
      )
    },
  },
  {
    label: 'Zone',
    id: 'shippingZone',
    sortColumn: true,
  },
  {
    label: 'AV',
    title: 'Address verification status',
    id: 'addressVerifyStatus',
    sortColumn: true,
    renderer: ({ value }) => {
      return (
        <td>
          {value === 'verified' ? (
            <i style={{ color: '#256c2b' }} className="fa fa-check-circle" />
          ) : value === 'warning' ? (
            <i className="text-warning fa fa-check-circle" />
          ) : value !== '' ? (
            <i className="text-danger fa fa-times-circle" />
          ) : null}
        </td>
      )
    },
  },
]

export default columnDef
