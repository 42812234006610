import type { GetAllBatchesApiResult } from '@ttc/api/batch'
import { LoadingImage } from 'components'
import { useApi, useDropDownToggler, usePrevious } from 'hooks'
import { useCallback, useEffect } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { askQuestion, askQuestionWithReason, sleep } from 'utils'
import type { ManageOrdersFiltersType } from '../Filters'

type BatchActionButtonProps = {
  selectedRows: string[]
  onOrderModified: () => void
  filters: ManageOrdersFiltersType
  filterStatus: string
  resultTotal: number
  query: string
  canModifyBatches: boolean
  canBlockUnblockOrders: boolean
}

const BatchActionButton = (props: BatchActionButtonProps) => {
  const {
    selectedRows,
    onOrderModified,
    filters,
    filterStatus,
    resultTotal,
    query,
    canModifyBatches,
    canBlockUnblockOrders,
  } = props

  const toggler = useDropDownToggler()

  const apiAction = useApi(null, null, { errorModal: true })
  const apiActionThrows = useApi(null, null, () => ({ throws: true }))

  const apiLoad = useApi<GetAllBatchesApiResult>(
    { action: 'batches_getAll' },
    [],
    {
      cache: true,
    },
  )

  const togglerIsOpenPrev = usePrevious(toggler.isOpen)
  useEffect(() => {
    if (togglerIsOpenPrev != toggler.isOpen && toggler.isOpen) {
      apiLoad.performRequest()
    }
  }, [togglerIsOpenPrev, toggler.isOpen, apiLoad])

  const handleClickAssignTo = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      const id = e.currentTarget.getAttribute('data-id')
      const name = e.currentTarget.getAttribute('data-name')
      const status = e.currentTarget.getAttribute('data-status')
      const numItems = e.currentTarget.getAttribute('data-numitems')

      if (
        id !== 'new' &&
        status !== 'NEW' &&
        !(await askQuestion(
          `Batch ${name} has status ${status}. Are you sure you want to continue?`,
          { icon: 'warning' },
        ))
      ) {
        return
      }

      const storageKey = 'batches.autoBatchPlants'
      const defaultNumPlants = localStorage.getItem(storageKey) || '100'

      const text = []

      text.push(
        id === 'new'
          ? 'This will add eligible orders to a new batch.'
          : `This will add eligible orders to batch ${name} (${
              numItems == null ? 'empty' : `${numItems} plants`
            }).`,
      )

      text.push(
        selectedRows.length
          ? `${selectedRows.length} selected orders will be considered.`
          : `All ${resultTotal} orders matching the current filter will be considered.`,
      )

      text.push('Please enter the maximum number of plants for this batch:')

      const MySwal = withReactContent(Swal)
      const ret = await MySwal.fire({
        html: (
          <div>
            {text.map((text, idx) => (
              <span key={idx}>
                {text}
                <br />
                <br />
              </span>
            ))}
            <span>
              <label>
                Allow invalid addresses:{' '}
                <input type="checkbox" id="swal-invalid-address-checkbox" />
              </label>
            </span>
          </div>
        ),
        input: 'text',
        inputValue: defaultNumPlants,
        showCancelButton: true,
        confirmButtonText: 'Create batch',
        showLoaderOnConfirm: true,
        preConfirm: async (value) => {
          localStorage.setItem(storageKey, value)

          if (numItems != null && Number(value) <= Number(numItems)) {
            Swal.showValidationMessage(
              `Batch ${name} already holds ${numItems} plants. Please select a larger limit.`,
            )
            return false
          }

          Swal.showLoading()

          try {
            const ret = await apiAction.performRequest({
              action: 'batches_autoBatch',
              batchId: id === 'new' ? 0 : id,
              maxPlants: value,
              orderIds: selectedRows,
              allowInvalidAddresses: (
                document.getElementById(
                  'swal-invalid-address-checkbox',
                ) as HTMLInputElement
              ).checked,
              useFilter: selectedRows.length === 0 ? 1 : 0,
              query,
              ...filters.requestProps,
              filterStatus,
            })

            return ret
          } catch (e) {
            Swal.showValidationMessage(e.message)
            return false
          }
        },
      })

      if (ret.value?.name) {
        Swal.fire({
          text:
            id === 'new'
              ? `Created new batch: ${ret.value.name}`
              : `Updated batch: ${ret.value.name}`,
          showCancelButton: false,
          showConfirmButton: false,
        })
        await sleep(2000)
        Swal.close()
      }

      if (onOrderModified) {
        onOrderModified()
      }
    },
    [
      apiAction,
      onOrderModified,
      selectedRows,
      resultTotal,
      filters,
      filterStatus,
      query,
    ],
  )

  const handleClickRemoveFromBatch = useCallback(async () => {
    if (
      !(await askQuestion(
        `Are you sure you want to remove ${selectedRows.length} orders from their assigned batches?`,
      ))
    ) {
      return
    }

    await apiAction.performRequest({
      action: 'batches_unassignOrders',
      json: { orderIds: selectedRows },
    })

    if (onOrderModified) {
      onOrderModified()
    }
  }, [apiAction, onOrderModified, selectedRows])

  const handleClickBlock = useCallback(async () => {
    await askQuestionWithReason(
      `Are you sure you want to block ${selectedRows.length} orders? Those orders will lose their batch association and will be excluded from auto-batch.`,
      {
        preConfirm: async (reason: string) => {
          try {
            await apiActionThrows.performRequest({
              action: 'batches_blockOrders',
              json: { orderIds: selectedRows, reason },
            })
          } catch (e) {
            Swal.showValidationMessage(e.message)
          }
        },
      },
    )

    if (onOrderModified) {
      onOrderModified()
    }
  }, [apiActionThrows, onOrderModified, selectedRows])

  const handleClickAutosplit = useCallback(async () => {
    if (
      !(await askQuestion(
        `Are you sure you want to autosplit ${selectedRows.length} orders?`,
      ))
    ) {
      return
    }

    await apiAction.performRequest({
      action: 'batches_autoSplitOrders',
      json: { orderIds: selectedRows },
    })

    if (onOrderModified) {
      onOrderModified()
    }
  }, [apiAction, onOrderModified, selectedRows])

  const handleClickUnblock = useCallback(async () => {
    await askQuestionWithReason(
      `Are you sure you want to unblock ${selectedRows.length} orders?`,
      {
        preConfirm: async (reason: string) => {
          try {
            await apiActionThrows.performRequest({
              action: 'batches_unblockOrders',
              json: { orderIds: selectedRows, reason },
            })
          } catch (e) {
            Swal.showValidationMessage(e.message)
          }
        },
      },
    )

    if (onOrderModified) {
      onOrderModified()
    }
  }, [apiActionThrows, onOrderModified, selectedRows])

  const canAdd = resultTotal > 0
  const isLoading =
    (apiLoad.isLoading && !apiLoad.hasResult) || apiAction.isLoading

  const isDisabled = apiAction.isLoading

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle
        nav
        caret={!isLoading}
        disabled={isDisabled}
        className={isDisabled ? 'disabled' : ''}
      >
        Batch {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu className="pre-scrollable">
        {canModifyBatches ? (
          <DropdownItem
            data-id="new"
            onClick={handleClickAssignTo}
            className={canAdd ? '' : 'disabled'}
          >
            Add to new batch
          </DropdownItem>
        ) : null}
        {canModifyBatches ? (
          <DropdownItem
            onClick={handleClickRemoveFromBatch}
            className={selectedRows.length ? '' : 'disabled'}
          >
            Remove from batch
          </DropdownItem>
        ) : null}
        {canModifyBatches || canBlockUnblockOrders ? (
          <DropdownItem
            onClick={handleClickBlock}
            className={selectedRows.length ? '' : 'disabled'}
          >
            Block
          </DropdownItem>
        ) : null}
        {canModifyBatches || canBlockUnblockOrders ? (
          <DropdownItem
            onClick={handleClickUnblock}
            className={selectedRows.length ? '' : 'disabled'}
          >
            Unblock
          </DropdownItem>
        ) : null}
        {canModifyBatches ? (
          <DropdownItem
            onClick={handleClickAutosplit}
            className={selectedRows.length ? '' : 'disabled'}
          >
            Automatic split
          </DropdownItem>
        ) : null}
        {canModifyBatches
          ? (apiLoad.result.rows || []).map((batch) => {
              return (
                <DropdownItem
                  key={batch.id}
                  data-id={batch.id}
                  data-name={batch.name}
                  data-numitems={batch.num_plants}
                  data-status={batch.status}
                  onClick={handleClickAssignTo}
                  className={canAdd ? '' : 'disabled'}
                  style={{ color: batch.status !== 'NEW' ? 'grey' : null }}
                >
                  Assign to {batch.name} {batch.status} ({batch.num_plants})
                </DropdownItem>
              )
            })
          : null}
      </DropdownMenu>
    </Dropdown>
  )
}

export default BatchActionButton
