const TagsCell = ({ value }) => {
  return (
    <td style={{ padding: '8px 0.75em 0px 0.75em' }}>
      {value.map((tag: { tag_id: string; label: string; color: string }) => {
        const { tag_id, label, color } = tag

        return (
          <div
            key={tag_id}
            title={label}
            className="mr-1 d-inline-block"
            style={{
              borderRadius: 5,
              width: 8,
              height: 20,
              background: color,
            }}
          />
        )
      })}
    </td>
  )
}

export default TagsCell
