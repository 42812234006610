import CheckboxField from 'components/CheckboxField'
import Loading from 'components/Loading'
import TextField from 'components/TextField'
import { useApi, usePageTitle } from 'hooks'
import { useCallback, useMemo, useReducer, useState } from 'react'
import { useNavigate } from 'react-router'
import Select from 'react-select'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap'
import shortid from 'shortid'
import { askQuestion } from 'utils'
import SelectTemplate from '../SelectTemplate'
import ConfigForm from './ConfigForm'

type SetTemplateRequest = {
  id: string
  title: string
  mail: string
  prio: number
  defaultAmountPercentage: number
  defaultPerItemShipping: number
  useNormalShipping: boolean
  defaultReason: string | null
}

type SetTemplateResult = void

type State = {
  template: null | string
  title: string
  text: string
  prio: number
  defaultAmountPercentage: number
  defaultPerItemShipping: number
  defaultReason: string | null
  useNormalShipping: boolean
}

const initialState: State = {
  template: null,
  title: '',
  text: '',
  prio: 0,
  defaultAmountPercentage: 0,
  defaultPerItemShipping: 0,
  defaultReason: null,
  useNormalShipping: false,
}

const reducer = (state: State, values: any) => {
  return { ...state, ...values }
}

const pageTitle = 'Configuration'

export const Config = () => {
  const navigate = useNavigate()

  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    title,
    template,
    text,
    prio,
    defaultAmountPercentage,
    defaultPerItemShipping,
    useNormalShipping,
  } = state
  const [reloadKey, setReloadKey] = useState(0)

  usePageTitle(pageTitle)

  const reloadTemplates = useCallback(() => {
    setReloadKey(reloadKey + 1)
  }, [reloadKey])

  // TODO(manuel, 2024-03-01) Update this
  const apiSave = useApi<SetTemplateResult, SetTemplateRequest>(
    { action: 'storeCredits_setTemplate' },
    null,
    {
      errorModal: true,
    },
  )

  const apiDelete = useApi({ action: 'storeCredits_deleteTemplate' }, null, {
    errorModal: true,
  })

  const apiGet = useApi({ action: 'storeCredits_getTemplate' }, null, {
    errorModal: true,
  })

  const getReasons = useApi<{ reasons: SelectOption[] }>(
    () => ({ action: 'storeCredits_getReasons' }),
    null,
    { autoPerform: true },
  )
  const reasonOptions = useMemo(
    () => getReasons.result?.reasons || [],
    [getReasons.result],
  )
  const defaultReason = useMemo(() => {
    const option = reasonOptions.find((o) => o.value === state.defaultReason)
    return option || null
  }, [reasonOptions, state.defaultReason])

  const handleClickCoupons = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      navigate('/store-credit')
    },
    [navigate],
  )

  const handleChangeTemplate = useCallback(
    async (_name: string, value: any) => {
      if (!value) {
        dispatch({ ...initialState })
        return
      }

      const ret = await apiGet.performRequest({ id: value ? value.value : '' })
      if (ret === false) {
        return
      }

      const {
        id,
        mail,
        prio,
        title,
        defaultPerItemShipping,
        defaultAmountPercentage,
        defaultReason,
        useNormalShipping,
      } = ret
      dispatch({
        template: id,
        title,
        prio,
        text: mail,
        defaultPerItemShipping,
        defaultAmountPercentage,
        defaultReason,
        useNormalShipping,
      })
    },
    [apiGet],
  )

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { type, name, value, checked } = e.currentTarget

      dispatch({ [name]: type === 'checkbox' ? checked : value })
    },
    [],
  )

  const handleChangeTextarea = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.currentTarget

      dispatch({ [name]: value })
    },
    [],
  )

  const handleChangeReason = useCallback((value: SelectOption | null) => {
    dispatch({ defaultReason: value ? value.value : null })
  }, [])

  const handleClickAdd = useCallback(() => {
    dispatch({
      ...initialState,
      template: 'new',
      title: '',
      prio: 0,
      text: 'Dear {{firstName}},\n\n\n\nThe Tree Center Team',
    })
  }, [])

  const handleClickDelete = useCallback(async () => {
    if (template === 'new') {
      dispatch({ ...initialState })
      return
    }

    if (await askQuestion('Are you sure you want to delete this template?')) {
      await apiDelete.performRequest({ id: template })
      dispatch({ ...initialState })
      reloadTemplates()
    }
  }, [reloadTemplates, apiDelete, template])

  const handleClickSave = useCallback(async () => {
    const id = template === 'new' ? shortid.generate() : template

    await apiSave.performRequest({
      id,
      title,
      mail: text,
      prio,
      defaultAmountPercentage,
      defaultPerItemShipping,
      useNormalShipping,
      defaultReason: defaultReason ? defaultReason.value : null,
    })

    if (template === 'new') {
      dispatch({ template: id })
    }

    reloadTemplates()
  }, [
    reloadTemplates,
    apiSave,
    template,
    title,
    text,
    prio,
    defaultPerItemShipping,
    defaultAmountPercentage,
    defaultReason,
    useNormalShipping,
  ])

  const canSave = title !== '' && text !== ''

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="#" onClick={handleClickCoupons}>
            Manage Store Credits
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Configuration</BreadcrumbItem>
      </Breadcrumb>
      <Container fluid>
        <div className="mt-4 addorder animated fadeIn">
          <Row style={{ maxWidth: 1200, minHeight: 1600 }}>
            <Col className="pr-4">
              <h5 className="mb-4">E-Mail Templates</h5>
              <div className="floating-labels">
                {template !== 'new' ? (
                  <SelectTemplate
                    key={reloadKey}
                    autoSelectFirst={false}
                    className="mb-2"
                    name="template"
                    value={template}
                    onChange={handleChangeTemplate}
                  />
                ) : null}
                {template != null ? (
                  <>
                    <TextField
                      name="title"
                      value={title}
                      onChange={handleChangeInput}
                      id="input-coupon-title"
                      label="Subject"
                      required
                    />
                    <TextField
                      name="prio"
                      value={prio}
                      onChange={handleChangeInput}
                      id="input-coupon-prio"
                      label="Priority"
                      required
                    />
                    <TextField
                      isPercentage
                      name="defaultAmountPercentage"
                      value={defaultAmountPercentage}
                      onChange={handleChangeInput}
                      id="input-amount-default-percentage"
                      label="Default Credit Amount Percentage"
                    />
                    <CheckboxField
                      name="useNormalShipping"
                      label="Use Normal Shipping"
                      checked={useNormalShipping}
                      onChange={handleChangeInput}
                      id="input-use-normal-shipping"
                    />
                    <TextField
                      isNumeric
                      name="defaultPerItemShipping"
                      value={defaultPerItemShipping}
                      onChange={handleChangeInput}
                      id="input-default-per-item-shipping"
                      label="Default Per Item Shipping"
                    />
                    <Select<SelectOption>
                      className="mt-2 mb-2"
                      name="defaultReason"
                      isClearable
                      escapeClearsValue
                      placeholder="Default internal reason"
                      value={defaultReason}
                      options={reasonOptions}
                      onChange={handleChangeReason}
                    />
                    <textarea
                      name="text"
                      style={{
                        width: '100%',
                        height: 450,
                        padding: 10,
                        borderRadius: 5,
                        border: '1px solid #ccc',
                      }}
                      value={text}
                      placeholder="Enter mail text"
                      onChange={handleChangeTextarea}
                      required
                    />
                    <div className="mt-2 mb-2 text-right">
                      <Button
                        className="float-left"
                        disabled={Boolean(apiDelete.isLoading)}
                        color="danger"
                        onClick={handleClickDelete}
                      >
                        <i className="fa fa-trash mr-2" />
                        {template === 'new' ? 'Cancel' : 'Delete'}
                      </Button>
                      <Button
                        disabled={!canSave || Boolean(apiSave.isLoading)}
                        color="primary"
                        onClick={handleClickSave}
                      >
                        <i className="fa fa-save mr-2" />
                        Save
                      </Button>
                    </div>
                    {apiSave.isLoading ? <Loading /> : null}
                  </>
                ) : (
                  <Button color="primary" onClick={handleClickAdd}>
                    <i className="fa fa-plus mr-2" />
                    Add New Template
                  </Button>
                )}
              </div>
            </Col>
            <Col>
              <h5 className="mb-4">Defaults</h5>
              <div className="floating-labels">
                <ConfigForm />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default Config
