import { SHIPPINGEMPLOYEES_READWRITE } from 'caps'
import { useContext } from 'react'
import AuthContext from './../../AuthContext'
import ManageEmployees from './../ManageShippingEmployees/ManageEmployees'

const ManageShippingEmployees = () => {
  const { hasCap } = useContext(AuthContext)
  const canEdit = hasCap(SHIPPINGEMPLOYEES_READWRITE)

  const pageTitle = 'Manage Shipping Employees'

  return (
    <ManageEmployees
      hasCapabilities={false}
      storageKey="manageShippingEmployees"
      userRole="treeadmin-shipping"
      {...{ pageTitle, canEdit }}
    />
  )
}

export default ManageShippingEmployees
