import { PageNavHead, PageNavTail } from 'components'
import {
  Table,
  defaultCellRenderer,
  setSelectedRows,
  useTableApi,
  useTableWithUrlUpdater,
} from 'components/Table'
import { usePageTitle } from 'hooks'
import { useBoolean, usePanelControl } from 'hooks'
import { useCallback, useMemo } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'
import EditDiscountPanel from './EditDiscountPanel'
import EditBogoPanel from './EditBogoPanel'
import columnDef from './columnDef'
import LogPanel from './LogPanel'

const pageTitle = 'Manage Discounts'

const cacheKey = 'manageDiscounts'
const [setItem, getItem] = getStorage(cacheKey)

const ManageDiscounts = ({ readOnly }: { readOnly: boolean }) => {
  const [state, dispatch] = useTableWithUrlUpdater({
    getItem,
    setItem,
    cacheKey,
  })
  const { isLoading, selectedRowId, selectedRows, rows } = state
  const [triggerSearch] = useTableApi('discounts_getAll', state, dispatch, {
    withQty: true,
    limit: 1000,
  })

  const selectedDiscountRowIds = useMemo(
    () =>
      rows
        .filter(
          (row) => selectedRows.includes(row.id) && row.type === 'QUANTITY',
        )
        .map((row) => row.id),
    [selectedRows, rows],
  )

  const selectedBogoRowIds = useMemo(
    () =>
      rows
        .filter((row) => selectedRows.includes(row.id) && row.type === 'BOGO')
        .map((row) => row.id),
    [selectedRows, rows],
  )

  const logPanel = usePanelControl()

  usePageTitle(pageTitle)

  const editDiscountPanel = usePanelControl({
    selectedRows: selectedDiscountRowIds,
    openForSingleRow: true,
  })
  const editBogoPanel = usePanelControl({
    selectedRows: selectedBogoRowIds,
    openForSingleRow: true,
  })

  const addDiscount = useBoolean(false)
  const addBogo = useBoolean(false)

  const handleClickAdd = useCallback(() => {
    addDiscount.on()
    editDiscountPanel.open()
  }, [addDiscount, editDiscountPanel])

  const handleClickAddBogo = useCallback(() => {
    addBogo.on()
    editBogoPanel.open()
  }, [addBogo, editBogoPanel])

  const handleClosePanel = useCallback(() => {
    editDiscountPanel.close()
    editBogoPanel.close()
    addDiscount.off()
    dispatch(setSelectedRows([]))
    triggerSearch()
  }, [dispatch, editDiscountPanel, editBogoPanel, addDiscount, triggerSearch])

  const renderCell = useCallback((props) => {
    const { col, row } = props

    if (col.id === 'name') {
      return (
        <td>
          {row.status === 'ACTIVE' ? (
            <i className="fa fa-check-circle text-success pr-2" />
          ) : (
            <i className="fa fa-times-circle text-danger pr-2" />
          )}
          {row.name}
          {row.status === 'INACTIVE' ? <> (inactive)</> : null}
        </td>
      )
    }
    if (col.id === 'configuration') {
      return (
        <td>
          {Object.keys(row.items).map((quantity) => (
            <div key={quantity}>
              {quantity} Pack → {row.items[quantity]}% off
            </div>
          ))}
        </td>
      )
    }

    return defaultCellRenderer(props)
  }, [])

  const handleClickLog = useCallback(() => {
    logPanel.open()
  }, [logPanel])

  return (
    <>
      <PageNavHead {...{ isLoading, pageTitle, onClickReload: triggerSearch }}>
        <div style={{ marginLeft: 'auto' }}>
          <Button className="mr-2" onClick={handleClickLog}>
            Log
          </Button>
          {!readOnly ? (
            <Button className="mr-2" onClick={handleClickAdd}>
              Add Quantity Discount
            </Button>
          ) : null}
          {!readOnly ? (
            <Button className="mr-2" onClick={handleClickAddBogo}>
              Add BOGO Discount
            </Button>
          ) : null}
        </div>
      </PageNavHead>
      <PageNavTail {...{ isLoading }} />
      <Container fluid className="mt-4">
        <div className="animated fadeIn">
          <LogPanel isOpen={logPanel.isOpen} onClose={logPanel.close} />
          <EditDiscountPanel
            {...{ readOnly }}
            editId={addDiscount.value ? null : selectedRowId}
            isOpen={editDiscountPanel.isOpen}
            onClose={handleClosePanel}
          />
          <EditBogoPanel
            {...{ readOnly }}
            editId={addDiscount.value ? null : selectedRowId}
            isOpen={editBogoPanel.isOpen}
            onClose={handleClosePanel}
          />
          <Row>
            <Col>
              <Table
                entityName="discounts"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                  hasPagination: false,
                  renderCell,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ManageDiscounts
