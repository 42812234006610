import { defaultHeaderLabelRenderer } from './defaultHeaderLabelRenderer'
import type { Row } from './reducer/types'
import type { ColumnDef } from './types'

export type TableHeaderRenderProps = {
  col: ColumnDef
  allRowsSelected: boolean
  handleClickToggleAll: () => void
}

type TableHeaderProps = {
  renderHeaderLabel: (props: TableHeaderRenderProps) => React.ReactNode
  sortColumn: string
  sortAsc: boolean
  columnDef: ColumnDef<Row>[]
  onClickSort: (column: string) => void
  allRowsSelected: boolean
  handleClickToggleAll: () => void
}

const TableHeader = (props: TableHeaderProps) => {
  const {
    renderHeaderLabel,
    sortColumn,
    sortAsc,
    columnDef,
    onClickSort,
    allRowsSelected,
    handleClickToggleAll,
  } = props

  const handleClickColumnHeader = (
    e: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    if (!e.target || !onClickSort) {
      return
    }

    const th = (e.target as HTMLElement).closest('th')
    const column = th.getAttribute('data-column')

    if (column) {
      onClickSort(column)
    }
  }

  return (
    <tr>
      {columnDef.map((col) => {
        const className = col.thClassName || ''
        const renderProps = { col, allRowsSelected, handleClickToggleAll }
        const label = renderHeaderLabel
          ? renderHeaderLabel(renderProps)
          : defaultHeaderLabelRenderer(renderProps)

        if (col.sortColumn) {
          const colSortColumn =
            col.sortColumn === true ? col.id : col.sortColumn
          const isSorting = sortColumn === colSortColumn
          const icon = isSorting ? `sort-${sortAsc ? 'asc' : 'desc'}` : 'sort'

          return (
            <th
              key={col.id}
              onClick={handleClickColumnHeader}
              data-column={col.id}
              className={`sort-column ${
                isSorting ? 'sorting' : ''
              } ${className}`}
              style={col.thStyle}
              title={col.title}
            >
              {label}
              <span>
                <i className={`fa fa-${icon} ml-2`} />
              </span>
            </th>
          )
        }

        return (
          <th
            title={col.title}
            style={col.thStyle}
            className={className}
            key={col.id}
          >
            {label ? label : null}
          </th>
        )
      })}
    </tr>
  )
}

export default TableHeader
