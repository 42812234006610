import { EditPanel, EditPanelCard, FormRenderer, Loading } from 'components'
import { useApi, useFormWithDef, usePrevious } from 'hooks'
import type { ApiCall } from 'hooks'
import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import { getFormDef, getFormFields } from './formDef'

type AddressEditor = {
  addressType: string
  getCustomer: ApiCall
  isOpen: boolean
  onClose: () => void
  editId: string
  isLoading: boolean
}

const AddressEditor = (props: AddressEditor) => {
  const { addressType, getCustomer, isOpen, onClose, editId } = props

  const formDef = useMemo(() => getFormDef(addressType), [addressType])

  const form = useFormWithDef(formDef)

  // Fill form as soon as panel opens.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      if (isOpen && getCustomer.result != null) {
        const submitState = []

        const formFields = getFormFields(formDef)

        for (const field of formFields) {
          submitState[field] = getCustomer.result[field]
        }

        form.setState(submitState)
      } else if (!isOpen) {
        form.reset()
      }
    }
  }, [form, prevIsOpen, isOpen, getCustomer.result, formDef])

  const updateCustomer = useApi({ action: 'customers_update' })

  const handleSubmit = useCallback(() => {
    const formSubmit = async () => {
      const ret = await updateCustomer.performRequest({
        id: editId,
        ...form.state,
      })

      if (ret) {
        onClose()
      }
    }

    formSubmit()
  }, [editId, form.state, onClose, updateCustomer])

  const isLoading = getCustomer.isLoading || updateCustomer.isLoading

  const handleClickCopyFrom = useCallback(
    (copyFromAddressType: string, e: React.MouseEvent) => {
      e.preventDefault()

      const copyFromDef = getFormDef(copyFromAddressType)
      const copyFromFormFields = getFormFields(copyFromDef)

      const fields = getFormFields(formDef)

      const newState = { ...form.state }

      for (const i in copyFromFormFields) {
        if (Number(i) > fields.length - 1) {
          continue
        }

        const srcField = copyFromFormFields[i]
        const srcValue = getCustomer.result[srcField]
        const targetField = fields[i]

        newState[targetField] = srcValue
      }

      form.setState(newState)
    },
    [formDef, form, getCustomer.result],
  )

  return (
    <EditPanel
      caption="Customer Info"
      onSubmit={isLoading ? null : handleSubmit}
      {...{ isOpen, onClose, isLoading }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment key={editId}>
          <EditPanelCard caption="Edit Customer Info" stateId="customer_info">
            {updateCustomer.error ? (
              <Row>
                <Col className="text-danger">{updateCustomer.error}</Col>
              </Row>
            ) : null}
            <div>
              {addressType === 'shipping' ? (
                <a href="#" onClick={(e) => handleClickCopyFrom('billing', e)}>
                  Copy from billing
                </a>
              ) : null}
              {addressType === 'billing' ? (
                <a href="#" onClick={(e) => handleClickCopyFrom('shipping', e)}>
                  Copy from shipping
                </a>
              ) : null}
            </div>
            <FormRenderer {...{ form, formDef }} />
          </EditPanelCard>
        </Fragment>
      )}
    </EditPanel>
  )
}

export default AddressEditor
