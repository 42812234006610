import AuthContext from 'AuthContext'
import { apiRequest } from 'api'
import { MANAGESTORECREDITTEMPLATES_READWRITE } from 'caps'
import { FilterBar, PageNavHead, PageNavTail } from 'components'
import {
  Table,
  setCellLoading,
  setEditCell,
  setRow,
  useTableApi,
  useTableColumns,
  useTableWithUrlUpdater,
} from 'components/Table'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import {
  useApi,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdaterWithTableSync,
  usePageTitle,
  usePanelControl,
  useSidebar,
} from 'hooks'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { Button, Col, Container, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { getStorage } from 'utils/storage'
import Filters from './Filters'
import type { ManageStoreCreditFiltersType } from './Filters/types'
import Sidebar from './Sidebar'
import columnDef from './columnDef'
import { askQuestionWithReason } from 'utils/alert'

const visibleFilters = ['q', 'reason']

const Alert = withReactContent(Swal)

type ManageStoreCreditsProps = {
  readOnly: boolean
  storageKey: string
  pageTitle: string
}

const ManageStoreCredits = (props: ManageStoreCreditsProps) => {
  const navigate = useNavigate()

  const { hasCap } = useContext(AuthContext)

  const { readOnly, storageKey, pageTitle } = props

  const [setItem, getItem] = useMemo(() => getStorage(storageKey), [storageKey])

  // date

  const [state, dispatch] = useTableWithUrlUpdater({
    getItem,
    setItem,
    cacheKey: storageKey,
  })
  const { isLoading, selectedRows } = state

  const filters = useFiltersWithUrlUpdaterWithTableSync(
    'manageStoreCreditFilters',
    visibleFilters,
    state,
    dispatch,
  ) as ManageStoreCreditFiltersType

  const query = filters.searchQueryString

  const columns = useTableColumns(
    'manageStoreCredit',
    state,
    dispatch,
    columnDef,
  )

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  const requestParams = useMemo(
    () => ({
      query: '',
      ...filters.requestProps,
      columns: columns.visibleColumnsIds,
    }),
    [filters.requestProps, columns.visibleColumnsIds],
  )

  const [triggerSearch] = useTableApi(
    'storeCredits_getAll',
    state,
    dispatch,
    requestParams,
    { autoReloadInterval: 2 * 60 },
  )
  useEffectIfObjectChanges(triggerSearch, requestParams)

  const manageColumnsPanel = usePanelControl()

  const sidebar = useSidebar({
    openForSingleRow: true,
    openForMultiRows: false,
    selectedRows,
  })

  const selectedId = selectedRows.length === 1 ? selectedRows[0] : null

  usePageTitle(pageTitle)

  const getStoreCredit = useApi(
    useMemo(
      () => ({
        action: 'storeCredits_get',
        json: {
          id: selectedId,
          withLog: true,
        },
      }),
      [selectedId],
    ),
    null,
    { autoPerform: sidebar.isOpen && selectedId != null },
  )

  const handleSidebarUpdate = useCallback(() => {
    triggerSearch()
    getStoreCredit.performRequest()
  }, [triggerSearch, getStoreCredit])

  const handleChangeCell = useCallback(
    async ({ value, colId, rowId }) => {
      if (colId === 'amount') {
        try {
          const reason = await askQuestionWithReason(
            `Change amount of store credit to ${value}?`,
          )

          if (!reason) {
            return
          }

          const response = await apiRequest({
            action: 'storeCredits_updateAmount',
            amount: value,
            reason,
            id: rowId,
          })

          dispatch(setRow(rowId, { ...response }))
          await getStoreCredit.performRequest()
        } catch (e) {
          dispatch(setCellLoading(null))

          await Alert.fire({
            icon: 'error',
            title: <p>{e.message}</p>,
          })
        }
      } else if (colId === 'balance') {
        try {
          const reason = await askQuestionWithReason(
            `Change balance of store credit to ${value}?`,
          )

          if (!reason) {
            return
          }

          const response = await apiRequest({
            action: 'storeCredits_updateBalance',
            balance: value,
            reason,
            id: rowId,
          })

          dispatch(setRow(rowId, { ...response }))
          await getStoreCredit.performRequest()
        } catch (e) {
          dispatch(setCellLoading(null))

          await Alert.fire({
            icon: 'error',
            title: <p>{e.message}</p>,
          })
        }
      }
    },
    [dispatch, getStoreCredit],
  )

  const handleClickCell = useCallback(
    (rowId, colId) => {
      if (colId === 'amount' || colId === 'balance') {
        dispatch(setEditCell(rowId, colId))
      }
      return false
    },
    [dispatch],
  )

  const handleClickConfig = useCallback(() => {
    navigate('/store-credit/config')
  }, [navigate])

  return (
    <>
      <PageNavHead
        {...{
          isLoading,
          pageTitle,
          onClickReload: triggerSearch,
        }}
      >
        {!sidebar.isOpen ? (
          <div className="actions" style={{ marginRight: '2rem' }}>
            {hasCap(MANAGESTORECREDITTEMPLATES_READWRITE) ? (
              <Button onClick={handleClickConfig}>Configuration</Button>
            ) : null}
          </div>
        ) : null}
        {sidebar && !sidebar.isOpen ? (
          <div style={{ marginLeft: 'auto' }}>
            <Button color="link" onClick={sidebar.userToggle}>
              <i className="icon-list" />
            </Button>
          </div>
        ) : null}
      </PageNavHead>
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
        }}
      />
      <FilterBar
        {...{
          filters,
          manageColumnsPanel,
          columns,
        }}
      >
        <Filters {...{ filters }} />
      </FilterBar>
      <Container fluid>
        <div className="mt-4 manage-inventory animated fadeIn">
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <Row>
            <Col>
              <Table
                entityName="store credit"
                onClickCell={handleClickCell}
                onChangeCell={handleChangeCell}
                renderRowClassName={({ row }) => {
                  if (row.cancelled_at !== null) {
                    return 'cancelled'
                  }
                  return ''
                }}
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Sidebar
        {...{
          readOnly,
          selectedRows,
          getStoreCredit,
          onToggle: sidebar.userToggle,
          onUpdate: handleSidebarUpdate,
        }}
      />
    </>
  )
}

export default ManageStoreCredits
