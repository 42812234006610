import { apiFetch } from 'api'
import { useCallback, useState } from 'react'
import { showError } from 'utils'

const InvoiceDropZone = (props: { id: number; onUploadDone: () => void }) => {
  const { id, onUploadDone } = props
  const [isDragOver, setIsDragOver] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      const { dataTransfer } = e

      e.preventDefault()
      setIsDragOver(false)

      const promises = []

      if (dataTransfer?.items) {
        for (let i = 0; i < dataTransfer.items.length; i++) {
          const item = dataTransfer.items[i]

          if (item.kind === 'file') {
            const file = item.getAsFile()

            const url = `${global.appConfig.wpBaseUrl}/wp-admin/admin-ajax.php`
            const formData = new FormData()
            formData.append('action', 'treeadmin_purchaseOrders_uploadInvoice')
            formData.append('id', String(id))
            formData.append('file', file)

            promises.push(apiFetch(url, formData, false))
          }
        }
      }

      try {
        setIsLoading(true)
        await Promise.all(promises)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        showError(e.message)
      }

      if (onUploadDone) {
        onUploadDone()
      }
    },
    [id, onUploadDone],
  )

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    setIsDragOver(true)
  }, [])

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    setIsDragOver(false)
  }, [])

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      className={`file-upload-dropzone d-flex ${isDragOver ? 'drag-over' : ''}`}
    >
      <div>
        <i className={`m-3 fa fa-${isDragOver ? 'check' : 'upload'}`} />
      </div>
      <div className="pl-4">
        {isLoading ? (
          <>Uploading...</>
        ) : (
          <>
            Upload Invoices <br />
            (Drag Files Here)
          </>
        )}
      </div>
    </div>
  )
}

export default InvoiceDropZone
