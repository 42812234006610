import keyBy from 'lodash/keyBy'
import {
  MOVE_ITEM,
  RESET_COLUMNS,
  RESET_COLUMN_COLORS,
  RESET_TABLE,
  SELECT_ROW_RANGE,
  SET_CELL_LOADING,
  SET_CELL_LOADING_DONE,
  SET_CELL_VALUE,
  SET_COLUMN_COLOR,
  SET_EDIT_CELL,
  SET_ERROR,
  SET_INITIAL_SELECTED_ROWS,
  SET_LOADING,
  SET_NEXT_PAGE,
  SET_NEXT_ROW,
  SET_PAGINATION_LIMIT,
  SET_PAGINATION_PAGE,
  SET_PRESET,
  SET_PREV_PAGE,
  SET_PREV_ROW,
  SET_QUERY,
  SET_RESPONSE,
  SET_ROW,
  SET_ROW_ORDER,
  SET_SELECTED_ROW,
  SET_SELECTED_ROWS,
  SET_SORTED_COLUMNS,
  SET_SORT_ASC,
  SET_SORT_COLUMN,
  SET_TABLE,
  SET_VISIBLE_COLUMNS,
  TOGGLE_SELECTED_ROW,
  columnColors,
} from './actions'
import type { Action, BaseRow, State } from './types'

const rowsReducer = <RowType extends BaseRow>(
  state: RowType[],
  action: Action<RowType>,
) => {
  switch (action.type) {
    case SET_ROW: {
      const { rowId } = action

      return state.map((row: RowType) => {
        if (row.id === rowId) {
          const rowData = { ...action.row }

          /* Always cast row.id to String */
          rowData.id = String(rowData.id)

          return { ...rowData }
        }

        return row
      })
    }
    case SET_CELL_VALUE: {
      const { rowId, colId, value } = action

      return state.map((row: RowType) => {
        if (row.id === rowId) {
          return {
            ...row,
            [colId]: value,
          }
        }

        return row
      })
    }
    default:
      return state
  }
}

function _reducer<RowType extends BaseRow>(
  state: State<RowType>,
  action: Action<RowType>,
) {
  switch (action.type) {
    case SET_VISIBLE_COLUMNS: {
      const { columns } = action
      return {
        ...state,
        visibleColumns: columns,
      }
    }
    case SET_SORTED_COLUMNS: {
      const { columns } = action
      return {
        ...state,
        sortedColumns: columns,
      }
    }
    case SET_COLUMN_COLOR: {
      return {
        ...state,
        columnColors: columnColors(state.columnColors, action),
      }
    }
    case RESET_COLUMN_COLORS: {
      return {
        ...state,
        columnColors: {},
      }
    }
    case RESET_COLUMNS: {
      return {
        ...state,
        visibleColumns: [],
        sortedColumns: [],
      }
    }
    case TOGGLE_SELECTED_ROW: {
      const { rowId } = action
      const hasRow = state.selectedRows.includes(rowId)
      const selectedRows = hasRow
        ? state.selectedRows.filter((r: string) => r !== rowId)
        : [...state.selectedRows, rowId]
      return {
        ...state,
        selectedRows,
      }
    }
    case SELECT_ROW_RANGE: {
      const { rowId } = action

      const collectRows = (rows: RowType[]) => {
        const rowIds = []
        let found = false
        for (const row of rows) {
          if (state.selectedRows.includes(row.id)) {
            rowIds.push(row.id)
            found = true
            continue
          }

          if (row.id === rowId) {
            rowIds.push(row.id)
            break
          }

          if (found) {
            rowIds.push(row.id)
          }
        }

        if (!found) {
          return false
        }

        return rowIds
      }

      let rowIds = collectRows(state.rows)
      if (rowIds === false) {
        rowIds = collectRows(state.rows.slice().reverse())
        if (rowIds === false) {
          return { ...state }
        }
      }

      return {
        ...state,
        selectedRows: rowIds,
      }
    }
    case SET_NEXT_PAGE: {
      const totalPages = Math.max(
        1,
        state.resultLimit > 0
          ? Math.ceil(state.resultTotal / state.resultLimit)
          : 1,
      )

      if (state.paginationPage >= totalPages) {
        return state
      }

      return {
        ...state,
        paginationPage: state.paginationPage + 1,
      }
    }
    case SET_PREV_PAGE: {
      if (state.paginationPage <= 1) {
        return state
      }

      return {
        ...state,
        paginationPage: state.paginationPage - 1,
      }
    }
    case SET_NEXT_ROW: {
      if (!state.rows.length) {
        return state
      }

      if (state.selectedRows.length === 0) {
        return {
          ...state,
          selectedRows: [state.rows[0].id],
        }
      }

      const lastSelectedRowId =
        state.selectedRows[state.selectedRows.length - 1]

      const idx = state.rows.findIndex((row) => row.id === lastSelectedRowId)
      if (idx === -1) {
        return
      }

      const row = state.rows.at(idx + 1)
      if (row) {
        return {
          ...state,
          selectedRows: [row.id],
        }
      }

      return state
    }
    case SET_PREV_ROW: {
      if (!state.rows.length) {
        return state
      }

      if (state.selectedRows.length === 0) {
        return state
      }

      const firstSelectedRowId = state.selectedRows[0]

      const idx = state.rows.findIndex((row) => row.id === firstSelectedRowId)
      if (idx === -1) {
        return
      }

      if (idx === 0) {
        return state
      }

      const row = state.rows.at(idx - 1)
      if (row) {
        return {
          ...state,
          selectedRows: [row.id],
        }
      }

      return state
    }
    case SET_INITIAL_SELECTED_ROWS: {
      const { rowIds } = action
      return {
        ...state,
        initialSelectedRows: rowIds.map((id: any) => String(id)),
      }
    }
    case SET_SELECTED_ROWS: {
      const { rowIds } = action
      return {
        ...state,
        selectedRows: rowIds.map((id: any) => String(id)),
      }
    }
    case SET_SELECTED_ROW: {
      const { rowId } = action
      return {
        ...state,
        selectedRows: [String(rowId)],
      }
    }
    case SET_RESPONSE: {
      return {
        ...state,
        response: action.response,
      }
    }
    case SET_TABLE: {
      const { offset, total, limit } = action

      /* Always cast row.id to String */
      const rows = action.rows.map((row: RowType) => ({
        ...row,
        id: String(row.id),
      }))

      return {
        ...state,
        isLoading: false,
        didLoad: true,
        rows,
        resultTotal: total,
        resultOffset: offset,
        resultLimit: limit,
        error: null,
        selectedRows:
          state.initialSelectedRows.length > 0
            ? state.initialSelectedRows
            : state.selectedRows,
        initialSelectedRows: [],
      }
    }
    case RESET_TABLE: {
      return {
        ...state,
        isLoading: false,
        didLoad: false,
        rows: [],
        resultTotal: 0,
        resultOffset: 0,
        resultLimit: 0,
        error: null,
      }
    }
    case SET_PAGINATION_PAGE: {
      const { page } = action
      return {
        ...state,
        paginationPage: page,
      }
    }
    case SET_PAGINATION_LIMIT: {
      const { limit } = action
      return {
        ...state,
        paginationLimit: limit,
      }
    }
    case SET_SORT_COLUMN: {
      const { column } = action
      return {
        ...state,
        sortColumn: column,
        sortAsc: false,
      }
    }
    case SET_SORT_ASC: {
      const { asc } = action
      return {
        ...state,
        sortAsc: asc,
      }
    }
    case SET_LOADING: {
      const { isLoading } = action
      if (isLoading) {
        return {
          ...state,
          isLoading,
          error: null,
        }
      }
      return {
        ...state,
        isLoading,
      }
    }
    case SET_ERROR: {
      const { error } = action
      return {
        ...state,
        isLoading: false,
        error,
      }
    }
    case SET_QUERY: {
      const { query } = action

      return {
        ...state,
        query,
        paginationPage: 1,
      }
    }
    case SET_EDIT_CELL: {
      const { rowId, colId } = action
      const editCell =
        rowId != null && colId != null
          ? {
              rowId,
              colId,
            }
          : null
      return {
        ...state,
        editCell,
      }
    }
    case SET_CELL_LOADING: {
      const { rowId, colId, value } = action

      if (rowId == null) {
        return { ...state, cellsLoading: {} }
      }

      const cellLoading = { rowId, colId, value }
      const cells = state.cellsLoading[rowId] || {}

      return {
        ...state,
        cellsLoading: {
          ...state.cellsLoading,
          [rowId]: { ...cells, [colId]: cellLoading },
        },
      }
    }
    case SET_CELL_LOADING_DONE: {
      const { rowId, colId } = action

      if (!Object.hasOwn(state.cellsLoading, rowId)) {
        return state
      }

      if (!Object.hasOwn(state.cellsLoading[rowId], colId)) {
        return state
      }

      const cells = Object.fromEntries(
        Object.entries(state.cellsLoading[rowId]).filter(
          ([key]) => key !== colId,
        ),
      )

      if (Object.keys(cells).length === 0) {
        const { [rowId]: _, ...rest } = state.cellsLoading
        return {
          ...state,
          cellsLoading: rest,
        }
      }

      return {
        ...state,
        cellsLoading: { ...state.cellsLoading, [rowId]: cells },
      }
    }
    case SET_ROW:
    case SET_CELL_VALUE: {
      return {
        ...state,
        rows: rowsReducer(state.rows, action),
      }
    }
    case MOVE_ITEM: {
      const rows = [...state.rows]

      rows.splice(action.hoverIndex, 0, rows.splice(action.dragIndex, 1)[0])

      return {
        ...state,
        rows,
      }
    }
    case SET_ROW_ORDER: {
      if (action.rowIds.length !== state.rows.length) {
        return state
      }

      const rowsById = keyBy(state.rows, 'id')
      const rows = action.rowIds.map((rowId: string) => rowsById[rowId])

      return {
        ...state,
        rows,
      }
    }
    case SET_PRESET: {
      return {
        ...state,
        visibleColumns: action.visibleColumns,
        sortedColumns: action.sortedColumns,
      }
    }
    default:
      return state
  }
}

export function reducer<RowType extends BaseRow>(
  state: State<RowType>,
  action: Action<RowType>,
) {
  const ret = _reducer(state, action)

  const rowIds = ret.rows.map((row: RowType) => String(row.id))
  const selectedRows = ret.selectedRows.filter((id: string) =>
    rowIds.includes(String(id)),
  )
  const allRowsSelected =
    ret.rows.length > 0 && selectedRows.length === ret.rows.length
  const selectedRowId = selectedRows.length === 1 ? selectedRows[0] : null

  return {
    ...ret,
    selectedRows,
    allRowsSelected,
    selectedRowId,
  }
}
