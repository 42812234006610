import useStateful from 'hooks/useStateful'
import pickBy from 'lodash/pickBy'
import { useCallback, useMemo } from 'react'
import { ucfirst } from 'utils/strings'

const EMPTY_OBJECT = {}

/**
 * `useFilters` is a custom hook designed to manage filters for a table view. It takes four parameters:
 * - `storagePrefix`: A string used as a prefix for storing filter values in local storage.
 * - `visibleFilters`: An array of strings representing the filters that are currently visible.
 * - `staticFilters`: An array of strings representing filters that cannot be removed.
 * - `loadFromStorage`: A boolean indicating whether to load filter values from local storage.
 *
 * The hook also provides:
 * - `requestProps`: An object that can be used to make a request with the current filter values.
 * - `filters`: An object that contains all the filters and their utility functions.
 *
 * `useFilters` utilizes localStorage for persisting filter values across sessions.
 * `setFilterValues` and filter's `set` updates the last value in `localStorage`.
 * `addFilter` restores values from the last value, but does not modify it.
 */
export function useFilters(
  storagePrefix: string,
  visibleFilters: string[],
  staticFilters: string[],
  loadFromStorage = true,
): FiltersType {
  const state = useStateful(EMPTY_OBJECT, storagePrefix, loadFromStorage)

  const clear = useCallback(() => {
    state.set(pickBy(state.value, (_value, key) => staticFilters.includes(key)))
  }, [state, staticFilters])

  const requestProps = useMemo(() => {
    const props = {}

    for (const key of Object.keys(state.value)) {
      let filterName = `filter${ucfirst(key)}`

      if (key === 'q') {
        filterName = 'query'
      }

      const values = state.value[key].map((o: string | { value: string }) =>
        o && typeof o === 'object' ? o.value : o,
      )

      props[filterName] = values.join(',')
    }

    return props
  }, [state])

  const isEmpty = useMemo(() => {
    for (const key of Object.keys(state.value)) {
      if (staticFilters.includes(key)) {
        continue
      }

      if (state.value[key] && state.value[key].length > 0) {
        return false
      }
    }

    return true
  }, [state, staticFilters])

  const has = useCallback(
    (filter: string) => {
      return Object.hasOwn(state.value, filter)
    },
    [state],
  )

  const addFilter = useCallback(
    (filter: string, initValues: string[] = []) => {
      let value = initValues

      try {
        const restored = localStorage.getItem(
          `${storagePrefix}.lastValue.${filter}`,
        )
        const restoredArr = JSON.parse(restored)
        if (Array.isArray(restoredArr)) {
          value = restoredArr
        }
      } catch (_e) {
        /* Ignore */
      }

      state.set({
        ...(state.value as object),
        [filter]: value,
      })
    },
    [state, storagePrefix],
  )

  const removeFilter = useCallback(
    (filter: string) => {
      const filters = { ...(state.value as object) }
      delete filters[filter]
      state.set(filters)
    },
    [state],
  )

  const getFilterValues = useCallback(
    (filter: string) => {
      return state.value[filter] ?? []
    },
    [state],
  )

  const toggleFilterValue = useCallback(
    (filter: string, value: string) => {
      const newState = { ...(state.value as object) }

      if (!Array.isArray(newState[filter])) {
        newState[filter] = []
      }

      if (newState[filter].includes(value)) {
        newState[filter] = newState[filter].filter(
          (item: any) => item !== value,
        )
      } else {
        newState[filter] = [...newState[filter], value]
      }

      state.set(newState)
    },
    [state],
  )

  const setFilterValues = useCallback(
    (filter: string, values: any) => {
      const newState = { ...(state.value as object) }
      if (Array.isArray(values) && values.length === 0) {
        delete newState[filter]
      } else {
        newState[filter] = values
      }

      try {
        localStorage.setItem(
          `${storagePrefix}.lastValue.${filter}`,
          JSON.stringify(values),
        )
      } catch (_e) {
        /* Ignore */
      }

      state.set(newState)
    },
    [state, storagePrefix],
  )

  const removeFilterValue = useCallback(
    (filter: string, value: any) => {
      const newState = { ...(state.value as object) }

      if (
        Object.hasOwn(newState, filter) &&
        Array.isArray(newState[filter]) &&
        newState[filter].includes(value)
      ) {
        newState[filter] = newState[filter].filter(
          (item: any) => item !== value,
        )
        state.set(newState)
      }
    },
    [state],
  )

  const hasFilterValue = useCallback(
    (filter: string, value: any) => {
      return (
        Object.hasOwn(state.value, filter) &&
        state.value[filter].map((a: any) => String(a)).includes(String(value))
      )
    },
    [state],
  )

  const filters = useMemo(() => {
    const ret: Record<string, FilterType> = {}

    const genFilter = (key: string) => {
      ret[key] = {
        // TODO(manuel, 2023-07-14) It seems that toggle, remove, clear doesn't update lastValue?
        toggle: (val: string) => toggleFilterValue(key, val),
        remove: (val: string) => removeFilterValue(key, val),
        clear: () => removeFilter(key),
        has: (val: string) => hasFilterValue(key, val),
        values: Object.hasOwn(state.value, key) ? state.value[key] : [],
        setValues: (values: string[]) => setFilterValues(key, values),
      }
    }

    Object.keys(state.value).forEach(genFilter)

    for (const key of visibleFilters) {
      if (!Object.hasOwn(ret, key)) {
        genFilter(key)
      }
    }

    return ret
  }, [
    state.value,
    toggleFilterValue,
    removeFilter,
    removeFilterValue,
    hasFilterValue,
    setFilterValues,
    visibleFilters,
  ])

  return useMemo(
    (): FiltersType => ({
      ...filters,
      clear,
      has,
      isEmpty,
      requestProps,
      toggleFilterValue,
      addFilter,
      removeFilter,
      getFilterValues,
      setFilterValues,
      hasFilterValue,
      removeFilterValue,
      filters,
      state,
    }),
    [
      clear,
      has,
      isEmpty,
      requestProps,
      toggleFilterValue,
      addFilter,
      removeFilter,
      getFilterValues,
      setFilterValues,
      hasFilterValue,
      removeFilterValue,
      filters,
      state,
    ],
  )
}

export default useFilters
