import { useDropDownToggler } from 'hooks'
import type { ApiCall } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import Swal from 'sweetalert2'
import { askQuestion, sleep } from 'utils'

type ActionButtonProps = {
  batchesTable: any
  selectedRows: string[]
  apiCombine: ApiCall
  apiArchive: ApiCall
}

const ActionButton = (props: ActionButtonProps) => {
  const { batchesTable, selectedRows, apiCombine, apiArchive } = props

  const toggler = useDropDownToggler()

  const handleClickCombine = useCallback(async () => {
    if (selectedRows.length <= 1) {
      return
    }

    if (
      await askQuestion(
        'Are you sure you want to combine the selected batches? This will create a new batch and delete the selected ones.',
      )
    ) {
      const ret = await apiCombine.performRequest({
        json: { ids: selectedRows },
      })
      batchesTable.triggerSearch()

      if (ret?.name) {
        Swal.fire({
          text: `Created new batch: ${ret.name}`,
          showCancelButton: false,
          showConfirmButton: false,
        })
        await sleep(2000)
        Swal.close()
      }

      if (global.updateOrderStats) {
        global.updateOrderStats()
      }
    }
  }, [batchesTable, apiCombine, selectedRows])

  const handleClickArchive = useCallback(async () => {
    if (await askQuestion('Are you sure you want to archive these batches?')) {
      await apiArchive.performRequest({ ids: selectedRows })
      batchesTable.triggerSearch()

      if (global.updateOrderStats) {
        global.updateOrderStats()
      }
    }
  }, [selectedRows, apiArchive, batchesTable])

  const disabled = selectedRows.length === 0

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret className={disabled ? 'disabled' : ''}>
        Action
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          disabled={selectedRows.length <= 1}
          onClick={handleClickCombine}
        >
          Combine
        </DropdownItem>
        <DropdownItem
          disabled={selectedRows.length === 0}
          onClick={handleClickArchive}
        >
          Archive
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ActionButton
