import { useCallback, useEffect, useRef, useState } from 'react'
import { Tooltip } from 'reactstrap'

type ClipboardButtonProps = {
  value: string
  text?: any
  style?: any
  className?: string
}

export const ClipboardButton = ({
  className,
  text,
  value,
  style,
}: ClipboardButtonProps) => {
  const [isCopied, setCopied] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const timeout = useRef(null)
  const ref = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
      setOpen(false)
    }
  }, [])

  const toggle = useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen])

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()

      setCopied(true)

      try {
        navigator.clipboard.writeText(value)
      } catch (_e) {
        /* Ignore */
      }

      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        setOpen(false)
        setCopied(false)
      }, 600)
    },
    [value],
  )

  return (
    <>
      {text ? <span className="mr-1">{text}</span> : null}
      <i
        className={`clipboard-copy-icon fa fa-copy ${className || ''}`}
        onClick={handleClick}
        style={style}
        ref={ref}
      />
      <Tooltip
        trigger="hover"
        placement="right"
        isOpen={isOpen}
        target={ref}
        toggle={toggle}
      >
        {isCopied ? 'Copied!' : 'Click to copy'}
      </Tooltip>
    </>
  )
}

export default ClipboardButton
